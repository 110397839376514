import { MAVEN_MSG, MVN_MSGS, MvnMessageBinds } from "./const";
import * as Code from "../code";
import { MvnCodeBE } from "../code/const";
import * as Lang from "../lang";
import { MvnCodeFE } from "../lang/const";

Code.COMMON_CODE;
Lang.SITE_LANG;

function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

const BIND_REGEX = /{([A-Za-z])+}/g;
const getItemFromLocalStorage = (key: string): string | null => {
  try {
    // @ts-ignore
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export enum MvnLanguage {
  EN = "EN",
  KO = "KO",
  FR = "FR",
  NL = "NL",
}

export function MvnMessage(
  mvn: MvnCodeFE | MvnCodeBE | string,
  binds?: MvnMessageBinds,
  naked?: Array<MvnCodeBE | MvnCodeFE | string>,
  lang?: MvnLanguage
): string {
  let msg: string;
  const localStorageValue = lang || (getItemFromLocalStorage("LoLang") as MvnLanguage);

  if (typeof mvn === "string") {
    msg = mvn;
  } else if (typeof mvn === "object" && localStorageValue === MvnLanguage.EN) {
    msg = mvn.en;
  } else if (typeof mvn === "object" && "fr" in mvn && mvn.fr && localStorageValue === MvnLanguage.FR) {
    msg = mvn.fr;
  } else if (typeof mvn === "object" && "nl" in mvn && mvn.nl && localStorageValue === MvnLanguage.NL) {
    msg = mvn.nl;
  } else if (typeof mvn === "object" && localStorageValue === MvnLanguage.KO) {
    msg = typeof mvn.ko === "string" ? mvn.ko : mvn.ko || mvn.en;
  } else {
    msg = typeof mvn.ko === "string" ? mvn.ko : mvn.ko || mvn.en;
  }

  const matches = msg.match(BIND_REGEX);

  if (!matches || !binds) {
    return msg;
  }

  try {
    matches.forEach(match => {
      const bind = !!binds && binds[match.slice(1, -1)];

      if (bind || bind === "" || typeof bind === "number") {
        const bindMsg =
          typeof bind === "object" && hasOwnProperty(bind, "result") && typeof bind.result === "boolean"
            ? (res => {
                if (hasOwnProperty(res, "data") && res.data.msgType === MAVEN_MSG && MVN_MSGS[res.code]) {
                  const bindMsg = MvnMessage({ ...MVN_MSGS[res.code], type: undefined }, res.data.binds);

                  if (naked && naked.map(nakedMsg => (typeof nakedMsg === "string" ? nakedMsg : nakedMsg.code)).includes(res.code)) {
                    throw new Error(bindMsg);
                  }

                  return MvnMessage({ ...MVN_MSGS[res.code], type: undefined }, res.data.binds);
                }

                return res.message || "?";
              })(bind)
            : bind;

        msg = msg.split(match).join(`${bindMsg}`);
      }
    });
  } catch (errMsg) {
    return ((errMsg || {}) as any).message;
  }

  return msg;
}

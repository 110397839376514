import { CONVERTER_COMMON_LANG } from "./common";
import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_STUDY_LANG: {
  LIST_LABEL_SEQ: MvnCodeFE;
  LIST_LABEL_PROTOCOL: MvnCodeFE;
  LIST_LABEL_DESCRIPTION: MvnCodeFE;
  LIST_LABEL_SDTMIG_VER: MvnCodeFE;
  LIST_LABEL_SPONSOR: MvnCodeFE;
  LIST_LABEL_CREATOR: MvnCodeFE;
  LIST_LABEL_CREATED_TIME: MvnCodeFE;
  LIST_MENU_UPDATE: MvnCodeFE;
  LIST_MENU_DELETE: MvnCodeFE;
  LIST_ADD: MvnCodeFE;
  LIST_EMPTY_TITLE: MvnCodeFE;
  LIST_EMPTY_DESCRIPTION: MvnCodeFE;
  DIALOG_ADD_TITLE: MvnCodeFE;
  DIALOG_EDIT_TITLE: MvnCodeFE;
  DIALOG_DELETE_TITLE: MvnCodeFE;
  DIALOG_DELETE_DESCRIPTION: MvnCodeFE;
  DIALOG_APPLY: MvnCodeFE;
  DIALOG_FORM_REQUIRED: MvnCodeFE;
  DIALOG_CONFIRM: MvnCodeFE;
  DIALOG_CANCEL: MvnCodeFE;
  DIALOG_FORM_NAME: MvnCodeFE;
  DIALOG_PLACEHOLDER_NAME: MvnCodeFE;
  DIALOG_FORM_DESCRIPTION: MvnCodeFE;
  DIALOG_PLACEHOLDER_DESCRIPTION: MvnCodeFE;
  DIALOG_FORM_PROTOCOL: MvnCodeFE;
  DIALOG_PLACEHOLDER_PROTOCOL: MvnCodeFE;
  DIALOG_FORM_SDTMIG_VER: MvnCodeFE;
  DIALOG_PLACEHOLDER_SDTMIG_VER: MvnCodeFE;
  DIALOG_FORM_CT_VER: MvnCodeFE;
  DIALOG_PLACEHOLDER_CT_VER: MvnCodeFE;
  DIALOG_FORM_AFFILIATION: MvnCodeFE;
  DIALOG_PLACEHOLDER_AFFILIATION: MvnCodeFE;
  TOAST_STUDY_ADD_SUCCEED: MvnCodeFE;
  TOAST_STUDY_ADD_FAIL: MvnCodeFE;
  TOAST_STUDY_EDIT_SUCCEED: MvnCodeFE;
  TOAST_STUDY_EDIT_FAIL: MvnCodeFE;
  TOAST_STUDY_DELETE_SUCCEED: MvnCodeFE;
  TOAST_STUDY_DELETE_FAIL: MvnCodeFE;
} = {
  LIST_LABEL_SEQ: {
    ko: "순번",
    en: "No.",
  },
  LIST_LABEL_PROTOCOL: {
    ko: "프로토콜명",
    en: "Protocol Name",
  },
  // TODO: 확인 필요
  LIST_LABEL_DESCRIPTION: {
    ko: "스터디 정보",
    en: "Study Name",
  },
  LIST_LABEL_SDTMIG_VER: {
    ko: "SDTMIG Ver.",
    en: "SDTMIG Ver.",
  },
  LIST_LABEL_SPONSOR: {
    ko: "스폰서",
    en: "Sponsor",
  },
  LIST_LABEL_CREATOR: {
    ko: "생성자",
    en: "Creator",
  },
  LIST_LABEL_CREATED_TIME: {
    ko: "생성일시",
    en: "Creation Timestamp",
  },
  LIST_MENU_UPDATE: CONVERTER_COMMON_LANG.EDIT_VERB,
  LIST_MENU_DELETE: CONVERTER_COMMON_LANG.DELETE_VERB,
  LIST_ADD: CONVERTER_COMMON_LANG.ADD_VERB,
  LIST_EMPTY_TITLE: {
    ko: "등록된 데이터가 없습니다.",
    en: "No data is available.",
  },
  LIST_EMPTY_DESCRIPTION: {
    ko: "스터디를 생성하고 원하는 스터디로 진입할 수 있습니다.",
    en: "You can create a study and join the one you want.",
  },
  DIALOG_ADD_TITLE: {
    ko: "스터디 생성",
    en: "Create Study",
  },
  DIALOG_EDIT_TITLE: {
    ko: "스터디 수정",
    en: "Modify the Study",
  },
  DIALOG_DELETE_TITLE: {
    ko: "삭제",
    en: "Deletion",
  },
  DIALOG_DELETE_DESCRIPTION: {
    ko: "스터디를 삭제하시겠습니까?",
    en: "Confirm deletion of the study.",
  },
  DIALOG_APPLY: CONVERTER_COMMON_LANG.APPLY_VERB,
  DIALOG_CONFIRM: CONVERTER_COMMON_LANG.CONFIRM_VERB,
  DIALOG_CANCEL: CONVERTER_COMMON_LANG.CANCEL_VERB,
  DIALOG_FORM_NAME: {
    ko: "Study Name",
    en: "Study Name",
  },
  DIALOG_PLACEHOLDER_NAME: {
    ko: "스폰서가 스터디에 부여한 짧은 이름",
    en: "A short name assigned to the study by the sponsor.",
  },
  DIALOG_FORM_DESCRIPTION: {
    ko: "Study Description",
    en: "Study Description",
  },
  // TODO: 확인 필요
  DIALOG_PLACEHOLDER_DESCRIPTION: {
    ko: "연구 제목",
    en: "Study Title",
  },
  DIALOG_FORM_PROTOCOL: {
    ko: "Protocol Name",
    en: "Protocol Name",
  },
  DIALOG_PLACEHOLDER_PROTOCOL: {
    ko: "프로토콜 이름 또는 번호",
    en: "Protocol Name or Number",
  },
  DIALOG_FORM_SDTMIG_VER: {
    ko: "SDTMIG version",
    en: "SDTMIG version",
  },
  DIALOG_PLACEHOLDER_SDTMIG_VER: {
    ko: "SDTMIG version",
    en: "SDTMIG version",
  },
  DIALOG_FORM_CT_VER: {
    ko: "SDTM CT version",
    en: "SDTM CT version",
  },
  DIALOG_PLACEHOLDER_CT_VER: {
    ko: "SDTM CT version",
    en: "SDTM CT version",
  },
  DIALOG_FORM_AFFILIATION: {
    ko: "Sponsor",
    en: "Sponsor",
  },
  DIALOG_FORM_REQUIRED: CONVERTER_COMMON_LANG.FORM_REQUIRED,
  // TODO: 확인 필요
  DIALOG_PLACEHOLDER_AFFILIATION: {
    ko: "소속",
    en: "Affiliation",
  },
  TOAST_STUDY_ADD_SUCCEED: {
    ko: "스터디를 생성했습니다.",
    en: "Create a folder for your study and upload data.",
  },
  TOAST_STUDY_ADD_FAIL: {
    ko: "스터디 생성에 실패했습니다.",
    en: "Failed to create the study.",
  },
  TOAST_STUDY_EDIT_SUCCEED: {
    ko: "스터디를 수정했습니다.",
    en: "Study has been modified.",
  },
  TOAST_STUDY_EDIT_FAIL: {
    ko: "스터디 수정에 실패했습니다.",
    en: "Failed to modify the study.",
  },
  TOAST_STUDY_DELETE_SUCCEED: {
    ko: "스터디를 삭제했습니다.",
    en: "Study has been deleted.",
  },
  TOAST_STUDY_DELETE_FAIL: {
    ko: "스터디 삭제에 실패했습니다.",
    en: "Failed to delete the study.",
  },
};

MvnCodeInitiate(CONVERTER_STUDY_LANG);

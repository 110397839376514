import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_CODE: {
  BUILDER_STUDY_INVALID_TITLE_MISSING: MvnCodeBE;
  BUILDER_STUDY_INVALID_TITLE_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_INVALID_TITLE_MISSING_WITH_DEFAULT_LANG: MvnCodeBE;
  BUILDER_STUDY_MODIFY_INVALID_STUDY_LOCKED: MvnCodeBE;
} = {
  BUILDER_STUDY_INVALID_TITLE_MISSING: {
    code: "BUILDER_STUDY_INVALID_TITLE_MISSING",
    status: 400,
    ko: "Title을 입력해 주세요.",
    en: "Please enter a Title.",
  },
  BUILDER_STUDY_INVALID_TITLE_DUPLICATED: {
    code: "BUILDER_STUDY_INVALID_TITLE_DUPLICATED",
    status: 400,
    ko: "'{lang}' 언어로 Title이 중복 입력되었습니다.",
    en: "Title has duplicated for '{lang}' language.",
  },
  BUILDER_STUDY_INVALID_TITLE_MISSING_WITH_DEFAULT_LANG: {
    code: "BUILDER_STUDY_INVALID_TITLE_MISSING_WITH_DEFAULT_LANG",
    status: 400,
    ko: "'{lang}' 언어로 Title이 입력되지 않았습니다.",
    en: "Title has not entered in '{lang}' language.",
  },
  BUILDER_STUDY_MODIFY_INVALID_STUDY_LOCKED: {
    code: "BUILDER_STUDY_MODIFY_INVALID_STUDY_LOCKED",
    status: 400,
    ko: "Publish 및 Push 작업 수행 중에는 Study {property} 를 변경할 수 없습니다.",
    en: "You cannot change Study {property} while performing a Publish, Push operation.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_CODE);

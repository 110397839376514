import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_WORKSPACE_FOLDER_CODE: {
  WORKSPACE_FOLDER_INVALID_DUPLICATED_NAME: MvnCodeBE;
  WORKSPACE_FOLDER_INVALID_NAME_MAX_LENGTH_EXCEED: MvnCodeBE;
} = {
  WORKSPACE_FOLDER_INVALID_DUPLICATED_NAME: {
    status: 409,
    ko: "폴더 이름이 중복되었습니다.",
    en: "Folder name is duplicated.",
  },
  WORKSPACE_FOLDER_INVALID_NAME_MAX_LENGTH_EXCEED: {
    status: 400,
    ko: "폴더명은 {length}자 이내로 입력가능합니다.",
    en: "Folder name must be less than {length} characters long.",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_FOLDER_CODE);

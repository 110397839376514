import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_PAGE_CODE: {
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_INVALID_DOMAIN: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_INVALID_DOMAIN_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_DOMAIN_ID_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_LENGTH_EXCEEDED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_IDENTIFIER_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_DOMAIN_ID_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_PROPERTY_INVALID_REQUIRED_VALUE_MISSING: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_INVALID_DOMAIN: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_ADD_INVALID_DOMAIN",
    status: 400,
    ko: "Domain 정보가 올바르지 않습니다.",
    en: "Domain information is invalid.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_INVALID_DOMAIN_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_ADD_INVALID_DOMAIN_MISSING",
    status: 400,
    ko: "Domain 정보를 입력해 주세요.",
    en: "Please enter a domain information.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_MISSING",
    status: 400,
    ko: "Page Identifier를 입력해 주세요.",
    en: "Please enter a page identifier.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_FORMAT: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_FORMAT",
    status: 400,
    ko: "Identifier는 영대문자, 숫자만 입력할 수 있습니다. (identifier: {identifier})",
    en: "Identifier should be combination of uppercase letters and number. (identifier: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_DOMAIN_ID_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_INVALID_DOMAIN_ID_MISSING",
    status: 400,
    ko: "Domain ID가 존재하지 않습니다.",
    en: "Please enter a domain ID.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_INVALID_IDENTIFIER_LENGTH_EXCEEDED",
    status: 400,
    ko: "Identifier는 {length}자리 이하의 문자이어야합니다.",
    en: "Identifier length should be {length} or less.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "중복된 Identifier({id})가 존재합니다.",
    en: "Identifier({id}) is duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_DOMAIN_ID_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_DOMAIN_ID_DUPLICATED",
    status: 409,
    ko: "Page({page})의 Domain은 다른 Page에서 사용하고 있습니다.",
    en: "Duplicate page({page}) domain found. Kindly provide a unique one.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_PROPERTY_INVALID_REQUIRED_VALUE_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_PROPERTY_INVALID_REQUIRED_VALUE_MISSING",
    status: 400,
    ko: "Study Page({identifier}) {property}의 필수값({value})이 누락되었습니다.",
    en: "Required value({value}) of study page({identifier}) {property} is missing.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_PAGE_CODE);

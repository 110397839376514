import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_ROLE_CODE: {
  BUILDER_STUDY_ROLE_NAME_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_ROLE_NOT_FOUND: MvnCodeBE;
} = {
  BUILDER_STUDY_ROLE_NAME_DUPLICATED: {
    code: "BUILDER_STUDY_ROLE_NAME_DUPLICATED",
    status: 409,
    ko: "해당 (Role({names}))이 이미 존재합니다.",
    en: "(Role({names})) already exists.",
  },
  BUILDER_STUDY_ROLE_NOT_FOUND: {
    code: "BUILDER_STUDY_ROLE_NOT_FOUND",
    status: 404,
    ko: "요청하신 Study Role({id}) 정보를 찾을 수 없습니다.",
    en: "Requested Study Role({id}) could not be found.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_ROLE_CODE);

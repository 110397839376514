import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_DATA_EXPORT_LANG: {
  CDMS_STUDY_DATA_EXPORT_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_DATA_EXPORT_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_DATA_EXPORT_TOOLTIP_MESSAGE: MvnCodeFE;
} = {
  CDMS_STUDY_DATA_EXPORT_PAGE_TITLE: {
    ko: "Data Export",
    en: "Data Export",
  },
  CDMS_STUDY_DATA_EXPORT_PAGE_DESC: {
    ko: "Data Export를 수행할 수 있습니다.",
    en: "Execute data export.",
  },
  CDMS_STUDY_DATA_EXPORT_TOOLTIP_MESSAGE: {
    ko: "Request 가능한 최대 용량은 {maxSize}입니다.\n* 1GB = 1024MB\n* 1MB = 1024KB",
    en: "The maximum file capacity you can request is {maxSize}.\n* 1GB = 1024MB\n* 1MB = 1024KB",
  },
};

MvnCodeInitiate(CDMS_STUDY_DATA_EXPORT_LANG);

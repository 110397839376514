import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_BUILDER_USER_CODE: {
  BUILDER_STUDY_BUILDER_USER_ADD_INVALID_NOT_FOUND: MvnCodeBE;
  BUILDER_STUDY_BUILDER_USER_ADD_INVALID_EXIST_USER: MvnCodeBE;
  BUILDER_STUDY_BUILDER_USER_DELETE_INVALID_NO_OWNER: MvnCodeBE;
} = {
  BUILDER_STUDY_BUILDER_USER_ADD_INVALID_NOT_FOUND: {
    code: "BUILDER_STUDY_BUILDER_USER_ADD_INVALID_NOT_FOUND",
    status: 404,
    ko: "System에서 사용자를 찾을 수 없습니다. (email: {email})",
    en: "The User was not found in the system. (email: {email})",
  },
  BUILDER_STUDY_BUILDER_USER_ADD_INVALID_EXIST_USER: {
    code: "BUILDER_STUDY_BUILDER_USER_ADD_INVALID_EXIST_USER",
    status: 409,
    ko: "이미 등록된 사용자입니다.",
    en: "The user already exists.",
  },
  BUILDER_STUDY_BUILDER_USER_DELETE_INVALID_NO_OWNER: {
    code: "BUILDER_STUDY_BUILDER_USER_DELETE_INVALID_NO_OWNER",
    status: 400,
    ko: "최소 한 명의 사용자는 Owner 역할을 가져야 합니다.",
    en: "The owner role has to be assigned to at least one user.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_BUILDER_USER_CODE);

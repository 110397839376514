import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_ADMIN_LANG: {
  CDMS_ADMIN_STUDY_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_STUDY_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_CLOSED_STUDY_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_CLOSED_STUDY_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_HOME_USER_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_HOME_USER_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_HOME_FAQ_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_HOME_FAQ_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_HOME_INQUIRY_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_HOME_INQUIRY_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_TRAINING_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_TRAINING_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_MANAGE_STUDY_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_MANAGE_STUDY_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_CS_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_USER_BREADCRUMB: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_USER_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_USER_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_API_USER_BREADCRUMB: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_API_USER_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_API_USER_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_FAQ_BREADCRUMB: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_FAQ_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_FAQ_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_E_TRAINING_BREADCRUMB: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_E_TRAINING_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_E_TRAINING_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_AFFILIATION_BREADCRUMB: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_AFFILIATION_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_AFFILIATION_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_ROLE_BREADCRUMB: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_ROLE_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_ROLE_PAGE_DESC: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_STUDY_ROLE_BREADCRUMB: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_STUDY_ROLE_PAGE_TITLE: MvnCodeFE;
  CDMS_ADMIN_SYSTEM_SETTING_STUDY_ROLE_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_ADMIN_STUDY_PAGE_TITLE: {
    ko: "Study",
    en: "Study",
  },
  CDMS_ADMIN_STUDY_PAGE_DESC: {
    ko: "현재 진행중인 스터디 목록을 확인할 수 있습니다. 스터디를 선택하면 상세페이지로 이동할 수 있습니다.",
    en: "Overall study information. Select Protocol no. for details.",
  },
  CDMS_ADMIN_CLOSED_STUDY_PAGE_TITLE: {
    ko: "Closed Study",
    en: "Closed Study",
  },
  CDMS_ADMIN_CLOSED_STUDY_PAGE_DESC: {
    ko: "종료된 스터디 목록을 확인할 수 있습니다. 종료된 스터디의 임상시험데이터는 일정 기간 보관 후 삭제됩니다.",
    en: "Closed study information. Closed study data will be deleted after a period of time.",
  },
  CDMS_ADMIN_HOME_USER_PAGE_TITLE: {
    ko: "User & Permissions",
    en: "User & Permissions",
  },
  CDMS_ADMIN_HOME_USER_PAGE_DESC: {
    ko: "사용자별 접속정보와 접근 권한을 확인하고 관리할 수 있습니다.",
    en: "Check and manage each user's access information and privileges.",
  },
  CDMS_ADMIN_HOME_FAQ_PAGE_TITLE: {
    ko: "FAQ",
    en: "FAQ",
  },
  CDMS_ADMIN_HOME_FAQ_PAGE_DESC: {
    ko: "자주 묻는 질문을 확인 할 수 있습니다.",
    en: "Check frequently asked questions.",
  },
  CDMS_ADMIN_HOME_INQUIRY_PAGE_TITLE: {
    ko: "Inquiry",
    en: "Inquiry",
  },
  CDMS_ADMIN_HOME_INQUIRY_PAGE_DESC: {
    ko: "1:1 질문과 답변을 확인 할 수 있습니다.",
    en: "Check 1:1 questions and answers.",
  },
  CDMS_ADMIN_TRAINING_PAGE_TITLE: {
    ko: "E-Training",
    en: "E-Training",
  },
  CDMS_ADMIN_TRAINING_PAGE_DESC: {
    ko: "E-Training을 확인하고 인증서를 발급 받을 수 있습니다.",
    en: "Conduct E-Training and download certificate.",
  },
  CDMS_ADMIN_MANAGE_STUDY_PAGE_TITLE: {
    ko: "Manage Study",
    en: "Manage Study",
  },
  CDMS_ADMIN_MANAGE_STUDY_PAGE_DESC: {
    ko: "등록된 스터디 목록을 확인하고 관리할 수 있습니다.",
    en: "View and manage the list of registered studies.",
  },
  CDMS_ADMIN_CS_PAGE_TITLE: {
    ko: "CS",
    en: "CS",
  },
  CDMS_ADMIN_SYSTEM_SETTING_PAGE_TITLE: {
    ko: "System Settings",
    en: "System Settings",
  },
  CDMS_ADMIN_SYSTEM_SETTING_PAGE_DESC: {
    ko: "Maven CDMS 시스템 환경설정을 확인하고 관리할 수 있습니다.",
    en: "View and manage the Maven CDMS system settings.",
  },
  CDMS_ADMIN_SYSTEM_SETTING_USER_BREADCRUMB: {
    ko: "User",
    en: "User",
  },
  CDMS_ADMIN_SYSTEM_SETTING_USER_PAGE_TITLE: {
    ko: "Manage User",
    en: "Manage User",
  },
  CDMS_ADMIN_SYSTEM_SETTING_USER_PAGE_DESC: {
    ko: "Maven CDMS에 등록된 사용자를 확인하고 관리할 수 있습니다.",
    en: "View and manage the users registered in Maven CDMS.",
  },
  CDMS_ADMIN_SYSTEM_SETTING_API_USER_BREADCRUMB: {
    ko: "API User",
    en: "API User",
  },
  CDMS_ADMIN_SYSTEM_SETTING_API_USER_PAGE_TITLE: {
    ko: "Manage API User",
    en: "Manage API User",
  },
  CDMS_ADMIN_SYSTEM_SETTING_API_USER_PAGE_DESC: {
    ko: "Maven CDMS에 등록된 API 사용자를 확인하고 관리할 수 있습니다.",
    en: "View and manage the API users registered in Maven CDMS.",
  },
  CDMS_ADMIN_SYSTEM_SETTING_FAQ_BREADCRUMB: {
    ko: "FAQ",
    en: "FAQ",
  },
  CDMS_ADMIN_SYSTEM_SETTING_FAQ_PAGE_TITLE: {
    ko: "Manage FAQ",
    en: "Manage FAQ",
  },
  CDMS_ADMIN_SYSTEM_SETTING_FAQ_PAGE_DESC: {
    ko: "Maven CDMS에 등록된 FAQ를 확인하고 관리할 수 있습니다.",
    en: "View and manage the FAQs registered in Maven CDMS.",
  },
  CDMS_ADMIN_SYSTEM_SETTING_E_TRAINING_BREADCRUMB: {
    ko: "E-Training",
    en: "E-Training",
  },
  CDMS_ADMIN_SYSTEM_SETTING_E_TRAINING_PAGE_TITLE: {
    ko: "Manage E-Training",
    en: "Manage E-Training",
  },
  CDMS_ADMIN_SYSTEM_SETTING_E_TRAINING_PAGE_DESC: {
    ko: "Maven CDMS에 등록된 E-Training을 확인하고 관리할 수 있습니다.",
    en: "View and manage the E-Training registered in Maven CDMS.",
  },
  CDMS_ADMIN_SYSTEM_SETTING_AFFILIATION_BREADCRUMB: {
    ko: "Affiliation",
    en: "Affiliation",
  },
  CDMS_ADMIN_SYSTEM_SETTING_AFFILIATION_PAGE_TITLE: {
    ko: "Manage Affiliation",
    en: "Manage Affiliation",
  },
  CDMS_ADMIN_SYSTEM_SETTING_AFFILIATION_PAGE_DESC: {
    ko: "Maven CDMS에 등록된 소속기관을 확인하고 관리할 수 있습니다.",
    en: "View and manage the affiliations registered in Maven CDMS.",
  },
  CDMS_ADMIN_SYSTEM_SETTING_ROLE_BREADCRUMB: {
    ko: "Role",
    en: "Role",
  },
  CDMS_ADMIN_SYSTEM_SETTING_ROLE_PAGE_TITLE: {
    ko: "Manage Role",
    en: "Manage Role",
  },
  CDMS_ADMIN_SYSTEM_SETTING_ROLE_PAGE_DESC: {
    ko: "Maven CDMS에 등록된 Role 목록을 확인하고 관리할 수 있습니다.",
    en: "View and manage the list of Roles registered in Maven CDMS.",
  },
  CDMS_ADMIN_SYSTEM_SETTING_STUDY_ROLE_BREADCRUMB: {
    ko: "Study-Role",
    en: "Study-Role",
  },
  CDMS_ADMIN_SYSTEM_SETTING_STUDY_ROLE_PAGE_TITLE: {
    ko: "Manage Study Role",
    en: "Manage Study Role",
  },
  CDMS_ADMIN_SYSTEM_SETTING_STUDY_ROLE_PAGE_DESC: {
    ko: "Maven CDMS에 등록된 Study Role 목록을 확인하고 관리할 수 있습니다.",
    en: "View and manage the list of Study Roles registered in Maven CDMS.",
  },
};

MvnCodeInitiate(CDMS_ADMIN_LANG);

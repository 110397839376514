import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_CAPTURE_CODE: {
  CDMS_STUDY_CAPTURE_DATA_INVALID_NOT_ALLOWED_INPUT: MvnCodeBE;
  CDMS_STUDY_CAPTURE_DATA_NOT_EXISTS: MvnCodeBE;
  CDMS_STUDY_CAPTURE_DATA_INVALID_REQUIRED_ITEM_VALUE_MISSING: MvnCodeBE;
} = {
  CDMS_STUDY_CAPTURE_DATA_INVALID_NOT_ALLOWED_INPUT: {
    status: 400,
    ko: "허용되지 않은 입력값입니다.",
    en: "The input entered is not allowed.",
  },
  CDMS_STUDY_CAPTURE_DATA_NOT_EXISTS: {
    status: 400,
    ko: "Capture Data가 존재하지 않는 경우에는 해당 작업을 수행할 수 없습니다.",
    en: "If the Capture Data does not exist, the operation cannot be performed.",
  },
  CDMS_STUDY_CAPTURE_DATA_INVALID_REQUIRED_ITEM_VALUE_MISSING: {
    code: "MISSING-CAPTURE-FIELD",
    status: 400,
    ko: "`{item}` Field is required.",
    en: "`{item}` Field is required.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CAPTURE_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_COMMENT_CODE: {
  CDMS_STUDY_COMMENT_NOT_EXISTS: MvnCodeBE;
  CDMS_STUDY_COMMENT_MESSAGE_EXCEED_MAXLEN: MvnCodeBE;
  CDMS_STUDY_COMMENT_ALREADY_DELETED: MvnCodeBE;
  CDMS_STUDY_COMMENT_WRONG_REQUEST: MvnCodeBE;
  CDMS_STUDY_COMMENT_NOT_CHANGED: MvnCodeBE;
  CDMS_STUDY_COMMENT_AUDIT_TRAIL_NOT_EXISTS: MvnCodeBE;
} = {
  CDMS_STUDY_COMMENT_NOT_EXISTS: {
    status: 404,
    ko: "Comment 정보가 존재하지 않습니다.",
    en: "The comment does not exist.",
  },
  CDMS_STUDY_COMMENT_MESSAGE_EXCEED_MAXLEN: {
    status: 400,
    ko: "Comment가 {maxLen}자를 초과했습니다.",
    en: "The comment exceeds the maximum length of {maxLen} characters.",
  },
  CDMS_STUDY_COMMENT_ALREADY_DELETED: {
    status: 409,
    ko: "삭제된 Comment입니다.",
    en: "The comment has been deleted.",
  },
  CDMS_STUDY_COMMENT_WRONG_REQUEST: {
    status: 400,
    ko: "Comment에 대한 잘못된 요청입니다.",
    en: "Invalid request for comment.",
  },
  CDMS_STUDY_COMMENT_NOT_CHANGED: {
    status: 409,
    ko: "변경사항이 없습니다.",
    en: "There are no changes.",
  },
  CDMS_STUDY_COMMENT_AUDIT_TRAIL_NOT_EXISTS: {
    status: 404,
    ko: "Comment Audit 정보가 존재하지 않습니다.",
    en: "Comment Audit does not exist.",
  },
};

MvnCodeInitiate(CDMS_STUDY_COMMENT_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_TRIGGER_CODE: {
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_IDENTIFIER_LENGTH_EXCEED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_IDENTIFIER_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_TYPE_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_REQUIRED_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_QUEUE_INVOKE_ID_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_PROPERTY_VALUE_INVALID: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_REMOVE_INVALID_TRIGGER_IN_USE: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_CONDITIONAL_INVALID_REQUIRED_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_VALUE_INVALID_REQUIRED_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_DUPLICATED_TARGET: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_PROPERTY_VALUE_EXCEED_MAXLEN: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_COPY_TYPE: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_ALIAS_DUPLICATED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_IDENTIFIER_LENGTH_EXCEED: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_IDENTIFIER_LENGTH_EXCEED",
    status: 400,
    ko: "Trigger identifier는 최대 {length}자 입력 가능합니다.",
    en: "The Trigger identifier can be up to {length} characters long.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "중복된 Trigger identifier({identifier})가 존재합니다.",
    en: "Trigger identifier({identifier}) is duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_TYPE_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_TYPE_DUPLICATED",
    status: 409,
    ko: "중복된 Trigger type({type})이 존재합니다.",
    en: "Trigger type({type}) is duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_REQUIRED_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_REQUIRED_PROPERTY_MISSING",
    status: 400,
    ko: "'{type}' Trigger의 필수값({property})이 누락되었습니다.",
    en: "Required value({property}) of '{type}' trigger is missing.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_QUEUE_INVOKE_ID_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_QUEUE_INVOKE_ID_DUPLICATED",
    status: 400,
    ko: "중복된 Invoke Trigger Identifier({identifier})이 존재합니다.",
    en: "Invoke trigger identifier({identifier}) is duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_PROPERTY_VALUE_INVALID: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_PROPERTY_VALUE_INVALID",
    status: 400,
    ko: "‘{property}’({value}) 값이 유효하지 않습니다.",
    en: "‘{property}’({value}) field has an invalid value.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_REMOVE_INVALID_TRIGGER_IN_USE: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_REMOVE_INVALID_TRIGGER_IN_USE",
    status: 400,
    ko: "사용중인 Trigger는 삭제할 수 없습니다.",
    en: "Can not remove trigger in use.",
  },
  // NOTE: BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_REQUIRED_PROPERTY_MISSING를 확장하여 Trigger Invalid Log 보여주도록 개선 필요 (2024.02.06)
  BUILDER_STUDY_PAYLOAD_TRIGGER_CONDITIONAL_INVALID_REQUIRED_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_CONDITIONAL_INVALID_REQUIRED_PROPERTY_MISSING",
    status: 400,
    ko: "Conditional 필수값 {property}({identifier})이 누락되었습니다.",
    en: "Required value {property}({identifier}) of conditional is missing.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_VALUE_INVALID_REQUIRED_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_VALUE_INVALID_REQUIRED_PROPERTY_MISSING",
    status: 400,
    ko: "Value 필수값 {property}({identifier})이 누락되었습니다.",
    en: "Required value {property}({identifier}) of value is missing.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_DUPLICATED_TARGET: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_DUPLICATED_TARGET",
    status: 409,
    ko: "Target이 이미 다른 {type} Trigger에 할당되어 있습니다.",
    en: "The target has already been assigned to another {type} trigger.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_PROPERTY_VALUE_EXCEED_MAXLEN: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_PROPERTY_VALUE_EXCEED_MAXLEN",
    status: 400,
    ko: "{property} {size}{unit}를 초과했습니다.",
    en: "{property} has exceeded {size}{unit} in length.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_COPY_TYPE: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_COPY_TYPE",
    status: 400,
    ko: "{type} type은 복사할 수 없습니다.",
    en: "Cannot duplicate type {type}.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_ALIAS_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_INVALID_ALIAS_DUPLICATED",
    status: 409,
    ko: "중복 Alias가 존재합니다. (Alias명: {aliasName})",
    en: "Alias is duplicated. (Alias Name: {aliasName})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_TRIGGER_CODE);

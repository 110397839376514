import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_PAGE_SECTION_CODE: {
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_LENGTH_EXCEEDED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_IDENTIFIER_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_LABEL_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_VISIT_CODE: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_PROPERTY_INVALID_REQUIRED_VALUE_MISSING: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_MISSING",
    status: 400,
    ko: "Page Section OID를 입력해 주세요.",
    en: "Please enter a page section OID.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_FORMAT: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_FORMAT",
    status: 400,
    ko: "Section OID는 영대문자, 숫자만 입력할 수 있습니다. (OID: {identifier})",
    en: "Section OID should be combination of uppercase letters and number. (OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_IDENTIFIER_LENGTH_EXCEEDED",
    status: 400,
    ko: "Section OID는 {length}자리 이하의 문자이어야합니다.",
    en: "Section OID length should be {length} or less.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "중복된 Section OID({id})가 존재합니다.",
    en: "Section OID({id}) is duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_LABEL_FORMAT: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_LABEL_FORMAT",
    status: 400,
    ko: "Section Label은 한글, 영문자, 숫자, 특수문자만 입력할 수 있습니다. (label: {label})",
    en: "Section label should be combination of english letters, special characters and number. (label: {label})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_VISIT_CODE: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_INVALID_VISIT_CODE",
    status: 400,
    ko: "Section Visibility Visit Code 정보가 올바르지 않습니다.",
    en: "Section visibility visit code is invalid.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_PROPERTY_INVALID_REQUIRED_VALUE_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_PROPERTY_INVALID_REQUIRED_VALUE_MISSING",
    status: 400,
    ko: "Study Page({pageIdentifier}) Section({sectionIdentifier}) {property}의 필수값({value})이 누락되었습니다.",
    en: "Required value({value}) of study page({pageIdentifier}) section({sectionIdentifier}) {property} is missing.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_PAGE_SECTION_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CRF_LANG: {
  SAFETY_CRF_ADD_SUCC: MvnCodeFE;
  SAFETY_CRF_ADD_FAIL: MvnCodeFE;
  SAFETY_CRF_EDIT_SUCC: MvnCodeFE;
  SAFETY_CRF_EDIT_FAIL: MvnCodeFE;
  SAFETY_REPORT_FORM: MvnCodeFE;
  SAFETY_REPORT_FROM_SELECT_PLACEHOLDER: MvnCodeFE;
} = {
  SAFETY_CRF_ADD_SUCC: {
    ko: "CRF가 성공적으로 추가되었습니다.",
    en: "CRF has been created successfully.",
  },
  SAFETY_CRF_ADD_FAIL: {
    ko: "CRF 추가에 실패했습니다. ({message})",
    en: "Failed to create CRF. ({message})",
  },
  SAFETY_CRF_EDIT_SUCC: {
    ko: "CRF가 성공적으로 수정되었습니다.",
    en: "CRF has been modified successfully.",
  },
  SAFETY_CRF_EDIT_FAIL: {
    ko: "CRF 수정에 실패했습니다. ({message})",
    en: "Failed to modify CRF. ({message})",
  },
  SAFETY_REPORT_FORM: {
    ko: "Report Form",
    en: "Report Form",
  },
  SAFETY_REPORT_FROM_SELECT_PLACEHOLDER: {
    ko: "Report Form 선택",
    en: "Select Report Form",
  },
};

MvnCodeInitiate(SAFETY_CRF_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_LAB_TEST_ITEM_UNIT_CODE: {
  BUILDER_STUDY_LAB_TEST_ITEM_UNIT_DUPLICATED: MvnCodeBE;
} = {
  BUILDER_STUDY_LAB_TEST_ITEM_UNIT_DUPLICATED: {
    code: "BUILDER_STUDY_LAB_TEST_ITEM_UNIT_DUPLICATED",
    status: 409,
    ko: "해당 실험실 항목 단위({label})가 이미 존재합니다.",
    en: "Lab Test Item Unit({label}) already exists.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_LAB_TEST_ITEM_UNIT_CODE);

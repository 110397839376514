import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_CODE: {
  BUILDER_STUDY_PAYLOAD_NOT_FOUND: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DRAFT_PAYLOAD_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PUBLISH_PAYLOAD_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PUSH_ENVIRONMENT_INVALID: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PUSH_VERSION_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_ALREADY_VALIDATE_REQUESTED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DELETE_INVALID_PAYLOAD_PUSH_ALREADY: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DRAFT_PAYLOAD_INVALID_VERSION_LENGTH_EXCEED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PUBLISH_INVALID_CRF_VERSION_LENGTH_EXCEED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PUBLISH_INVALID_EDC_VERSION_LENGTH_EXCEED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_NOT_FOUND: {
    code: "BUILDER_STUDY_PAYLOAD_NOT_FOUND",
    status: 404,
    ko: "{type}이(가) 존재하지 않습니다.",
    en: "{type} is not exist.",
  },
  BUILDER_STUDY_PAYLOAD_DRAFT_PAYLOAD_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_DRAFT_PAYLOAD_DUPLICATED",
    status: 409,
    ko: "중복된 Draft Version({version}) 입니다.",
    en: "The Draft version is duplicated. (version: {version})",
  },
  BUILDER_STUDY_PAYLOAD_PUBLISH_PAYLOAD_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_PUBLISH_PAYLOAD_DUPLICATED",
    status: 409,
    ko: "중복된 Publish Version 입니다. (CRF version: {version}, EDC version: {edcVersion})",
    en: "The Publish version is duplicated. (CRF version: {version}, EDC version: {edcVersion})",
  },
  BUILDER_STUDY_PAYLOAD_PUSH_ENVIRONMENT_INVALID: {
    code: "BUILDER_STUDY_PAYLOAD_PUSH_ENVIRONMENT_INVALID",
    status: 400,
    ko: "CRF version을 push 할 environment 값({env})이 올바르지 않습니다.",
    en: "The logical environment({env}) to push the CRF version is not valid.",
  },
  BUILDER_STUDY_PAYLOAD_PUSH_VERSION_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_PUSH_VERSION_DUPLICATED",
    status: 409,
    ko: "CDMS({environment})로 중복된 Version({version})은 push할 수 없습니다.",
    en: "Cannot push duplicated version({version}) to CDMS({environment}).",
  },
  BUILDER_STUDY_PAYLOAD_ALREADY_VALIDATE_REQUESTED: {
    code: "BUILDER_STUDY_PAYLOAD_ALREADY_VALIDATE_REQUESTED",
    status: 400,
    ko: "이미 유효성 검사가 요청된 상태입니다.",
    en: "The validation has already been requested.",
  },
  BUILDER_STUDY_PAYLOAD_DELETE_INVALID_PAYLOAD_PUSH_ALREADY: {
    code: "BUILDER_STUDY_PAYLOAD_DELETE_INVALID_PAYLOAD_PUSH_ALREADY",
    status: 409,
    ko: "이미 push된 CRF version은 삭제할 수 없습니다.",
    en: "Cannot delete a CRF version that has already been pushed.",
  },
  BUILDER_STUDY_PAYLOAD_DRAFT_PAYLOAD_INVALID_VERSION_LENGTH_EXCEED: {
    code: "BUILDER_STUDY_PAYLOAD_DRAFT_PAYLOAD_INVALID_VERSION_LENGTH_EXCEED",
    status: 400,
    ko: "Draft version은 {length}자를 초과할 수 없습니다.",
    en: "The draft version must not exceed {length} characters.",
  },
  BUILDER_STUDY_PAYLOAD_PUBLISH_INVALID_CRF_VERSION_LENGTH_EXCEED: {
    code: "BUILDER_STUDY_PAYLOAD_PUBLISH_INVALID_CRF_VERSION_LENGTH_EXCEED",
    status: 400,
    ko: "CRF version은 {length}자를 초과할 수 없습니다.",
    en: "CRF version must not exceed {length} characters.",
  },
  BUILDER_STUDY_PAYLOAD_PUBLISH_INVALID_EDC_VERSION_LENGTH_EXCEED: {
    code: "BUILDER_STUDY_PAYLOAD_PUBLISH_INVALID_EDC_VERSION_LENGTH_EXCEED",
    status: 400,
    ko: "EDC version은 {length}자를 초과할 수 없습니다.",
    en: "EDC version must not exceed {length} characters.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_CODE);

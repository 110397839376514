import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CONVERTER_LIBRARY_CODE: {
  CONVERTER_LIBRARY_LIMIT_EXCEEDED: MvnCodeBE;
} = {
  CONVERTER_LIBRARY_LIMIT_EXCEEDED: {
    status: 409,
    ko: "라이브러리 저장 한도를 초과했습니다.",
    en: "The library storage limit has been exceeded.",
  },
};

MvnCodeInitiate(CONVERTER_LIBRARY_CODE);

import { MvnCodeFE } from "./../const";
import { MvnCodeInitiate } from "../../lib/const";

export const CDMS_STUDY_QUERY_LANG: {
  CDMS_STUDY_QUERY_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_QUERY_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_QUERY_PAGE_TITLE: {
    ko: "Query",
    en: "Query",
  },
  CDMS_STUDY_QUERY_PAGE_DESC: {
    ko: "발행된 쿼리를 확인할 수 있습니다.",
    en: "Manage and inquire queries.",
  },
};

MvnCodeInitiate(CDMS_STUDY_QUERY_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_PREFILLED_FORM_CAPTURE_CODE: {
  PREFILLED_FORM_CAPTURE_FILE_EXTENSTION_INVALID: MvnCodeBE;
  PREFILLED_FORM_CAPTURE_FILE_SIZE_EXCEED: MvnCodeBE;
  PREFILLED_FORM_CAPTURE_NO_CHANGES: MvnCodeBE;
  PREFILLED_FORM_CAPTURE_INVALID_NOT_OVERRIDABLE: MvnCodeBE;
  PREFILLED_FORM_CAPTURE_INVALID_EMPTY_OR_MISSING: MvnCodeBE;
} = {
  PREFILLED_FORM_CAPTURE_FILE_EXTENSTION_INVALID: {
    status: 400,
    ko: "확장자가 {type}인 파일은 업로드할 수 없습니다.",
    en: "File with extension {type} cannot be uploaded.",
  },
  PREFILLED_FORM_CAPTURE_FILE_SIZE_EXCEED: {
    status: 400,
    ko: "파일은 최대 {mb}MB까지 업로드할 수 있습니다.",
    en: "You can upload a file up to {mb}MB.",
  },
  PREFILLED_FORM_CAPTURE_NO_CHANGES: {
    status: 400,
    ko: "변경사항이 없습니다.",
    en: "There are no changes.",
  },
  PREFILLED_FORM_CAPTURE_INVALID_NOT_OVERRIDABLE: {
    status: 400,
    ko: "덮어쓸 수 없는 Prefilled Form Capture 입니다. (itemId: {itemId})",
    en: "This Prefilled Form Capture is cannot be overwritten. (itemId: {itemId})",
  },
  PREFILLED_FORM_CAPTURE_INVALID_EMPTY_OR_MISSING: {
    status: 400,
    ko: "테이블에 빈 행이 존재하거나 필수값이 누락되었습니다.",
    en: "Table has empty rows or missing required items.",
  },
};

MvnCodeInitiate(SAFETY_PREFILLED_FORM_CAPTURE_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_IP_DELIVERY_LANG: {
  CDMS_STUDY_IP_DELIVERY_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_IP_DELIVERY_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_IP_DELIVERY_PAGE_TITLE: {
    ko: "IP Delivery",
    en: "IP Delivery",
  },
  CDMS_STUDY_IP_DELIVERY_PAGE_DESC: {
    ko: "IP 배송 상태를 관리하고, 관련 문서를 출력할 수 있습니다.",
    en: "Manage IP delivery status and export relevant documents.",
  },
};

MvnCodeInitiate(CDMS_STUDY_IP_DELIVERY_LANG);

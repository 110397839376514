import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_SYNC_LANG: {
  CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_001: MvnCodeFE;
  CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_002: MvnCodeFE;
  CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_003: MvnCodeFE;
  CASE_SYNC_IDENTIFIER_DIALOG_INPUT_LABEL: MvnCodeFE;
  CASE_SYNC_IDENTIFIER_DIALOG_INPUT_AUTO_IDENTIFIER_VALUE: MvnCodeFE;
  CASE_SYNC_IDENTIFIER_DIALOG_INPUT_INVALID_DUPLICATED: MvnCodeFE;
  CASE_SYNC_MULTI_DIALOG_DESCRIPTION: MvnCodeFE;
  CASE_SYNC_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  CASE_SYNC_DIALOG_TITLE: MvnCodeFE;
  CASE_SYNC_DIALOG_CONFIRM_BUTTON: MvnCodeFE;
  CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_DESCRIPTION: MvnCodeFE;
  CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_WORKSPACE_DROPDOWN_LABEL: MvnCodeFE;
  CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_CRF_DROPDOWN_LABEL: MvnCodeFE;
  CASE_SYNC_CREATE_CASE_REQUEST_SUCC: MvnCodeFE;
  CASE_SYNC_CREATE_CASE_REQUEST_FAIL: MvnCodeFE;
  CASE_SYNC_EMPTY_CASE_SYNC_TITLE: MvnCodeFE;
  CASE_SYNC_EMPTY_CASE_SYNC_DESCRIPTION: MvnCodeFE;
  CASE_SYNC_UPDATE: MvnCodeFE;
  CASE_SYNC_UPSERTABLE_DATA_LIST_SYNC_DELETED_CHIP_TOOLTIP: MvnCodeFE;
  CASE_SYNC_UPSERTABLE_DATA_LIST_SAFETY_DELETED_CHIP_TOOLTIP: MvnCodeFE;
  CASE_SYNC_UPDATE_DIALOG_DESC: MvnCodeFE;
  CASE_SYNC_UPDATE_DIALOG_LOCKED_CASE_DESC: MvnCodeFE;
  CASE_SYNC_UPDATE_DIALOG_INVALID_WORKFLOW_DESC: MvnCodeFE;
  CASE_SYNC_UPDATE_DIALOG_NOT_AUTHORIZED_DESC: MvnCodeFE;
  CASE_SYNC_UPDATE_DIALOG_EMPTY_TITLE: MvnCodeFE;
  CASE_SYNC_UPDATE_SYNC_DATA_SUCC: MvnCodeFE;
  CASE_SYNC_UPDATE_SYNC_DATA_FAIL: MvnCodeFE;
  CASE_SYNC_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: MvnCodeFE;
  CASE_SYNC_DELETE_CASE_DIALOG_TITLE: MvnCodeFE;
  CASE_SYNC_DELETE_CASE_DIALOG_DESC: MvnCodeFE;
  CASE_SYNC_DELETE_CASE_DISABLED: MvnCodeFE;
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_TITLE: MvnCodeFE;
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_001: MvnCodeFE;
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_002: MvnCodeFE;
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_003: MvnCodeFE;
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_004: MvnCodeFE;
} = {
  CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_001: {
    ko: "선택한 이상사례를 Safety Case로 입력하시겠습니까?",
    en: "Are you sure you want to import selected AEs?",
  },
  CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_002: {
    ko: "고유식별 보고자관리번호를 입력해주세요.",
    en: "Please enter a new unique case identification.",
  },
  CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_003: {
    ko: "워크스페이스 설정으로 인해 식별번호가 자동으로 할당됩니다.",
    en: "The case identifier is generated automatically based on the workspace settings.",
  },
  CASE_SYNC_IDENTIFIER_DIALOG_INPUT_LABEL: {
    ko: "Unique Case Identification",
    en: "Unique Case Identification",
  },
  CASE_SYNC_IDENTIFIER_DIALOG_INPUT_AUTO_IDENTIFIER_VALUE: {
    ko: "{New Unique Case Identification}",
    en: "{New Unique Case Identification}",
  },
  CASE_SYNC_IDENTIFIER_DIALOG_INPUT_INVALID_DUPLICATED: {
    ko: "이미 존재하는 고유식별 보고자관리번호입니다.",
    en: "This unique case identification is already used.",
  },
  CASE_SYNC_MULTI_DIALOG_DESCRIPTION: {
    ko: "선택한 이상사례들을 1개의 Safety Case으로 생성하시겠습니까?",
    en: "Are you sure you want to import selected AEs as single case?",
  },
  CASE_SYNC_PAGE_TITLE_DESCRIPTION: {
    ko: "CDMS에서 전송된 이상사례를 확인하고 관리할 수 있습니다.",
    en: "Check and manage adverse events sent from CDMS.",
  },
  CASE_SYNC_DIALOG_TITLE: {
    ko: "Synchronized Subject / AE List",
    en: "Synchronized Subject / AE List",
  },
  CASE_SYNC_DIALOG_CONFIRM_BUTTON: {
    ko: "동기화",
    en: "Sync",
  },
  CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_DESCRIPTION: {
    ko: "선택한 이상사례를 추가할 Workspace와 Case Version을 선택해주세요.",
    en: "Please select a workspace and case version to create a selected case.",
  },
  CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_WORKSPACE_DROPDOWN_LABEL: {
    ko: "Workspace Name (Protocol No)",
    en: "Workspace Name (Protocol No)",
  },
  CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_CRF_DROPDOWN_LABEL: {
    ko: "Case Version",
    en: "Case Version",
  },
  CASE_SYNC_CREATE_CASE_REQUEST_SUCC: {
    ko: "Case 생성이 요청되었습니다.",
    en: "Creating new Case has been requested.",
  },
  CASE_SYNC_CREATE_CASE_REQUEST_FAIL: {
    ko: "Case 생성 요청이 실패했습니다. ({message})",
    en: "Failed to request to create a case. ({message})",
  },
  CASE_SYNC_EMPTY_CASE_SYNC_TITLE: {
    ko: "등록된 데이터가 없습니다",
    en: "No registered data",
  },
  CASE_SYNC_EMPTY_CASE_SYNC_DESCRIPTION: {
    ko: "CDMS에서 데이터를 전송해주세요",
    en: "Send data from CDMS",
  },
  CASE_SYNC_UPDATE: {
    ko: "Sync Update",
    en: "Sync Update",
  },
  CASE_SYNC_UPSERTABLE_DATA_LIST_SYNC_DELETED_CHIP_TOOLTIP: {
    ko: "CDMS에서 삭제된 항목입니다.",
    en: "This element was deleted from CDMS.",
  },
  CASE_SYNC_UPSERTABLE_DATA_LIST_SAFETY_DELETED_CHIP_TOOLTIP: {
    ko: "Safety에서 삭제된 항목입니다. 업데이트가 불가능합니다.",
    en: "This element was deleted in Safety. Update data is not available.",
  },
  CASE_SYNC_UPDATE_DIALOG_DESC: {
    ko: "업데이트할 항목을 선택해주세요.",
    en: "Please select items to update.",
  },
  CASE_SYNC_UPDATE_DIALOG_LOCKED_CASE_DESC: {
    ko: "잠겨 있는 Case 입니다. 잠금 해제 후 Sync Update가 가능합니다.",
    en: "This case is locked. You can update the case after unlocking the case.",
  },
  CASE_SYNC_UPDATE_DIALOG_INVALID_WORKFLOW_DESC: {
    ko: "Sync Update는 Workflow 상태가 'Case Assign' 또는 'Data Entry' 인 경우에만 가능합니다.",
    en: "You can update the case, if workflow status is 'Case Assign' or 'Data entry'.",
  },
  CASE_SYNC_UPDATE_DIALOG_NOT_AUTHORIZED_DESC: {
    ko: "Case를 업데이트 할 수 있는 권한이 없습니다.",
    en: "You have no permission to update the case.",
  },
  CASE_SYNC_UPDATE_DIALOG_EMPTY_TITLE: {
    ko: "업데이트할 항목이 없습니다.",
    en: "No item to update.",
  },
  CASE_SYNC_UPDATE_SYNC_DATA_SUCC: {
    ko: "Sync update가 완료되었습니다.",
    en: "Sync update has been completed successfully.",
  },
  CASE_SYNC_UPDATE_SYNC_DATA_FAIL: {
    ko: "Sync Update가 실패했습니다. ({message})",
    en: "Failed to sync update. ({message})",
  },
  CASE_SYNC_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. Sync update를 진행하시겠습니까?",
    en: "You have unsaved data. Do you want to process sync update?",
  },
  CASE_SYNC_DELETE_CASE_DIALOG_TITLE: {
    ko: "{case}이 삭제되었습니다.",
    en: "{case} was deleted.",
  },
  CASE_SYNC_DELETE_CASE_DIALOG_DESC: {
    ko: "CDMS에서 해당 Case가 삭제되었습니다. 해당 Case를 삭제하시겠습니까?",
    en: "This case was deleted from CDMS. Do you want to delete this case?",
  },
  CASE_SYNC_DELETE_CASE_DISABLED: {
    ko: "Workspace의 Case 삭제 기능이 활성화되어야 삭제가 가능합니다.",
    en: "The case can be deleted only when case deletion is enabled in the workspace.",
  },
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_TITLE: {
    ko: "{dictionary} Version 불일치",
    en: "{dictionary} Version Mismatcheds",
  },
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_001: {
    ko: "CDMS에서 해당 Case의 {dictionary} Version이 변경되었습니다.",
    en: "The {dictionary} Version of the case has changed in CDMS.",
  },
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_002: {
    ko: "Safety {dictionary} Version: {version}",
    en: "Safety {dictionary} Version: {version}",
  },
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_003: {
    ko: "CDMS {dictionary} Version: {version}",
    en: "CDMS {dictionary} Version: {version}",
  },
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DIALOG_DESC_004: {
    ko: "두 시스템의 {dictionary} Version이 일치해야 Case Sync Update가 가능합니다.\nCDMS {dictionary} Version을 변경 후 다시 시도하시기 바랍니다.",
    en: "The {dictionary} Version of the two systems(Safety and CDMS systems) must match for Case Sync Update to work.\nPlease change the CDMS {dictionary} Version and try again.",
  },
};

MvnCodeInitiate(SAFETY_CASE_SYNC_LANG);

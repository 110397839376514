import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_ECOA_CAPTURE_CODE: {
  CDMS_ECOA_SUBJECT_STATUS_NOT_AVAILABLE: MvnCodeBE;
} = {
  CDMS_ECOA_SUBJECT_STATUS_NOT_AVAILABLE: {
    status: 400,
    ko: "대상자를 확인할 수 없습니다. 임상시험 실시기관으로 문의해 주세요.",
    en: "The Subject can't be verified. Please contact the clinical trail site.",
  },
};

MvnCodeInitiate(CDMS_ECOA_CAPTURE_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_FILE_CODE: {
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_EACH_FILE_SIZE_MAXLEN_EXCEED: MvnCodeBE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_NAME_FORMAT: MvnCodeBE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_SUBJECT_NOT_EXIST: MvnCodeBE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_CRF_VERSION: MvnCodeBE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_CRF_NOT_EXIST: MvnCodeBE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_CRF_NOT_AVAILABLE: MvnCodeBE;
} = {
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_EACH_FILE_SIZE_MAXLEN_EXCEED: {
    status: 413,
    ko: "개별 파일 최대 용량({maxFileSize})MB 초과",
    en: "The individual file exceeds the maximum size of ({maxFileSize}) MB.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_NAME_FORMAT: {
    status: 409,
    ko: "File Name Format과 다른 File Name",
    en: "File name differs from file name format.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_SUBJECT_NOT_EXIST: {
    status: 409,
    ko: "Subject 부재",
    en: "Subject No. Not Found",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_CRF_VERSION: {
    status: 409,
    ko: "CRF Version 불일치",
    en: "CRF Version Mismatch",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_CRF_NOT_EXIST: {
    status: 409,
    ko: "CRF Page 부재",
    en: "CRF Page Not Found",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_INVALID_CRF_NOT_AVAILABLE: {
    status: 409,
    ko: "비활성화된 Page",
    en: "Disabled Page",
  },
};

MvnCodeInitiate(CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_FILE_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_SUBJECT_CODE: {
  CDMS_SUBJECT_CONTACT_NOT_AVAILABLE: MvnCodeBE;
  CDMS_SUBJECT_STATUS_NOT_AVAILABLE: MvnCodeBE;
} = {
  CDMS_SUBJECT_CONTACT_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study 대상자({id}) 전화번호 정보가 올바르지 않습니다.",
    en: "Requested study subject({id}) contact is not valid.",
  },
  CDMS_SUBJECT_STATUS_NOT_AVAILABLE: {
    status: 400,
    ko: "대상자 비활성화(N/A) 상태",
    en: "Subject's status is NotAvailable.",
  },
};

MvnCodeInitiate(CDMS_STUDY_SUBJECT_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_CERTIFICATE_LANG: {
  CDMS_STUDY_CERTIFICATE_BREADCRUMB: MvnCodeFE;
  CDMS_STUDY_CERTIFICATE_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CERTIFICATE_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_CERTIFICATE_BREADCRUMB: {
    ko: "Certificates",
    en: "Certificates",
  },
  CDMS_STUDY_CERTIFICATE_PAGE_TITLE: {
    ko: "Manage Certificate",
    en: "Manage Certificate",
  },
  CDMS_STUDY_CERTIFICATE_PAGE_DESC: {
    ko: "임상시험 스터디 구성원의 E-Training 인증서 목록을 확인할 수 있습니다.",
    en: "Inquire E-Training certificates of subjects.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CERTIFICATE_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const TMF_STUDY_NOTIFICATION_LANG: {
  TMF_STUDY_DOCUMENT_QC_REQ_MAIL_TITLE: MvnCodeFE;
  TMF_STUDY_DOCUMENT_QC_REQ_MAIL_CONTENT_CHECK: MvnCodeFE;
  TMF_STUDY_DOCUMENT_QC_APPROVED_MAIL_TITLE: MvnCodeFE;
  TMF_STUDY_DOCUMENT_QC_APPROVED_MAIL_CONTENT_CHECK: MvnCodeFE;
  TMF_STUDY_DOCUMENT_QC_REJECTED_MAIL_TITLE: MvnCodeFE;
  TMF_STUDY_DOCUMENT_QC_REJECTED_MAIL_CONTENT_CHECK: MvnCodeFE;
  TMF_STUDY_DOCUMENT_QC_CANCELLED_MAIL_TITLE: MvnCodeFE;
  TMF_STUDY_DOCUMENT_QC_CANCELLED_MAIL_CONTENT_CHECK: MvnCodeFE;
} = {
  TMF_STUDY_DOCUMENT_QC_REQ_MAIL_TITLE: {
    ko: "[Maven TMF] Document Quality Check Review 요청이 있습니다.",
    en: "[Maven TMF] There is a request for a Document Quality Check Review.",
  },
  TMF_STUDY_DOCUMENT_QC_REQ_MAIL_CONTENT_CHECK: {
    ko: "아래 내용을 확인해 주시기 바랍니다.",
    en: "Please review the following content.",
  },

  TMF_STUDY_DOCUMENT_QC_APPROVED_MAIL_TITLE: {
    ko: "[Maven TMF] Document Quality Check Review가 승인되었습니다.",
    en: "[Maven TMF] The Document Quality Check Review has been approved.",
  },
  TMF_STUDY_DOCUMENT_QC_APPROVED_MAIL_CONTENT_CHECK: {
    ko: "아래 내용을 확인해 주시기 바랍니다.",
    en: "Please review the following content.",
  },

  TMF_STUDY_DOCUMENT_QC_REJECTED_MAIL_TITLE: {
    ko: "[Maven TMF] Document Quality Check Review가 반려되었습니다.",
    en: "[Maven TMF] The Document Quality Check Review has been rejected.",
  },
  TMF_STUDY_DOCUMENT_QC_REJECTED_MAIL_CONTENT_CHECK: {
    ko: "아래 내용을 확인해 주시기 바랍니다.",
    en: "Please review the following content.",
  },

  TMF_STUDY_DOCUMENT_QC_CANCELLED_MAIL_TITLE: {
    ko: "[Maven TMF] Document Quality Check Review 요청이 취소되었습니다.",
    en: "[Maven TMF] The request for Document Quality Check Review has been cancelled.",
  },
  TMF_STUDY_DOCUMENT_QC_CANCELLED_MAIL_CONTENT_CHECK: {
    ko: "아래 내용을 확인해 주시기 바랍니다.",
    en: "Please review the following content.",
  },
};

MvnCodeInitiate(TMF_STUDY_NOTIFICATION_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_LAB_NORMAL_RANGE_LANG: {
  CDMS_STUDY_LAB_NORMAL_RANGE_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_LAB_NORMAL_RANGE_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_LAB_NORMAL_RANGE_PAGE_TITLE: {
    ko: "Lab Normal Range",
    en: "Lab Normal Range",
  },
  CDMS_STUDY_LAB_NORMAL_RANGE_PAGE_DESC: {
    ko: "Lab Normal Range를 추가하고 관리합니다.",
    en: "Add and manage lab normal ranges.",
  },
};

MvnCodeInitiate(CDMS_STUDY_LAB_NORMAL_RANGE_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_SCHEDULE: {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_FOLDER_IDENTIFIER_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_IDENTIFIER_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_CODE_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_PROPERTY: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_FOLDER_INVALID_IDENTIFIER_LENGTH_EXCEEDED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_VISIT_INVALID_IDENTIFIER_LENGTH_EXCEEDED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_PROPERTY_MISSING",
    status: 400,
    ko: "'{property}' 필드는 필수 입력값입니다.",
    en: "'{property}' field is required.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_FOLDER_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_FOLDER_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "고유한 Folder ID를 입력해 주세요.",
    en: "Please enter a unique Folder ID.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "고유한 Visit ID를 입력해 주세요.",
    en: "Please enter a unique Visit ID.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_CODE_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_CODE_DUPLICATED",
    status: 409,
    ko: "고유한 Visit Code를 입력해 주세요.",
    en: "Please enter a unique Visit Code.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_PROPERTY: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_MODIFY_INVALID_VISIT_PROPERTY",
    status: 400,
    ko: "‘{property}’({value}) 값이 유효하지 않습니다.",
    en: "‘{property}’({value}) field has an invalid value.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_FOLDER_INVALID_IDENTIFIER_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_FOLDER_INVALID_IDENTIFIER_LENGTH_EXCEEDED",
    status: 400,
    ko: "Folder identifier는 {length}자를 초과할 수 없습니다.",
    en: "The folder identifier must not exceed {length} characters.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_VISIT_INVALID_IDENTIFIER_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_VISIT_INVALID_IDENTIFIER_LENGTH_EXCEEDED",
    status: 400,
    ko: "Visit identifier는 {length}자를 초과할 수 없습니다.",
    en: "The visit identifier must not exceed {length} characters.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_SCHEDULE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_WORKSPACE_ITEM_LANG: {
  SAFETY_WORKSPACE_ITEM_DELETE_INVALID_MULTI_SELECT: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_INVALID_LOCKED_CASE: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_INVALID_WORKFLOW_STATUS: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_DESCRIPTION_001: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_CASE_IDENTIFIER_INPUT_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_DESCRIPTION_002: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_DESCRIPTION_003: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_INVALID_ACCESS: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_EMPTY_SECTION_TITLE: MvnCodeFE;
  SAFETY_WORKSPACE_ITEM_EMPTY_SECTION_DESCRIPTION: MvnCodeFE;
} = {
  SAFETY_WORKSPACE_ITEM_DELETE_INVALID_MULTI_SELECT: {
    ko: "1개의 Folder 또는 Case를 선택해주세요.",
    en: "Please select a single case or folder.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_INVALID_LOCKED_CASE: {
    ko: "잠긴 Case는 삭제할 수 없습니다.",
    en: "Locked case cannot be deleted.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_INVALID_WORKFLOW_STATUS: {
    ko: "{status} 상태가 아닌 Case는 삭제할 수 없습니다.",
    en: "You cannot delete a Case that is not in {status}.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_DIALOG_HEADER: {
    ko: "Delete Folder",
    en: "Delete Folder",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_DIALOG_DESCRIPTION: {
    ko: "정말로 폴더를 삭제하시겠습니까?",
    en: "Are you sure you want to permanently delete folder?",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_SUCC: {
    ko: "폴더를 삭제하였습니다.",
    en: "Folder has been deleted successfully.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_FOLDER_FAIL: {
    ko: "폴더 삭제에 실패했습니다. ({error})",
    en: "Failed to delete folder. ({error})",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_HEADER: {
    ko: "Case Delete",
    en: "Case Delete",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_CASE_IDENTIFIER_INPUT_LABEL: {
    ko: "삭제할 보고자관리자번호를 입력하세요.",
    en: "Please enter a case identification to delete.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_DESCRIPTION_001: {
    ko: "선택한 Case를 삭제하시겠습니까?",
    en: "Are you sure you want to delete a selected case?",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_DESCRIPTION_002: {
    ko: "삭제된 Case는 복구가 불가능합니다.",
    en: "Deleted case cannot be recovered.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_DIALOG_DESCRIPTION_003: {
    ko: "Case에서 사용된 포인트와 Export한 파일 다운로드, 보고자관리번호도 포함됩니다.",
    en: "It includes Case Identification, Used Point to export and redownload of exported file.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_SUCC: {
    ko: "Case를 삭제하였습니다.",
    en: "Case has been deleted successfully.",
  },
  SAFETY_WORKSPACE_ITEM_DELETE_CASE_FAIL: {
    ko: "Case 삭제에 실패했습니다. ({error})",
    en: "Failed to delete case. ({error})",
  },
  SAFETY_WORKSPACE_ITEM_INVALID_ACCESS: {
    ko: "{workspaceItem}에 접근할 수 없습니다. 해당 {workspaceItem}이/가 존재하지 않거나 삭제되었습니다.",
    en: "Failed to access {workspaceItem}. {workspaceItem} does not exist or has been deleted.",
  },
  SAFETY_WORKSPACE_ITEM_EMPTY_SECTION_TITLE: {
    ko: "아이템이 없습니다",
    en: "No Item",
  },
  SAFETY_WORKSPACE_ITEM_EMPTY_SECTION_DESCRIPTION: {
    ko: "아이템을 생성해주세요",
    en: "Create a new item",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_ITEM_LANG);

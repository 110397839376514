import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_EXPORT_CODE: {
  BUILDER_STUDY_PAYLOAD_EXPORT_FAIL_LAB_TEST_TARGET_ITEM_INVALID: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_EXPORT_FAIL_LAB_TEST_TARGET_ITEM_INVALID: {
    code: "BUILDER_STUDY_PAYLOAD_EXPORT_FAIL_LAB_TEST_TARGET_ITEM_INVALID",
    status: 400,
    ko: "실험실 검사 항목 요소의 target 아이템이 해당 페이로드에서 유효하지 않습니다. ({pageId}:{sectionId}:{itemId})",
    en: "The target item of lab test factor is not valid in this payload. ({pageId}:{sectionId}:{itemId})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_EXPORT_CODE);

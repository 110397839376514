import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_USER_LANG: {
  CDMS_STUDY_USER_BREADCRUMB: MvnCodeFE;
  CDMS_STUDY_USER_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_USER_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_USER_ROLE_NOT_FOUND_ERROR: MvnCodeFE;
  CDMS_STUDY_USER_UNASSIGNED_ROLE_ACCESS_ERROR: MvnCodeFE;
  CDMS_STUDY_USER_FULL_NAME_REQUIRED: MvnCodeFE;
  CDMS_STUDY_USER_ACTIVATE_DATE_REQUIRED: MvnCodeFE;
} = {
  CDMS_STUDY_USER_BREADCRUMB: {
    ko: "Users",
    en: "Users",
  },
  CDMS_STUDY_USER_PAGE_TITLE: {
    ko: "Manage User",
    en: "Manage User",
  },
  CDMS_STUDY_USER_PAGE_DESC: {
    ko: "등록된 Study 사용자를 확인하고 관리할 수 있습니다.",
    en: "Inquire and manage study Users.",
  },
  CDMS_STUDY_USER_ROLE_NOT_FOUND_ERROR: {
    ko: "존재하지 않는 Role로 접근하였습니다. Study List 화면으로 이동합니다. ({message})",
    en: "You have accessed the study with a role that does not exist. Returning to the study list. ({message})",
  },
  CDMS_STUDY_USER_UNASSIGNED_ROLE_ACCESS_ERROR: {
    ko: "부여받지 않은 Role로 접근하였습니다. Study List 화면으로 이동합니다. ({message})",
    en: "You have accessed the study with a role not assigned. Returning to the study list. ({message})",
  },
  CDMS_STUDY_USER_FULL_NAME_REQUIRED: {
    ko: "Full Name은 필수 입력 값입니다.",
    en: "Full Name is mandatory.",
  },
  CDMS_STUDY_USER_ACTIVATE_DATE_REQUIRED: {
    ko: "계정 사용 시작 일자는 필수 입력 값입니다.",
    en: "Activate Date is mandatory.",
  },
};

MvnCodeInitiate(CDMS_STUDY_USER_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const DICOM_VIEWER_LANG: {
  DICOM_VIEWER_DICOM_FILE_LIST: MvnCodeFE;
  DICOM_VIEWER_ACCESS_DENIED_VIEWER: MvnCodeFE;
  DICOM_VIEWER_REQUEST_FILE_ERROR: MvnCodeFE;
  DICOM_VIEWER_ARROW_ANNOTATION_ALERT: MvnCodeFE;
} = {
  DICOM_VIEWER_DICOM_FILE_LIST: { ko: "File List", en: "File List" },
  DICOM_VIEWER_ACCESS_DENIED_VIEWER: {
    ko: "이 페이지에 접근할 수 있는 권한이 없습니다. 이전 페이지로 다시 접속해 주세요.",
    en: "You have no access rights to this page. Please return to the previous page and try again.",
  },
  DICOM_VIEWER_REQUEST_FILE_ERROR: {
    ko: "첨부파일을 불러오지 못했습니다. 올바른 파일인지 확인 후 다시 시도해 주세요.",
    en: "The attached file could not be loaded. Please check if the file is valid and try again.",
  },
  DICOM_VIEWER_ARROW_ANNOTATION_ALERT: {
    ko: "화살표와 함께 표시할 문구를 입력하세요.",
    en: "Enter the text to display with the arrow.",
  },
};

MvnCodeInitiate(DICOM_VIEWER_LANG);

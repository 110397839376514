import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_CODE: {
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_IDENTIFIER_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_REQUIRED_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_COLUMN_INVALID_REQUIRED_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_IDENTIFIER_LENGTH_EXCEEDED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_LABEL_LENGTH_EXCEEDED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_DUPLICATED",
    status: 409,
    ko: "해당 Coding({identifier})이 이미 존재합니다.",
    en: "Coding({identifier}) already exists.",
  },
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_IDENTIFIER_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_IDENTIFIER_MISSING",
    status: 400,
    ko: "Category ID를 입력해 주세요.",
    en: "Please enter a category ID.",
  },
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_REQUIRED_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_REQUIRED_PROPERTY_MISSING",
    status: 400,
    ko: "Coding의 필수값이 누락되었습니다. (ID: {identifier}, property: {property})",
    en: "Required property of coding is missing. (ID: {identifier}, property: {property})",
  },
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_COLUMN_INVALID_REQUIRED_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_COLUMN_INVALID_REQUIRED_PROPERTY_MISSING",
    status: 400,
    ko: "Coding Column의 필수값이 누락되었습니다. (category ID: {identifier}, column: {column}, property: {property})",
    en: "Required property of coding column is missing. (category ID: {identifier}, column: {column}, property: {property})",
  },
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_IDENTIFIER_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_IDENTIFIER_LENGTH_EXCEEDED",
    status: 400,
    ko: "Coding identifier는 {length}자를 초과할 수 없습니다.",
    en: "The coding identifier must not exceed {length} characters.",
  },
  BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_LABEL_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_INVALID_LABEL_LENGTH_EXCEEDED",
    status: 400,
    ko: "Coding label은 {length}자를 초과할 수 없습니다.",
    en: "The coding label must not exceed {length} characters.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_MEDICAL_CODING_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_ROLE_LANG: {
  BUILDER_STUDY_ROLE_AND_PRIVILEGES_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  BUILDER_STUDY_ROLE_ADD_BUTTON_LABEL: MvnCodeFE;
  BUILDER_STUDY_ROLE_ADD_SUCC: MvnCodeFE;
  BUILDER_STUDY_ROLE_ADD_FAIL: MvnCodeFE;
  BUILDER_STUDY_ROLE_NO_PRIVILEGES: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPDATE_SUCC: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPDATE_FAIL: MvnCodeFE;
  BUILDER_STUDY_ROLE_DELETE_SUCC: MvnCodeFE;
  BUILDER_STUDY_ROLE_DELETE_FAIL: MvnCodeFE;
  BUILDER_STUDY_ROLE_EMPTY: MvnCodeFE;
  BUILDER_STUDY_ROLE_DELETE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_LIST_DOWNLOAD_SUCC: MvnCodeFE;
  BUILDER_STUDY_ROLE_LIST_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  BUILDER_STUDY_ROLE_AND_PRIVILEGES_PAGE_TITLE_DESCRIPTION: {
    ko: "Study role과 권한을 관리할 수 있습니다.",
    en: "Manage study roles and privileges.",
  },
  BUILDER_STUDY_ROLE_ADD_BUTTON_LABEL: {
    ko: "Role 추가",
    en: "Add Role",
  },
  BUILDER_STUDY_ROLE_ADD_SUCC: {
    ko: "Role({name})을 성공적으로 추가했습니다.",
    en: "You have added role({name}) successfully.",
  },
  BUILDER_STUDY_ROLE_ADD_FAIL: {
    ko: "Role 추가에 실패했습니다. ({message})",
    en: "Failed to add the role. ({message})",
  },
  BUILDER_STUDY_ROLE_NO_PRIVILEGES: {
    ko: "포함된 권한 없음.",
    en: "No privileges included",
  },
  BUILDER_STUDY_ROLE_UPDATE_SUCC: {
    ko: "Role({name})을 성공적으로 수정했습니다.",
    en: "You have updated role({name}) successfully.",
  },
  BUILDER_STUDY_ROLE_UPDATE_FAIL: {
    ko: "Role 정보 수정에 실패했습니다. ({message})",
    en: "Failed to update the role. ({message})",
  },
  BUILDER_STUDY_ROLE_DELETE_SUCC: {
    ko: "Role({name})을 성공적으로 삭제했습니다.",
    en: "You have deleted role({name}) successfully.",
  },
  BUILDER_STUDY_ROLE_DELETE_FAIL: {
    ko: "Role 삭제에 실패했습니다. ({message})",
    en: "Failed to delete the role. ({message})",
  },
  BUILDER_STUDY_ROLE_EMPTY: {
    ko: "생성된 Role이 없습니다.",
    en: "No roles yet.",
  },
  BUILDER_STUDY_ROLE_DELETE_CONFIRM_DIALOG_TITLE: {
    ko: "Role 삭제가 반영되면, 해당 role에 할당된 사용자는 스터디에 접근할 수 없습니다.",
    en: "Once the role deletion is applied, the user that is assigned this role will not be accessible to the study.",
  },
  BUILDER_STUDY_ROLE_LIST_DOWNLOAD_SUCC: {
    ko: "Study R&P 파일을 성공적으로 다운로드하였습니다.",
    en: "You have downloaded the Study R&P file successfully.",
  },
  BUILDER_STUDY_ROLE_LIST_DOWNLOAD_FAIL: {
    ko: "Study R&P 파일 다운로드에 실패하였습니다. ({message})",
    en: "Failed to download Study R&P file. ({message})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_ROLE_LANG);

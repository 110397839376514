import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_ECOA_ACCESS_CODE_CODE: {
  CDMS_ECOA_ACCESS_CODE_NOT_FOUND: MvnCodeBE;
} = {
  CDMS_ECOA_ACCESS_CODE_NOT_FOUND: {
    status: 400,
    ko: "요청하신 Study 대상자({subjectNo})의 eCOA Access Code를 찾을 수 없습니다.",
    en: "The requested eCOA Access Code cannot be found.",
  },
};

MvnCodeInitiate(CDMS_ECOA_ACCESS_CODE_CODE);

/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const DOCS_DOCUMENT_CODE: {
  DOCS_DOCUMENT_FILE_TYPE_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_FILE_SIZE_EXCEED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_REQUIRED_EMAIL_REIGSTRATION: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_REQUIRED_EMAIL_VERIFIFCATION: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_REQUIRED_IDENTITY_VERIFIFCATION: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_EMAIL_NOT_MATCHED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_IDENTITY_NOT_MATCHED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_DRYRUN_SUCC: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_EMAIL_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_PHONE_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_STATUS_SIGNED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_STATUS_DECLINED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_STATUS_EXPIRED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_STATUS_CANCELED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_STATUS_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_SIGNDATE_MISSING: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_SIGNDATE_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_SIGNING_REASON_MISSING: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_SIGNING_VERIFICATION_MISSING: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_SIGNATURE_LIMIT_EXCEED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_ACCESS_TOKEN_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_RESP_FIELD_MISSING: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_RESP_FIELD_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_STAMP_API_FAIL: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_HISTORY_NOT_FOUND: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_REMIND_TIME_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_SIGNER_NOT_FOUND: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_SIGNER_STATUS_INVALID: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_ADD_SIGNER_LIMIT_EXCEED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_TEXT_MAX_LENGTH_EXCEED: MvnCodeBE;
  DOCS_DOCUMENT_FILE_EXPORT_SIGNER_NOT_FOUND: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_IMAGE_EMBED_FAILED: MvnCodeBE;
  DOCS_FILE_EXPORT_DOWNLOAD_EXPIRED: MvnCodeBE;
  DOCS_DOCUMENT_SIGN_ENCRYPTED_PDF_NOT_SUPPORTED: MvnCodeBE;
  DOCS_DOCUMENT_SEND_SCHEDULED_ECONSENT_PAST_TIME_SETTING: MvnCodeBE;
  DOCS_DOCUMENT_SEND_SCHEDULED_ECONSENT_INNER_FIVE_MINUTE_SETTING: MvnCodeBE;
  DOCS_DOCUMENT_CANCEL_SCHEDULED_ECONSENT_POSSIBLE_STATUS: MvnCodeBE;
} = {
  DOCS_DOCUMENT_FILE_TYPE_INVALID: {
    status: 400,
    ko: "{fileType} 확장자의 파일만 업로드할 수 있습니다.",
    en: "You can upload a file {fileType} only",
  },
  DOCS_DOCUMENT_FILE_SIZE_EXCEED: {
    status: 400,
    ko: "PDF 파일은 최대 {mb}MB까지 업로드할 수 있습니다.",
    en: "You can upload a PDF file up to {mb}MB.",
  },
  DOCS_DOCUMENT_SIGN_TEXT_MAX_LENGTH_EXCEED: {
    status: 400,
    ko: "최대 {length}자로 작성해 주세요.",
    en: "You can enter the title up to {length} characters.",
  },
  DOCS_DOCUMENT_SIGN_IMAGE_EMBED_FAILED: {
    code: "DOCS_DOCUMENT_SIGN_IMAGE_EMBED_FAILED",
    status: 500,
    ko: "전자서명 이미지 처리에 실패하였습니다. 잠시 후 다시 시도해주세요 {message}",
    en: "Failed to process the electronic signature image. Please try at later {message}"
  },
  DOCS_DOCUMENT_SIGN_REQUIRED_EMAIL_REIGSTRATION: {
    status: 409,
    ko: "이메일 인증을 진행해 주세요.",
    en: "Please proceed with email address verification.",
  },
  DOCS_DOCUMENT_SIGN_REQUIRED_EMAIL_VERIFIFCATION: {
    status: 400,
    ko: "이메일 인증을 진행해 주세요.",
    en: "Please proceed with email address verification.",
  },
  DOCS_DOCUMENT_SIGN_REQUIRED_IDENTITY_VERIFIFCATION: {
    status: 400,
    ko: "본인 인증을 진행해 주세요.",
    en: "Please proceed personal identity verification.",
  },
  DOCS_DOCUMENT_SIGN_EMAIL_NOT_MATCHED: {
    status: 400,
    ko: "이메일 정보가 일치하지 않습니다.",
    en: "Email address is not matched.",
  },
  DOCS_DOCUMENT_SIGN_IDENTITY_NOT_MATCHED: {
    status: 400,
    ko: "본인 인증 정보가 일치하지 않습니다.",
    en: "Personal identification is not matched.",
  },
  DOCS_DOCUMENT_SIGN_DRYRUN_SUCC: {
    status: 303,
    ko: "서명을 진행해 주세요.",
    en: "Please proceed e-sign process.",
  },
  DOCS_DOCUMENT_SIGN_EMAIL_INVALID: {
    status: 400,
    ko: "이메일 주소가 올바르지 않습니다. ({email})",
    en: "The specified email address is not valid. ({email})",
  },
  DOCS_DOCUMENT_SIGN_PHONE_INVALID: {
    status: 400,
    ko: "전화번호가 올바르지 않습니다. ({phone})",
    en: "The specified phone is not valid. ({phone})",
  },
  DOCS_DOCUMENT_SIGN_STATUS_SIGNED: {
    status: 409,
    ko: "이미 서명이 완료된 문서입니다.",
    en: "The requested document has already been e-signed.",
  },
  DOCS_DOCUMENT_SIGN_STATUS_DECLINED: {
    status: 409,
    ko: "이미 서명이 거절된 문서입니다.",
    en: "The requested document has already been declined.",
  },
  DOCS_DOCUMENT_SIGN_STATUS_EXPIRED: {
    status: 409,
    ko: "문서의 서명기한이 만료되었습니다.",
    en: "The requested document to signing has expired.",
  },
  DOCS_DOCUMENT_SIGN_STATUS_CANCELED: {
    status: 409,
    ko: "서명 요청이 취소된 문서입니다.",
    en: "The signing request to document was canceled.",
  },
  DOCS_DOCUMENT_SIGN_STATUS_INVALID: {
    status: 409,
    ko: "문서의 상태가 올바르지 않습니다.",
    en: "The requested document status is not valid.",
  },
  DOCS_DOCUMENT_SIGN_SIGNDATE_MISSING: {
    status: 400,
    ko: "서명일자가 입력되지 않았습니다.",
    en: "The sign date is missing.",
  },
  DOCS_DOCUMENT_SIGN_SIGNDATE_INVALID: {
    status: 400,
    ko: "서명일자가 올바르지 않습니다.",
    en: "The sign date is not valid.",
  },
  DOCS_DOCUMENT_SIGN_SIGNING_REASON_MISSING: {
    status: 400,
    ko: "서명사유를 입력해 주세요.",
    en: "Please enter the signing reason.",
  },
  DOCS_DOCUMENT_SIGN_SIGNING_VERIFICATION_MISSING: {
    status: 400,
    ko: "서명자 인증이 누락되었습니다.",
    en: "Please verify the signer.",
  },
  DOCS_DOCUMENT_SIGN_SIGNATURE_LIMIT_EXCEED: {
    status: 400,
    ko: "사용할 수 있는 서명 수를 초과하였습니다. 서명자별 최대 {maxSignatureCount}개까지 사용할 수 있습니다.",
    en: "Exceeded the maximum number of signatures. Up to {maxSignatureCount} signatures are available.",
  },
  DOCS_DOCUMENT_SIGN_ACCESS_TOKEN_INVALID: {
    status: 400,
    ko: "인증토큰이 올바르지 않습니다.",
    en: "The specified access token is not valid.",
  },
  DOCS_DOCUMENT_SIGN_RESP_FIELD_MISSING: {
    status: 400,
    ko: "응답값을 선택해 주세요.",
    en: "Please choose your answer.",
  },
  DOCS_DOCUMENT_SIGN_RESP_FIELD_INVALID: {
    status: 400,
    ko: "응답값이 올바르지 않습니다.",
    en: "The answer you chose is not valid.",
  },
  DOCS_DOCUMENT_SIGN_STAMP_API_FAIL: {
    status: 500,
    ko: "정보 연동에 실패했습니다. 잠시 후 다시 시도해 주세요.",
    en: "Failed to integrate the information. Please try again later.",
  },
  DOCS_DOCUMENT_SIGN_HISTORY_NOT_FOUND: {
    status: 400,
    ko: "문서 서명 내역이 존재하지 않습니다.",
    en: "Document sign history could not be found.",
  },
  DOCS_DOCUMENT_SIGN_REMIND_TIME_INVALID: {
    status: 400,
    ko: "문서 서명 재요청 시간이 유효하지 않습니다.",
    en: "The requested document sign time is not valid.",
  },
  DOCS_DOCUMENT_SIGN_SIGNER_NOT_FOUND: {
    status: 404,
    ko: "문서 서명자가 존재하지 않습니다.",
    en: "The document signer could not be found.",
  },
  DOCS_DOCUMENT_SIGN_SIGNER_STATUS_INVALID: {
    status: 409,
    ko: "문서 전송 상태가 올바르지 않습니다.",
    en: "The requested document delivery status is not valid.",
  },
  DOCS_DOCUMENT_SIGN_ADD_SIGNER_LIMIT_EXCEED: {
    status: 400,
    ko: "최대 1,000명까지 등록할 수 있습니다.",
    en: "You can add up to 1,000 subjects at once.",
  },
  DOCS_DOCUMENT_FILE_EXPORT_SIGNER_NOT_FOUND: {
    status: 404,
    ko: "서명 완료된 서명자가 존재하지 않습니다.",
    en: "The document signer could not be found.",
  },
  DOCS_FILE_EXPORT_DOWNLOAD_EXPIRED: {
    status: 409,
    ko: "다운로드 URL이 만료되었습니다.",
    en: "download url has expired.",
  },
  DOCS_DOCUMENT_SIGN_ENCRYPTED_PDF_NOT_SUPPORTED: {
    status: 400,
    ko: "암호화된 PDF 파일은 해당 기능을 지원하지 않습니다.",
    en: "The encrypted PDF file is not supported.",
  },
  DOCS_DOCUMENT_SEND_SCHEDULED_ECONSENT_PAST_TIME_SETTING: {
    status: 400,
    ko: "예약 발송 시간은 과거 시간으로 설정이 불가능합니다.",
    en: "It is unavailable to set scheduled time as the past time.",
  },
  DOCS_DOCUMENT_SEND_SCHEDULED_ECONSENT_INNER_FIVE_MINUTE_SETTING: {
    status: 400,
    ko: "현재 시간으로 부터 5분 이내 설정은 불가능합니다.",
    en: "It is unavailable to set scheduled time within 5 mins from the current time.",
  },
  DOCS_DOCUMENT_CANCEL_SCHEDULED_ECONSENT_POSSIBLE_STATUS: {
    status: 400,
    ko: "대기 상태인 예약만 발송 취소가 가능합니다.",
    en: "Only scheduled with a pending status can be canceled for dispatch.",
  },
};

MvnCodeInitiate(DOCS_DOCUMENT_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_DASHBOARD_LANG: {
  CDMS_DASHBOARD_BAR_SHAPE_STACK: MvnCodeFE;
  CDMS_DASHBOARD_PAGE_TITLE: MvnCodeFE;
} = {
  CDMS_DASHBOARD_BAR_SHAPE_STACK: {
    ko: "누적 그래프",
    en: "Stacked Bar Chart",
  },
  CDMS_DASHBOARD_PAGE_TITLE: {
    ko: "Dashboard",
    en: "Dashboard",
  },
};

MvnCodeInitiate(CDMS_DASHBOARD_LANG);

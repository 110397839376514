import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_CODE: {
  BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_INVALID_IDENTIFIER_LENGTH_EXCEED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_INVALID_IDENTIFIER_DUPLICATED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_INVALID_IDENTIFIER_LENGTH_EXCEED: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_INVALID_IDENTIFIER_LENGTH_EXCEED",
    status: 400,
    ko: "Trigger version identifier는 최대 {length}자 입력 가능합니다.",
    en: "The Trigger version identifier can be up to {length} characters long.",
  },
  BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_INVALID_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_INVALID_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "중복된 Trigger version identifier({identifier})가 존재합니다.",
    en: "Trigger version identifier({identifier}) is duplicated.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_TRIGGER_VERSION_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_DIGEST_DASHBOARD_LANG: {
  CDMS_DIGEST_DASHBOARD_PAGE_TITLE: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_PAGE_DESC: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_SUMMARY: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_STATUS: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_STATISTICS: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_RESULT_BY_MONTH: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_RESULT_BY_MONTH_DESC: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_STATISTICS_STATUS_COUNT_PER_MONTH_LABEL: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_STATISTICS_STATUS_SUM_PER_MONTH_LABEL: MvnCodeFE;
  CDMS_DIGEST_DASHBOARD_STATISTICS_STATUS_EMPTY: MvnCodeFE;
} = {
  CDMS_DIGEST_DASHBOARD_PAGE_TITLE: {
    ko: "Digest",
    en: "Digest",
  },
  CDMS_DIGEST_DASHBOARD_PAGE_DESC: {
    ko: "Audit Trail에 대한 Digest를 확인하고 데이터 위변조 여부를 모니터링 할 수 있습니다.",
    en: "Validate data integrity in audit trail.",
  },
  CDMS_DIGEST_DASHBOARD_SUMMARY: {
    ko: "Digest Summary",
    en: "Digest Summary",
  },
  CDMS_DIGEST_DASHBOARD_STATUS: {
    ko: "Digest Status",
    en: "Digest Status",
  },
  CDMS_DIGEST_DASHBOARD_STATISTICS: {
    ko: "Digest Statistics",
    en: "Digest Statistics",
  },
  CDMS_DIGEST_DASHBOARD_RESULT_BY_MONTH: {
    ko: "Digest Result by Month",
    en: "Digest Result by Month",
  },
  CDMS_DIGEST_DASHBOARD_RESULT_BY_MONTH_DESC: {
    ko: "Digest 결과에 대한 월별 누적 수와 누적 합계를 볼 수 있습니다.",
    en: "This diagram shows monthly cumulative count and cumulative total of Digest results.",
  },
  CDMS_DIGEST_DASHBOARD_STATISTICS_STATUS_COUNT_PER_MONTH_LABEL: {
    ko: "월별 누적 수",
    en: "cumulative count by month",
  },
  CDMS_DIGEST_DASHBOARD_STATISTICS_STATUS_SUM_PER_MONTH_LABEL: {
    ko: "월별 누적 합계",
    en: "cumulative total by month",
  },
  CDMS_DIGEST_DASHBOARD_STATISTICS_STATUS_EMPTY: {
    ko: "보고싶은 Digest Status를 선택해 주세요.",
    en: "Please select the digest status you would like to see.",
  },
};

MvnCodeInitiate(CDMS_DIGEST_DASHBOARD_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_SYNC_HISTORY_CODE: {
  BUILDER_STUDY_PAYLOAD_SYNC_INVALID_LOCKED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_SYNC_INVALID_LOCKED: {
    code: "BUILDER_STUDY_PAYLOAD_SYNC_INVALID_LOCKED",
    status: 400,
    ko: "Push 및 Publish, Template 추가 작업 수행 중에는 {property}를 변경할 수 없습니다.",
    en: "You cannot change {property} while performing Push, Publish and add Template operation.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_SYNC_HISTORY_CODE);

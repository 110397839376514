import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CONVERTER_STUDY_DATASET_CODE: {
  CONVERTER_STUDY_DATASET_NAME_DUPLICATED: MvnCodeBE;
} = {
  CONVERTER_STUDY_DATASET_NAME_DUPLICATED: {
    status: 409,
    ko: "Dataset 이름이 이미 존재합니다.",
    en: "The dataset name already exists.",
  },
};

MvnCodeInitiate(CONVERTER_STUDY_DATASET_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_IMPORT_CODE: {
  BUILDER_STUDY_PAYLOAD_IMPORT_INVALID_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_IMPORT_INVALID_ITEM_DOMAIN_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_IMPORT_INVALID_ITEM_FORMAT_MISSING: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_IMPORT_INVALID_FORMAT: {
    status: 400,
    ko: "Import한 Payload 형식이 올바르지 않습니다.",
    en: "Imported payload format is not valid.",
  },
  BUILDER_STUDY_PAYLOAD_IMPORT_INVALID_ITEM_DOMAIN_MISSING: {
    status: 400,
    ko: "유효하지 않은 아이템입니다. Domain 정보가 없습니다. (OID: {id})",
    en: "Invalid Item, Domain is missing. (OID: {id})",
  },
  BUILDER_STUDY_PAYLOAD_IMPORT_INVALID_ITEM_FORMAT_MISSING: {
    status: 400,
    ko: "유효하지 않은 아이템입니다. Item의 Format 정보가 없습니다. (OID: {id})",
    en: "Invalid Item, Item format is missing. (OID: {id})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_IMPORT_CODE);

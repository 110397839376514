import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CONVERTER_STUDY_CODE: {
  CONVERTER_STUDY_NOT_FOUND: MvnCodeBE;
} = {
  CONVERTER_STUDY_NOT_FOUND: {
    status: 404,
    ko: "Study를 찾을 수 없습니다.",
    en: "Study is not found.",
  },
};

MvnCodeInitiate(CONVERTER_STUDY_CODE);

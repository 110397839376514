import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_IP_ACCOUNTABILITY_LANG: {
  CDMS_STUDY_IP_ACCOUNTABILITY_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_IP_ACCOUNTABILITY_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_IP_ACCOUNTABILITY_PAGE_TITLE: {
    ko: "IP Accountability",
    en: "IP Accountability",
  },
  CDMS_STUDY_IP_ACCOUNTABILITY_PAGE_DESC: {
    ko: "IP 수불기록을 다운로드 하거나 불출/반납/CRA 서명 정보를 조회할 수 있습니다.",
    en: "Download IP Accountability and inquire dispense/return/CRA signature relevant IP.",
  },
};

MvnCodeInitiate(CDMS_STUDY_IP_ACCOUNTABILITY_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_TRAINING_CODE: {
  CDMS_TRAINING_CORRECT_ANSWER_ALREADY_EXIST: MvnCodeBE;
} = {
  CDMS_TRAINING_CORRECT_ANSWER_ALREADY_EXIST: {
    status: 409,
    ko: "이미 정답으로 등록된 Answer가 있습니다.",
    en: "An answer already exists that has been registered as the correct answer.",
  },
};

MvnCodeInitiate(CDMS_TRAINING_CODE);

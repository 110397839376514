import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_ROLE_PRIVILEGE_TITLE_LANG: {
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_CAPTURE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_ON_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_OFF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_RANDOM_NO_RETRIEVE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_ADD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_REMOVE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_ENTIRE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_VISIT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_CRF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_REFRESH_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_TRIGGER_INVOKE_ALL_EXPLICITLY_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_OPEN_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_ANSWER_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_CLOSE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUTO_QUERY_CLOSE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_MANUAL_QUERY_CLOSE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SAME_ROLE_MANUAL_QUERY_CLOSE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_UPLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_NOTIFICATION_EMAIL_QUERY_MANAGEMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUDIT_TRAIL_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_DATA_AUDIT_TRAIL_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_AUDIT_TRAIL_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_AUDIT_TRAIL_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_AUDIT_TRAIL_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_AUDIT_TRAIL_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_AUDIT_TRAIL_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_SUBMIT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_MANAGEMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_INIT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_REQUEST_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_APPROVE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_UNDER_CONDITIONS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_FORCE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_ON_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_OFF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_ON_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_OFF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_ON_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_OFF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_ON_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_OFF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_ON_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_OFF_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DB_SPEC_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_BLANK_CRF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_INCLUDE_NON_CRF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_MANAGEMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RNP_MANAGEMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_MANAGEMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_MANAGEMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_API_MANAGEMENT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_LOCK_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_UNLOCK_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TERMINATE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CREATION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CREATION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SAVE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CANCEL_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CANCEL_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CREATION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SAVE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CANCEL_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CREATION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SAVE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CANCEL_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BY_SITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_DATA_CRF_CREATE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_TEMPLATE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_01_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_02_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_03_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_04_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_05_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_RETRIEVE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_ALLOCATE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_RETRIEVE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRAINING_CERTIFICATE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_ACCEPT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_APPLY_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_COMPLETE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_CANCEL_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_MIGRATE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_REPORT_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_OPEN_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_CLOSE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_STATISTICS_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_EDIT_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_VIEW_BELONGS_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_RELEASE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_VIEW_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_WRITE_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_ADMINISTRATION_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_FILE_UPLOAD_NOTIFICATION_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_LIST_DOWNLOAD_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_COMPLETE_NOTIFICATION_TITLE: MvnCodeFE;
} = {
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_VIEW_TITLE: {
    ko: "스터디 접근 활성화",
    en: "Enable study access",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_VIEW_TITLE: {
    ko: "스터디 공지사항 조회",
    en: "View study notice",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_WRITE_TITLE: {
    ko: "스터디 공지사항 작성",
    en: "Write study notice",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_TITLE: {
    ko: "사전 대상자 목록 조회",
    en: "View pre-subject list",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 사전 대상자 목록 조회",
    en: "View pre-subject list (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_TITLE: {
    ko: "사전 대상자 문자 발송 내역 조회",
    en: "Pre-Subject SMS History View (All Sites)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 사전 대상자 문자 발송 내역 조회",
    en: "Pre-Subject SMS History View (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_DOWNLOAD_TITLE: {
    ko: "사전 대상자 문자 발송 내역 다운로드",
    en: "Pre-Subject SMS History Download",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 사전 대상자 전화번호 조회",
    en: "View pre-subject contact (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_WRITE_TITLE: {
    ko: "사전 대상자 전화번호 등록/수정/삭제",
    en: "Register/edit/delete pre-subject contact",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_TITLE: {
    ko: "대상자 목록 조회",
    en: "View subject list",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 목록 조회",
    en: "View subject list (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_VIEW_TITLE: {
    ko: "대상자 CRF 조회",
    en: "View subject CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_CAPTURE_TITLE: {
    ko: "대상자 CRF 입력",
    en: "Input subject CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_ON_TITLE: {
    ko: "대상자 CRF 비활성화",
    en: "Disable subject CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_OFF_TITLE: {
    ko: "대상자 CRF 비활성화 해제",
    en: "Cancel disabled subject CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_TITLE: {
    ko: "대상자 CRFs 다운로드",
    en: "Export subject CRFs",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_BELONGS_TITLE: {
    ko: "본인 소속 대상자 CRFs 다운로드",
    en: "Export subject CRFs (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_TITLE: {
    ko: "대상자 등록",
    en: "Subject Registration",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_BELONGS_TITLE: {
    ko: "본인 소속 대상자 등록",
    en: "Subject Registration (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_TITLE: {
    ko: "대상자 일괄 등록",
    en: "Register subject by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_BELONGS_TITLE: {
    ko: "본인 소속 대상자 일괄 등록",
    en: "Register subject by the batch (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_RANDOM_NO_RETRIEVE_TITLE: {
    ko: "대상자 무작위 배정 번호 회수",
    en: "Retrieve randomization number",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_ADD_TITLE: {
    ko: "대상자 Visit 추가",
    en: "Add subject visit",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_REMOVE_TITLE: {
    ko: "대상자 Visit 삭제",
    en: "Delete subject visit",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_ENTIRE_TITLE: {
    ko: "대상자 전체 초기화",
    en: "Initialize subject",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_VISIT_TITLE: {
    ko: "대상자 Visit 초기화",
    en: "Initialize subject visit",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_CRF_TITLE: {
    ko: "대상자 CRF 초기화",
    en: "Initialize subject CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_REFRESH_TITLE: {
    ko: "대상자 CRF 새로고침",
    en: "Refresh subject CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_TRIGGER_INVOKE_ALL_EXPLICITLY_TITLE: {
    ko: "대상자 전체 Trigger 재수행",
    en: "Re-run subject trigger",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_TITLE: {
    ko: "대상자 전화번호 조회",
    en: "View subject contact",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 전화번호 조회",
    en: "View subject contact (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_WRITE_TITLE: {
    ko: "대상자 전화번호 등록/수정/삭제",
    en: "Register/edit/delete subject contact",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_TITLE: {
    ko: "대상자 문자 발송 내역 조회",
    en: "Subject SMS History View (All Sites)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 문자 발송 내역 조회",
    en: "Subject SMS History View (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_DOWNLOAD_TITLE: {
    ko: "대상자 문자 발송 내역 다운로드",
    en: "Subject SMS History Download",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_TITLE: {
    ko: "Query 목록 조회",
    en: "View query list",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 Query 조회",
    en: "View query list (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_OPEN_TITLE: {
    ko: "Query 발행",
    en: "Open Query",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_ANSWER_TITLE: {
    ko: "Query 응답",
    en: "Answer Query",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_CLOSE_TITLE: {
    ko: "Query 종료",
    en: "Close Query",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUTO_QUERY_CLOSE_TITLE: {
    ko: "Auto Query 종료",
    en: "Close Auto Query",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_MANUAL_QUERY_CLOSE_TITLE: {
    ko: "Manual Query 종료",
    en: "Close Manual Query",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SAME_ROLE_MANUAL_QUERY_CLOSE_TITLE: {
    ko: "Same Role Manual Query 종료",
    en: "Close same role manual query",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_UPLOAD_TITLE: {
    ko: "Query 발행 (업로드)",
    en: "Upload query",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_NOTIFICATION_EMAIL_QUERY_MANAGEMENT_TITLE: {
    ko: "Query Notification 관리",
    en: "Query Notification Management",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_VIEW_TITLE: {
    ko: "ASL Upload 조회",
    en: "ASL Upload View",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_TITLE: {
    ko: "ASL List Upload",
    en: "ASL List Upload",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_VIEW_TITLE: {
    ko: "Comment 조회",
    en: "View comment",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_WRITE_TITLE: {
    ko: "Comment 작성",
    en: "Write comment",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUDIT_TRAIL_VIEW_TITLE: {
    ko: "Audit Trail 조회",
    en: "View audit trail",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_DATA_AUDIT_TRAIL_VIEW_TITLE: {
    ko: "Data Audit Trail 조회",
    en: "View data audit trail",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_AUDIT_TRAIL_VIEW_TITLE: {
    ko: "Query Audit Trail 조회",
    en: "View query audit trail",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_AUDIT_TRAIL_VIEW_TITLE: {
    ko: "SDV Audit Trail 조회",
    en: "View SDV audit trail",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_AUDIT_TRAIL_VIEW_TITLE: {
    ko: "Freezing Audit Trail 조회",
    en: "View freezing audit trail",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_AUDIT_TRAIL_VIEW_TITLE: {
    ko: "E-Sign Audit Trail 조회",
    en: "View E-sign audit trail",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_AUDIT_TRAIL_VIEW_TITLE: {
    ko: "Locking Audit Trail 조회",
    en: "View locking audit trail",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_TITLE: {
    ko: "AE/SAE Report 조회",
    en: "View AE/SAE report",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 Report 목록 조회",
    en: "View report list (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_WRITE_TITLE: {
    ko: "AE/SAE Report 작성 및 저장",
    en: "Write and Save AE/SAE report",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_SUBMIT_TITLE: {
    ko: "AE/SAE Report 제출",
    en: "Submit AE/SAE report",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_DOWNLOAD_TITLE: {
    ko: "Report 다운로드",
    en: "Export report",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_VIEW_TITLE: {
    ko: "Safety 연동 내역 조회",
    en: "afety Integration History View",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_MANAGEMENT_TITLE: {
    ko: "PV User 관리 및 Safety 연동 Organization 지정",
    en: "Safety Integration PV Management",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_VIEW_TITLE: {
    ko: "Coding 목록 조회",
    en: "View coding list",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_WRITE_TITLE: {
    ko: "Coding 작성",
    en: "Medical coding",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_TITLE: {
    ko: "eCOA Access Code 조회",
    en: "View eCOA access code",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 eCOA Access Code 조회",
    en: "View eCOA access code (user site) ",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_TITLE: {
    ko: "eCOA Page 조회",
    en: "View eCOA page",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 eCOA Page 조회",
    en: "View eCOA page for (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_WRITE_TITLE: {
    ko: "eCOA Page 입력",
    en: "Input eCOA page",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_INIT_TITLE: {
    ko: "대상자 eCOA Page 초기화",
    en: "Initialize subject eCOA page",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_TITLE: {
    ko: "RTSM CRF 조회",
    en: "View RTSM CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 RTSM CRF 조회",
    en: "View RTSM CRF (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_WRITE_TITLE: {
    ko: "RTSM CRF 입력",
    en: "Write RTSM CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_REQUEST_TITLE: {
    ko: "눈가림 해제 요청",
    en: "Request unblind",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_APPROVE_TITLE: {
    ko: "눈가림 해제 승인",
    en: "Approve unblind",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_TITLE: {
    ko: "눈가림 해제",
    en: "Unblind",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_UNDER_CONDITIONS_TITLE: {
    ko: "눈가림 해제(명시된 조건 만족)",
    en: "Unblind (under met conditions)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_VIEW_TITLE: {
    ko: "이중맹검 과제 눈가림 해제시 무작위 배정 그룹 조회",
    en: "View unblind (randomized groups)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_FORCE_VIEW_TITLE: {
    ko: "이중맹검 과제 무작위 배정 그룹 조회",
    en: "Force view unblind (randomized groups )",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_TITLE: {
    ko: "일괄 SDV 목록 조회",
    en: "View SDV list by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 대상자 일괄 SDV 목록 조회",
    en: "View user site SDV list ",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_ON_TITLE: {
    ko: "일괄 SDV 처리",
    en: "Process SDV by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_OFF_TITLE: {
    ko: "일괄 SDV 해제",
    en: "Clear SDV by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_TITLE: {
    ko: "일괄 Freezing 목록 조회",
    en: "View freezing list by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 대상자 일괄 Freezing 목록 조회",
    en: "View user site freezing list",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_ON_TITLE: {
    ko: "일괄 Freezing 처리",
    en: "Process freezing by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_OFF_TITLE: {
    ko: "일괄 Freezing 해제",
    en: "Clear freezing by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_TITLE: {
    ko: "일괄 전자서명 목록 조회",
    en: "View e-Sign list by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 대상자 일괄 전자서명 목록 조회",
    en: "View user site e-Sign list ",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_ON_TITLE: {
    ko: "일괄 전자서명 처리",
    en: "Process e-Sign by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_OFF_TITLE: {
    ko: "일괄 전자서명 해제",
    en: "Clear e-Sign by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_TITLE: {
    ko: "일괄 CRF Lock 목록 조회",
    en: "View CRF Lock list by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 대상자 일괄 CRF Lock 목록 조회",
    en: "View CRF lock list (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_ON_TITLE: {
    ko: "일괄 CRF Lock 처리",
    en: "Process CRF lock by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_OFF_TITLE: {
    ko: "일괄 CRF Lock 해제",
    en: "Clear CRF lock by the batch",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_ON_BELONGS_TITLE: {
    ko: "본인 소속 기관 두번째 전자서명 처리",
    en: "Second E-Sign - ON (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_OFF_BELONGS_TITLE: {
    ko: "본인 소속 기관 두번째 전자서명 해제",
    en: "Second E-Sign - OFF (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_VIEW_TITLE: {
    ko: "IP Info 조회",
    en: "View IP information",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_WRITE_TITLE: {
    ko: "IP Info 입력",
    en: "Input IP Info",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_TITLE: {
    ko: "IP 배송 조회",
    en: "View IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 IP 배송 조회",
    en: "View IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_TITLE: {
    ko: "IP 배송 요청",
    en: "Request IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_BELONGS_TITLE: {
    ko: "본인 소속 IP 배송 요청",
    en: "Request IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_TITLE: {
    ko: "IP 배송 승인",
    en: "Approve IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_BELONGS_TITLE: {
    ko: "본인 소속 IP 배송 승인",
    en: "Approve IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_TITLE: {
    ko: "IP 배송 출발",
    en: "Start IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_BELONGS_TITLE: {
    ko: "본인 소속 IP 배송 출발",
    en: "Start IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_TITLE: {
    ko: "IP 배송 완료",
    en: "Complete IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_BELONGS_TITLE: {
    ko: "본인 소속 IP 배송 완료",
    en: "Complete IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_TITLE: {
    ko: "출하요청서 발급",
    en: "Request delivery document",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_BELONGS_TITLE: {
    ko: "본인 소속 출하요청서 발급",
    en: "Request delivery document (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_TITLE: {
    ko: "인수확인증 발급",
    en: "Issue delivery confirmation document",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_BELONGS_TITLE: {
    ko: "본인 소속 인수확인증 발급",
    en: "Issue delivery confirmation (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_TITLE: {
    ko: "IP 자동 배송 조건 조회",
    en: "View automatic IP delivery conditions",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 IP 자동 배송 조건 조회",
    en: "View automatic IP delivery conditions (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_TITLE: {
    ko: "IP 자동 배송 조건 입력",
    en: "Write automatic IP delivery conditions",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_BELONGS_TITLE: {
    ko: "본인 소속 IP 자동 배송 조건 입력",
    en: "Write automatic IP delivery conditions (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_TITLE: {
    ko: "IPM CRF 조회",
    en: "View IPM CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 대상자 IPM CRF 조회",
    en: "View IPM CRF (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_WRITE_TITLE: {
    ko: "IPM CRF 입력",
    en: "Write IPM CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_TITLE: {
    ko: "IP 재고 정보 조회",
    en: "View IP stock",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 IP 재고 정보 조회",
    en: "View IP Stock (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_TITLE: {
    ko: "Pharmacy IP 재고 정보 수정(반납 / 에러)",
    en: "Edit (return/error)  pharmacy IP info",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_BELONGS_TITLE: {
    ko: "본인 소속 Pharmacy IP 재고 정보 수정(반납 / 에러)",
    en: "Edit (return/error) pharmacy IP info (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_TITLE: {
    ko: "IP 수불기록 정보 조회",
    en: "View IP accountability",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 IP 수불기록 정보 조회",
    en: "View IP accountability (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_TITLE: {
    ko: "수불기록 발급",
    en: "Issue Accountability document",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_BELONGS_TITLE: {
    ko: "본인 소속 수불기록 발급",
    en: "Issue accountability document (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_TITLE: {
    ko: "Error IP 조회",
    en: "View IP Error",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 Error IP 조회",
    en: "View IP error (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_TITLE: {
    ko: "Error IP 수정",
    en: "Edit IP error",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_BELONGS_TITLE: {
    ko: "본인 소속 Error IP 수정",
    en: "Edit IP error (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_TITLE: {
    ko: "IP 반납 요청 조회",
    en: "View IP return request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 IP 반납 요청 조회",
    en: "View IP return request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_TITLE: {
    ko: "IP 반납 요청",
    en: "Request IP return",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_BELONGS_TITLE: {
    ko: "본인 소속 IP 반납 요청",
    en: "Request IP return (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_TITLE: {
    ko: "IP 반납 승인",
    en: "Approve IP return",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_BELONGS_TITLE: {
    ko: "본인 소속 IP 반납 승인",
    en: "Approve IP return (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_TITLE: {
    ko: "반납확인증 발급",
    en: "Issue certificate of IP return",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_BELONGS_TITLE: {
    ko: "본인 소속 반납확인증 발급",
    en: "Issue certificate of IP return (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_TITLE: {
    ko: "IP 상태 조회",
    en: "View IP status",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 상태 조회",
    en: "View IP status (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_TITLE: {
    ko: "EDC Dataset 추출",
    en: "Export EDC dataset",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_BELONGS_TITLE: {
    ko: "본인 소속 기관 EDC Dataset 추출",
    en: "Export EDC dataset (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_TITLE: {
    ko: "Lab Dataset 추출",
    en: "Export lab dataset",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_BELONGS_TITLE: {
    ko: "본인 소속 기관 Lab Dataset 추출",
    en: "Export lab dataset (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_TITLE: {
    ko: "eCOA Dataset 추출",
    en: "Export eCOA dataset",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_BELONGS_TITLE: {
    ko: "본인 소속 기관 eCOA Dataset 추출",
    en: "Export eCOA dataset (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DB_SPEC_TITLE: {
    ko: "DB Specification 다운로드",
    en: "Export DB specification",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_BLANK_CRF_TITLE: {
    ko: "Blank CRFs 다운로드",
    en: "Export DB specification (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_TITLE: {
    ko: "Data CRFs 다운로드",
    en: "Export data CRFs",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_BELONGS_TITLE: {
    ko: "본인 소속 기관 Data CRFs 다운로드",
    en: "Export data CRFs (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_TITLE: {
    ko: "Audit Trail(Data) 다운로드",
    en: "Export audit trail (data)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_BELONGS_TITLE: {
    ko: "본인 소속 기관 Audit Trail(Data) 다운로드",
    en: "Export audit trail (data) (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_TITLE: {
    ko: "Audit Trail(Query) 다운로드",
    en: "Export audit trail (query)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_BELONGS_TITLE: {
    ko: "본인 소속 기관 Audit Trail(Query) 다운로드",
    en: "Export audit trail (query) (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_TITLE: {
    ko: "Comment 다운로드",
    en: "Export comment",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_BELONGS_TITLE: {
    ko: "본인 소속 기관 Comment 다운로드",
    en: "Export comment (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_INCLUDE_NON_CRF_TITLE: {
    ko: "Non-CRF 포함 DataWarehouse 다운로드",
    en: "Download DataWarehouse data including Non-CRF",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_TITLE: {
    ko: "Subject 상태 통계 목록 조회",
    en: "View subject status statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 Subject 상태 통계 목록 조회",
    en: "View subject status statistics (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_DOWNLOAD_TITLE: {
    ko: "Subject 상태 통계 다운로드",
    en: "Export subject status statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_TITLE: {
    ko: "CRF Page 별 입력 상태 조회",
    en: "View input status (per CRF)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관의 CRF Page 입력 상태 조회",
    en: "View input status (per CRF) (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_DOWNLOAD_TITLE: {
    ko: "CRF Page 별 입력 상태 다운로드",
    en: "Export input status (per CRF)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_TITLE: {
    ko: "Visit별 입력 상태 조회",
    en: "View input status (per visit)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관의 Visit 별 입력 상태 조회",
    en: "View input status (per visit) (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_DOWNLOAD_TITLE: {
    ko: "Visit별 입력 상태 다운로드",
    en: "Export input status (per visit)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_TITLE: {
    ko: "기관별 입력 상태 조회",
    en: "View input status (per site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관의 입력 상태 조회",
    en: "View input status (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_DOWNLOAD_TITLE: {
    ko: "기관별 입력 상태 다운로드",
    en: "Export input status (per site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_TITLE: {
    ko: "Custom Data Report 1 통계 목록 조회",
    en: "View custom data report 1 statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 Custom Data Report 1 통계 목록 조회",
    en: "View custom data report 1 statistics (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_DOWNLOAD_TITLE: {
    ko: "Custom Data Report 1 통계 다운로드",
    en: "Export custom data report 1 statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_TITLE: {
    ko: "Custom Data Report 2 통계 목록 조회",
    en: "View custom data report 2 statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 Custom Data Report 2 통계 목록 조회",
    en: "View custom data report 2 statistics (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_DOWNLOAD_TITLE: {
    ko: "Custom Data Report 2 통계 다운로드",
    en: "Export custom data report 2 statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_TITLE: {
    ko: "Custom Data Report 3 통계 목록 조회",
    en: "View custom data report 3 statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 Custom Data Report 3 통계 목록 조회",
    en: "View custom data report 3 statistics (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_DOWNLOAD_TITLE: {
    ko: "Custom Data Report 3 통계 다운로드",
    en: "Export custom data report 3 statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_VIEW_TITLE: {
    ko: "Data Integration(EDC) 내역 조회",
    en: "View data Integration (EDC) history",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_TITLE: {
    ko: "Data Integration(EDC)",
    en: "Data Integration (EDC)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_BELONGS_TITLE: {
    ko: "본인 소속 기관 Data Integration(EDC)",
    en: "Data Integration (EDC) (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_VIEW_TITLE: {
    ko: "Data Integration(LAB) 내역 조회",
    en: "View data Integration (LAB) history",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_TITLE: {
    ko: "Data Integration(LAB)",
    en: "Data Integration (LAB)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_BELONGS_TITLE: {
    ko: "본인 소속 기관 Data Integration(LAB)",
    en: "Data Integration (LAB) (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_VIEW_TITLE: {
    ko: "Data Integration(Attachment) 내역 조회",
    en: "Data Integration(Attachment) View",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_MANAGEMENT_TITLE: {
    ko: "Data Integration(Attachment) 수행",
    en: "Data Integration(Attachment) Management",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_TITLE: {
    ko: "Data Export Request",
    en: "Data Export request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_BELONGS_TITLE: {
    ko: "본인 소속 기관 Data Export Request",
    en: "Data Export request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_TITLE: {
    ko: "Digest 조회",
    en: "View Digest - function to confirm/detect the history of forgery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 기관 Digest 조회",
    en: "View Digest (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RNP_MANAGEMENT_TITLE: {
    ko: "스터디 R&P 관리",
    en: "Manage study R&P",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_VIEW_TITLE: {
    ko: "스터디 사용자 조회",
    en: "View study users",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_MANAGEMENT_TITLE: {
    ko: "스터디 사용자 관리",
    en: "Manage study users",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_VIEW_TITLE: {
    ko: "스터디 Site 조회",
    en: "View study site",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_MANAGEMENT_TITLE: {
    ko: "스터디 Site 관리",
    en: "Manage study site",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_API_MANAGEMENT_TITLE: {
    ko: "스터디 API 관리",
    en: "Study API Management",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_LOCK_TITLE: {
    ko: "DB Lock",
    en: "DB lock",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_UNLOCK_TITLE: {
    ko: "DB Unlock",
    en: "DB unlock",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TERMINATE_TITLE: {
    ko: "스터디 종료",
    en: "Terminate study",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_TITLE: {
    ko: "SAE Report 생성 및 제출 알림",
    en: "Notice of SAE report creation and submission",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_BELONGS_TITLE: {
    ko: "본인 소속 기관 SAE Report 생성 및 제출 알림",
    en: "Notice of SAE report creation and submission (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_TITLE: {
    ko: "AE Report 생성 및 제출 알림",
    en: "Notice of AE report creation and submission",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_BELONGS_TITLE: {
    ko: "본인 소속 기관 AE Report 생성 및 제출 알림",
    en: "Notice of AE report creation and submission (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CREATION_BELONGS_TITLE: {
    ko: "본인 소속 기관 SAE Report 생성 알림",
    en: "SAE Report Creation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CREATION_BELONGS_TITLE: {
    ko: "본인 소속 기관 AE Report 생성 알림",
    en: "AE Report Creation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SAVE_BELONGS_TITLE: {
    ko: "본인 소속 기관 SAE Report 저장 알림",
    en: "SAE Report Save Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_BELONGS_TITLE: {
    ko: "본인 소속 기관 SAE Report 제출 알림",
    en: "SAE Report Submission Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: {
    ko: "본인 소속 기관 SAE Report 원본 데이터 변경 알림",
    en: "SAE Report Mapping Data Change Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: {
    ko: "본인 소속 기관 AE Report 원본 데이터 변경 알림",
    en: "AE Report Mapping Data Change Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: {
    ko: "본인 소속 기관 SAE Report 제출 알림 (첨부파일 포함)",
    en: "SAE Report Submission Notification with Attachment (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: {
    ko: "본인 소속 기관 AE Report 제출 알림 (첨부파일 포함)",
    en: "AE Report Submission Notification with Attachment (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CANCEL_BELONGS_TITLE: {
    ko: "본인 소속 기관 SAE Report 취소 알림",
    en: "SAE Report Cancellation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CANCEL_BELONGS_TITLE: {
    ko: "본인 소속 기관 AE Report 취소 알림",
    en: "AE Report Cancellation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CREATION_BELONGS_TITLE: {
    ko: "본인 소속 기관 PREGNANCY Report 생성 알림",
    en: "PREGNANCY Report Creation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SAVE_BELONGS_TITLE: {
    ko: "본인 소속 기관 PREGNANCY Report 저장 알림",
    en: "PREGNANCY Report Save Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_BELONGS_TITLE: {
    ko: "본인 소속 기관 PREGNANCY Report 제출 알림",
    en: "PREGNANCY Report Submission Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: {
    ko: "본인 소속 기관 PREGNANCY Report 제출 알림 (첨부파일 포함)",
    en: "PREGNANCY Report Submission Notification with Attachment (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CANCEL_BELONGS_TITLE: {
    ko: "본인 소속 기관 PREGNANCY Report 취소 알림",
    en: "PREGNANCY Report Cancellation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: {
    ko: "본인 소속 기관 PREGNANCY Report 원본 데이터 변경 알림",
    en: "PREGNANCY Report Mapping Data Change Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CREATION_BELONGS_TITLE: {
    ko: "본인 소속 기관 AESI Report 생성 알림",
    en: "AESI Report Creation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SAVE_BELONGS_TITLE: {
    ko: "본인 소속 기관 AESI Report 저장 알림",
    en: "AESI Report Save Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_BELONGS_TITLE: {
    ko: "본인 소속 기관 AESI Report 제출 알림",
    en: "AESI Report Submission Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_ATTACHMENT_BELONGS_TITLE: {
    ko: "본인 소속 기관 AESI Report 제출 알림 (첨부파일 포함)",
    en: "AESI Report Submission Notification with Attachment (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CANCEL_BELONGS_TITLE: {
    ko: "본인 소속 기관 AESI Report 취소 알림",
    en: "AESI Report Cancellation Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_MAPPING_DATA_CHANGED_BELONGS_TITLE: {
    ko: "본인 소속 기관 AESI Report 원본 데이터 변경 알림",
    en: "AESI Report Mapping Data Change Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_TITLE: {
    ko: "대상자 등록 알림",
    en: "Notice of subject registration",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_BELONGS_TITLE: {
    ko: "본인 소속기관 대상자 등록 알림",
    en: "Notice of subject registration (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_TITLE: {
    ko: "대상자 최초 등록 알림",
    en: "Notice of the first subject registration",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BY_SITE_TITLE: {
    ko: "기관별 최초 대상자 등록 알림",
    en: "Registration notice of first subject by site",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BELONGS_TITLE: {
    ko: "본인 소속 기관 최초 대상자 등록 알림",
    en: "Registration notice of the first subject (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_TITLE: {
    ko: "대상자 무작위 배정 번호 부여 알림",
    en: "Notice of assigning randomization number to subject",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_BELONGS_TITLE: {
    ko: "본인 소속기관 대상자 무작위 배정 번호 부여 알림",
    en: "Notice of assigning randomization number to subject (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_TITLE: {
    ko: "대상자 등록 번호 부여 알림",
    en: "Notice of assigning subject number to subject",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_BELONGS_TITLE: {
    ko: "본인 소속기관 대상자 등록 번호 부여 알림",
    en: "Notice of assigning subject number to subject (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_TITLE: {
    ko: "SDV 자동 해제 알림",
    en: "Notice of SDV auto-cancellation",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_BELONGS_TITLE: {
    ko: "본인 소속 기관 SDV 자동 해제 알림",
    en: "Notice of SDV auto-cancellation (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_TITLE: {
    ko: "전자서명 자동 해제 알림",
    en: "Notice of E-Sign auto-cancellation",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_BELONGS_TITLE: {
    ko: "본인 소속 기관 전자서명 자동 해제 알림",
    en: "Notice of E-Sign auto-cancellation (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_TITLE: {
    ko: "눈가림 해제 알림",
    en: "Notice of unblinding",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_BELONGS_TITLE: {
    ko: "본인 소속 기관 눈가림 해제 알림",
    en: "Notice of unblinding (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_TITLE: {
    ko: "IP 배송 요청 등록 알림",
    en: "Registration notice of IP delivery request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 배송 요청 등록 알림",
    en: "Registration notice of IP delivery request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_TITLE: {
    ko: "IP 자동 배송 요청 등록 알림",
    en: "Registration notice of IP delivery auto-request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 자동 배송 요청 등록 알림",
    en: "Registation notice of IP delivery auto-request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_TITLE: {
    ko: "IP 배송 취소 알림",
    en: "Cancellation notice of IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 배송 취소 알림",
    en: "Cancellation notice of IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_TITLE: {
    ko: "IP 배송 요청 승인 알림",
    en: "Approval notice of IP delivery request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 배송 요청 승인 알림",
    en: "Approval notice of IP delivery request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_TITLE: {
    ko: "IP 배송 출발 알림",
    en: "Dispatch notice of IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 배송 출발 알림",
    en: "Dispatch notice of IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_TITLE: {
    ko: "IP 배송 완료 알림",
    en: "Completion notice of IP delivery",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 배송 완료 알림",
    en: "Completion notice of IP delivery (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_DATA_CRF_CREATE_BELONGS_TITLE: {
    ko: "본인 소속기관 Data Combination File 데이터 추가/삭제/변경 알림",
    en: "Add Data/Change/Notice of change to Data Combination File (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_TEMPLATE_BELONGS_TITLE: {
    ko: "본인 소속기관 Custom Template 알림",
    en: "Custom Template Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_01_TITLE: {
    ko: "본인 소속기관 Study Trigger 제어 01",
    en: "Control study trigger 01 (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_02_TITLE: {
    ko: "본인 소속기관 Study Trigger 제어 02",
    en: "Control study trigger 02 (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_03_TITLE: {
    ko: "본인 소속기관 Study Trigger 제어 03",
    en: "Control study trigger 03 (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_04_TITLE: {
    ko: "본인 소속기관 Study Trigger 제어 04",
    en: "Control study trigger 04 (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_05_TITLE: {
    ko: "본인 소속기관 Study Trigger 제어 05",
    en: "Control study trigger 05 (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_TITLE: {
    ko: "IP Code 배정 알림",
    en: "Notice of IP code assignment",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_BELONGS_TITLE: {
    ko: "본인 소속기관 IP Code 배정 알림",
    en: "Notice of IP code assignment (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_RETRIEVE_BELONGS_TITLE: {
    ko: "본인 소속기관 IP Code 회수 알림",
    en: "IP Code Retrieval Notification (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_ALLOCATE_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 번호 배정",
    en: "Allocate IP Code (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_RETRIEVE_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 번호 회수",
    en: "Retrieve IP Code (Authorized site only)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_TITLE: {
    ko: "IP 불출 알림",
    en: "Notice of IP dispensation",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 불출 알림",
    en: "Notice of IP dispensation (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_TITLE: {
    ko: "IP 반납 알림",
    en: "Notice of IP return",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 반납 알림",
    en: "Notice of IP return (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_TITLE: {
    ko: "IP 반납 요청 등록 알림",
    en: "Registration notice of IP return request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 반납 요청 등록 알림",
    en: "Registration notice of IP return request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_TITLE: {
    ko: "IP 반납 요청 승인 알림",
    en: "Approval notice of IP return request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 반납 요청 승인 알림",
    en: "Approval notice of IP return request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_TITLE: {
    ko: "IP 반납 요청 취소 알림",
    en: "Cancellation notice of IP return request",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_BELONGS_TITLE: {
    ko: "본인 소속기관 IP 반납 요청 취소 알림",
    en: "Cancellation notice of IP return request (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_TITLE: {
    ko: "IP CRA 전자서명 해제 알림 수신 권한",
    en: "Privilege to receive cancellation notice of IP CRA E-Sign",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_BELONGS_TITLE: {
    ko: "본인 소속기관 IP CRA 전자서명 해제 알림 수신 권한",
    en: "Privilege to receive cancellation notice of IP CRA E-Sign (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRAINING_CERTIFICATE_VIEW_TITLE: {
    ko: "Study E-Training 인증서 조회",
    en: "View study e-training certificate",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_VIEW_TITLE: {
    ko: "Study UAT List 조회",
    en: "View study UAT list",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_WRITE_TITLE: {
    ko: "Study UAT 작성",
    en: "Write study UAT",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_ACCEPT_TITLE: {
    ko: "Study UAT Status 변경 (Accepted)",
    en: "Change study UAT status to accepted",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_APPLY_TITLE: {
    ko: "Study UAT Status 변경 (Applied)",
    en: "Change study UAT status to applied",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_COMPLETE_TITLE: {
    ko: "Study UAT Status 변경 (Completed)",
    en: "Change study UAT status to completed",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_CANCEL_TITLE: {
    ko: "Study UAT Status 변경 (Cancelled)",
    en: "Change study UAT status to cancelled",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_MIGRATE_TITLE: {
    ko: "Study UAT migrate",
    en: "Migrate study UAT",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_VIEW_TITLE: {
    ko: "Study UAT Comments 조회",
    en: "View study UAT comments",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_WRITE_TITLE: {
    ko: "Study UAT Comment 작성",
    en: "Write study UAT comment",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_REPORT_DOWNLOAD_TITLE: {
    ko: "Study UAT Report 다운로드",
    en: "Export study UAT report",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_OPEN_TITLE: {
    ko: "Study UAT 시작",
    en: "Open study UAT",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_CLOSE_TITLE: {
    ko: "Study UAT 종료",
    en: "Close study UAT",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_STATISTICS_VIEW_TITLE: {
    ko: "Study 통계정보 조회",
    en: "View study statistics",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_VIEW_TITLE: {
    ko: "Study Lab Normal Range 조회",
    en: "Lab Normal Range List View",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_EDIT_TITLE: {
    ko: "Study Lab Normal Range 편집",
    en: "Lab Normal Range List Edit",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_VIEW_TITLE: {
    ko: "Lab Normal Range Comment 조회",
    en: "Lab Normal Range Comment View",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_WRITE_TITLE: {
    ko: "Lab Normal Range Comment 작성",
    en: "Lab Normal Range Comment Write",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_VIEW_BELONGS_TITLE: {
    ko: "본인 소속 Data Combination File 목록 조회",
    en: "View Data Combination File List (user site)",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_DOWNLOAD_TITLE: {
    ko: "Data Combination File 다운로드",
    en: "Download Data Combination File",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_VIEW_TITLE: {
    ko: "Study Payload 조회",
    en: "View study payload",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_WRITE_TITLE: {
    ko: "Study Payload 설정",
    en: "Set study payload",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_RELEASE_TITLE: {
    ko: "Study Payload 배포",
    en: "Release study payload",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_VIEW_TITLE: {
    ko: "Study Randomization 조회",
    en: "View study randomization",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_WRITE_TITLE: {
    ko: "Study Randomization 설정",
    en: "Set up study randomization",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_VIEW_TITLE: {
    ko: "Study IP Management 조회",
    en: "View study IP management",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_WRITE_TITLE: {
    ko: "Study IP Management 설정",
    en: "Set up study IP management",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_VIEW_TITLE: {
    ko: "독립적 평가 목록 조회",
    en: "Independent Evaluation List View",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_WRITE_TITLE: {
    ko: "독립적 평가 작성",
    en: "Independent Evaluation CRFs Write",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_ADMINISTRATION_TITLE: {
    ko: "독립적 평가 원본 데이터 확인",
    en: "Independent Evaluation Administration",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_FILE_UPLOAD_NOTIFICATION_TITLE: {
    ko: "독립적 평가 파일 업로드 알림",
    en: "Independent Evaluation Upload Notification",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_LIST_DOWNLOAD_TITLE: {
    ko: "독립적 평가 목록 다운로드",
    en: "Independent Evaluation List Download",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_COMPLETE_NOTIFICATION_TITLE: {
    ko: "독립적 평가 완료 알림",
    en: "Independent Evaluation Complete Notification",
  },
};

MvnCodeInitiate(BUILDER_STUDY_ROLE_PRIVILEGE_TITLE_LANG);

export const BUILDER_STUDY_ROLE_PRIVILEGE_DESC_LANG: {
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_CAPTURE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_ON_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_OFF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_RANDOM_NO_RETRIEVE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_ADD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_REMOVE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_ENTIRE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_VISIT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_CRF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_REFRESH_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_TRIGGER_INVOKE_ALL_EXPLICITLY_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_OPEN_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_ANSWER_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_CLOSE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUTO_QUERY_CLOSE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_MANUAL_QUERY_CLOSE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SAME_ROLE_MANUAL_QUERY_CLOSE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_UPLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_NOTIFICATION_EMAIL_QUERY_MANAGEMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUDIT_TRAIL_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_DATA_AUDIT_TRAIL_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_AUDIT_TRAIL_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_AUDIT_TRAIL_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_AUDIT_TRAIL_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_AUDIT_TRAIL_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_AUDIT_TRAIL_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_SUBMIT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_MANAGEMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_INIT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_REQUEST_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_APPROVE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_UNDER_CONDITIONS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_FORCE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_ON_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_OFF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_ON_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_OFF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_ON_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_OFF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_ON_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_OFF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_ON_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_OFF_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DB_SPEC_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_BLANK_CRF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_INCLUDE_NON_CRF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_MANAGEMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RNP_MANAGEMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_MANAGEMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_MANAGEMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_API_MANAGEMENT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_LOCK_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_UNLOCK_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TERMINATE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CREATION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CREATION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SAVE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CANCEL_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CANCEL_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CREATION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SAVE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CANCEL_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CREATION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SAVE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CANCEL_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BY_SITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_DATA_CRF_CREATE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_TEMPLATE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_01_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_02_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_03_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_04_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_05_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_RETRIEVE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_ALLOCATE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_RETRIEVE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRAINING_CERTIFICATE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_ACCEPT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_APPLY_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_COMPLETE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_CANCEL_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_MIGRATE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_REPORT_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_OPEN_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_CLOSE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_STATISTICS_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_EDIT_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_VIEW_BELONGS_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_RELEASE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_VIEW_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_WRITE_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_ADMINISTRATION_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_FILE_UPLOAD_NOTIFICATION_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_LIST_DOWNLOAD_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_COMPLETE_NOTIFICATION_DESC: MvnCodeFE;
} = {
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_VIEW_DESC: {
    ko: "해당 스터디로 접근 할 수 있는 권한입니다.",
    en: "Privilege to access to the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_VIEW_DESC: {
    ko: "공지사항을 조회할 수 있는 권한입니다.",
    en: "Privilege to view study notice.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTICE_WRITE_DESC: {
    ko: "공지사항을 등록할 수 있는 권한입니다.",
    en: "Privilege to write study notice.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_DESC: {
    ko: "스터디에 등록된 사전 대상자 전체를 조회할 수 있는 권한입니다.",
    en: "Privilege to view all pre-subjects registered in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_LIST_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 사전 대상자에 한하여 조회할 수 있는 권한입니다.",
    en: "Privilege to view only pre-subject for user site.",
  },
  // TODO: 아래 3개 번역 요청 (2023.08.23)
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_DESC: {
    ko: "스터디에 등록된 사전 대상자의 문자 발송 내역을 조회할 수 있는 권한입니다.",
    en: "Privilege to view all pre-subject text message sending history in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 사전 대상자에 한하여 문자 발송 내역을 조회할 수 있는 권한입니다.",
    en: "Privilege to view only pre-subject text message sending history for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_SMS_HISTORY_DOWNLOAD_DESC: {
    ko: "스터디에 등록된 사전 대상자의 문자 발송 내역을 다운로드할 수 있는 권한입니다.",
    en: "Privilege to export pre-subject text message sending history for all registered subjects in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 사전 대상자에 한하여 전화번호 전체를 조회할 수 있는 권한입니다.",
    en: "Privilege to view pre-subject contact for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PRE_SUBJECT_CONTACT_WRITE_DESC: {
    ko: "스터디에 등록된 사전 대상자 전화번호를 등록/수정/삭제할 수 있는 권한입니다.",
    en: "Privilege to register/edit/delete pre-subject contact in the study",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_DESC: {
    ko: "스터디에 등록된 대상자 전체를 조회할 수 있는 권한입니다.",
    en: "Privilege to view all registered subjects in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_LIST_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 조회할 수 있는 권한입니다.",
    en: "Privilege to view only the subject for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_VIEW_DESC: {
    ko: "대상자별 CRF 페이지를 조회할 수 있는 권한입니다.",
    en: "Privilege to view CRF pages by subject.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_CAPTURE_DESC: {
    ko: "대상자별 CRF 데이터를 입력할 수 있는 권한입니다.",
    en: "Privilege to write CRF pages by subject.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_ON_DESC: {
    ko: "CRF 페이지를 비활성화할 수 있는 권한입니다.",
    en: "Privilege to disable CRF page.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DISABLE_OFF_DESC: {
    ko: "CRF 페이지 비활성화를 해제할 수 있는 권한입니다.",
    en: "Privilege to cancel disabled CRF page.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_DESC: {
    ko: "스터디에 등록된 대상자 전체의 CRFs를 다운로드 받을 수 있는 권한입니다.",
    en: "Privilege to export CRFs for all registered subjects in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_DOWNLOAD_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 CRFs를 다운로드 받을 수 있는 권한입니다.",
    en: "Privilege to export CRFs only the subjects registered for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_DESC: {
    ko: "스터디에 등록된 모든 기관에 대하여 대상자를 등록할 수 있는 권한입니다.",
    en: "Privilege to register subjects for all user sites in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_REGISTRATION_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 등록할 수 있는 권한입니다.",
    en: "Privilege to register only subjects for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_DESC: {
    ko: "스터디에 등록된 모든 기관에 대하여 대상자를 일괄 등록할 수 있는 권한입니다.",
    en: "Privilege to register subjects for all user sites by the batch in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_BULK_REGISTRATION_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 일괄 등록할 수 있는 권한입니다.",
    en: "Privilege to register subject user site by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_RANDOM_NO_RETRIEVE_DESC: {
    ko: "대상자 무작위 배정 번호를 회수할 수 있는 권한입니다.",
    en: "Privilege to retrieve randomization number.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_ADD_DESC: {
    ko: "Unscheduled Visit을 추가할 수 있는 권한입니다.",
    en: "Privilege to add unscheduled visit.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_VISIT_REMOVE_DESC: {
    ko: "추가된 Unscheduled Visit을 삭제할 수 있는 권한입니다.",
    en: "Privilege to delete unscheduled visit.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_ENTIRE_DESC: {
    ko: "대상자에 대하여 입력된 데이터를 초기화할 수 있는 권한입니다.",
    en: "Privilege to Initialize data entered for all subjects.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_VISIT_DESC: {
    ko: "대상자별 Visit에 대하여 입력된 데이터를 초기화할 수 있는 권한입니다.",
    en: "Privilege to Initialize data entered for each subject visit.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_INIT_CRF_DESC: {
    ko: "대상자별 CRF에 대하여 입력된 데이터를 초기화할 수 있는 권한입니다.",
    en: "Privilege to Initialize data entered for each subject CRF.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CRF_REFRESH_DESC: {
    ko: "대상자별 CRF에 대하여 입력된 데이터를 새로고침할 수 있는 권한입니다.",
    en: "Privilege to refresh data entered for each subject CRF.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_TRIGGER_INVOKE_ALL_EXPLICITLY_DESC: {
    ko: "대상자에 대하여 전체 Trigger를 재수행할 수 있는 권한입니다.",
    en: "Privilege to rerun all triggers for subject.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_DESC: {
    ko: "스터디에 등록된 대상자 전화번호 전체를 조회할 수 있는 권한입니다.",
    en: "Privilege to view all subject contacts in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 전화번호 전체를 조회할 수 있는 권한입니다.",
    en: "Privilege to view subject contact for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_CONTACT_WRITE_DESC: {
    ko: "스터디에 등록된 대상자 전화번호를 등록/수정/삭제할 수 있는 권한입니다.",
    en: "Privilege to register/edit/delete subject contact in the study.",
  },
  // TODO: 아래 3개 번역 요청 (2023.08.23)
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_DESC: {
    ko: "스터디에 등록된 대상자의 문자 발송 내역을 조회할 수 있는 권한입니다.",
    en: "Privilege to view all subject text message sending history in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 문자 발송 내역을 조회할 수 있는 권한입니다.",
    en: "Privilege to view only subject text message sending history for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SUBJECT_SMS_HISTORY_DOWNLOAD_DESC: {
    ko: "스터디에 등록된 대상자의 문자 발송 내역을 다운로드할 수 있는 권한입니다.",
    en: "Privilege to export subject text message sending history for all registered subjects in the study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_DESC: {
    ko: "대상자별로 발행된 Query 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view open query list for each subject.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 발행된 Query 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view open query list for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_OPEN_DESC: {
    ko: "Query를 발행할 수 있는 권한입니다.",
    en: "Privilege to open query.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_ANSWER_DESC: {
    ko: "발행된 Query에 대하여 응답할 수 있는 권한입니다.",
    en: "Privilege to answer query.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_CLOSE_DESC: {
    ko: "발행된 Query에 대하여 종료할 수 있는 권한입니다.",
    en: "Privilege to close query.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUTO_QUERY_CLOSE_DESC: {
    ko: "발행된 Auto Query에 대하여 종료할 수 있는 권한입니다.",
    en: "Privilege to close auto query.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_MANUAL_QUERY_CLOSE_DESC: {
    ko: "발행된 Manual Query에 대하여 종료할 수 있는 권한입니다.",
    en: "Privilege to close manual query.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SAME_ROLE_MANUAL_QUERY_CLOSE_DESC: {
    ko: "동일 소속 기관, 동일 Role에 의해 발행된 Manual Query에 대하여 종료할 수 있는 권한입니다.",
    en: "Privilege to close same role manual query.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_UPLOAD_DESC: {
    ko: "Query를 일괄 발행할 수 있는 권한입니다.",
    en: "Privilege to upload query.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_NOTIFICATION_EMAIL_QUERY_MANAGEMENT_DESC: {
    ko: "Query Notification을 관리할 수 있는 권한입니다.",
    en: "Privilege to manage query notification.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_VIEW_DESC: {
    ko: "ASL Upload 탭의 접근 권한입니다.",
    en: "Privilege to access the ASL upload tab.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ALWAYS_SERIOUS_LISTS_UPLOAD_DESC: {
    ko: "ASL Upload 화면에서 리스트를 업로드 할 수 있는 권한입니다.",
    en: "Privilege to upload ASL list.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_VIEW_DESC: {
    ko: "Comment를 조회할 수 있는 권한입니다.",
    en: "Privilege to view comments.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_COMMENT_WRITE_DESC: {
    ko: "Comment를 작성할 수 있는 권한입니다.",
    en: "Privilege to write comments.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_AUDIT_TRAIL_VIEW_DESC: {
    ko: "입력된 CRF에 대하여 Audit Trail을 조회할 수 있는 권한입니다.",
    en: "Privilege to view audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_DATA_AUDIT_TRAIL_VIEW_DESC: {
    ko: "입력된 CRF Data에 대하여 Audit Trail을 조회할 수 있는 권한입니다.",
    en: "Privilege to view data audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_QUERY_AUDIT_TRAIL_VIEW_DESC: {
    ko: "Query에 대하여 Audit Trail을 조회할 수 있는 권한입니다.",
    en: "Privilege to view query audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_AUDIT_TRAIL_VIEW_DESC: {
    ko: "CRF의 SDV 상태에 대하여 Audit Trail을 조회할 수 있는 권한입니다.",
    en: "Privilege to view SDV audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_AUDIT_TRAIL_VIEW_DESC: {
    ko: "CRF의 Freezing 상태에 대하여 Audit Trail을 조회할 수 있는 권한입니다.",
    en: "Privilege to view freezing audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_AUDIT_TRAIL_VIEW_DESC: {
    ko: "CRF의 E-Sign 상태에 대하여 Audit Trail을 조회할 수 있는 권한입니다.",
    en: "Privilege to view E-sign audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_AUDIT_TRAIL_VIEW_DESC: {
    ko: "CRF의 Locking 상태에 대하여 Audit Trail을 조회할 수 있는 권한입니다.",
    en: "Privilege to view locking audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_DESC: {
    ko: "스터디의 모든 기관에 대하여 AE/SAE Report 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view AE/SAE report list for all study sites.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관에 대하여 AE/SAE Report 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view AE/SAE report list for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_WRITE_DESC: {
    ko: "AE/SAE Report를 작성 및 저장할 수 있는 권한입니다. 해당 권한은 Follow-Up Report 생성 권한을 포함합니다.",
    en: "Privilege to write and save AE/SAE reports. This privilege includes the ability to generate Follow-Up reports.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_SUBMIT_DESC: {
    ko: "작성된 AE/SAE Report를 제출할 수 있는 권한입니다.",
    en: "Privilege to submit AE/SAE report.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_REPORT_DOWNLOAD_DESC: {
    ko: "Report를 다운로드 받을 수 있는 권한입니다.",
    en: "Privilege to export reports.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_VIEW_DESC: {
    ko: "Safety로 연동된 이상반응 데이터의 내역을 조회할 수 있는 권한입니다.",
    en: "Privilege to view adverse event data integrated to Safety.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SAFETY_INTEGRATION_USER_MANAGEMENT_DESC: {
    ko: "Safety Integration View 권한을 가진 유저를 관리할 수 있는 권한입니다.",
    en: "Privilege to manage PV User and organization integrated to Safety.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_VIEW_DESC: {
    ko: "Coding 대상 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view coding list.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_CODING_WRITE_DESC: {
    ko: "Coding 대상을 처리할 수 있는 권한입니다.",
    en: "Privilege to perform medical coding.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_DESC: {
    ko: "eCOA Access Code를 조회할 수 있는 권한입니다.",
    en: "Privilege to view eCOA access code.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_ACCESS_CODE_VIEW_BELONGS_DESC: {
    ko: "본인 소속 대상자에 한하여 eCOA Access Code를 조회할 수 있는 권한입니다.",
    en: "Privilege to view eCOA access code for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_DESC: {
    ko: "eCOA Page를 조회할 수 있는 권한입니다.",
    en: "Privilege to view eCOA page.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_VIEW_BELONGS_DESC: {
    ko: "본인 소속 대상자에 한하여 eCOA Page를 조회할 수 있는 권한입니다.",
    en: "Privilege to view eCOA page for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_WRITE_DESC: {
    ko: "eCOA Page를 입력할 수 있는 권한입니다.",
    en: "Privilege to input eCOA page.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ECOA_CRF_INIT_DESC: {
    ko: "대상자별 eCOA Page에 대하여 입력된 데이터를 초기화할 수 있는 권한입니다.",
    en: "Privilege to initialize subject eCOA page.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_DESC: {
    ko: "RTSM CRF를 조회할 수 있는 권한입니다.",
    en: "Privilege to view RTSM CRF.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_VIEW_BELONGS_DESC: {
    ko: "본인 소속 대상자에 한하여 RTSM CRF를 조회할 수 있는 권한입니다.",
    en: "Privilege to view  RTSM CRF at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_WRITE_DESC: {
    ko: "RTSM CRF를 입력할 수 있는 권한입니다.",
    en: "Privilege to write RTSM CRF.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_REQUEST_DESC: {
    ko: "Randomization 대상자에 대하여 눈가림 해제를 요청할 수 있는 권한입니다.",
    en: "Privilege to request unblind for randomized subjects.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_APPROVE_DESC: {
    ko: "요청된 눈가림 해제에 대하여 눈가림 해제를 승인할 수 있는 권한입니다.",
    en: "Privilege to approve unblind request.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_DESC: {
    ko: "Randomization 대상자에 대하여 눈가림 해제를 할 수 있는 권한입니다.",
    en: "Privilege to unblind for randomized subjects.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_UNDER_CONDITIONS_DESC: {
    ko: "명시된 조건을 만족했을 때 Randomization 대상자에 대하여 눈가림 해제를 할 수 있는 권한입니다.",
    en: "Privilege to unblind if conditions are met.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_RELEASE_VIEW_DESC: {
    ko: "이중 맹검이 적용된 과제에서 대상자의 눈가림이 해제되었을 때 무작위 배정 그룹을 조회할 수 있는 권한입니다.",
    en: "Privilege to view unblind for randomized groups.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_BLIND_FORCE_VIEW_DESC: {
    ko: "이중 맹검이 적용된 과제에서 무작위 배정 그룹을 조회할 수 있는 권한입니다.",
    en: "Privilege to force unblind for randomized groups.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_DESC: {
    ko: "CRF 일괄 SDV 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view SDV list by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 CRF 일괄 SDV 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to to view user site SDV list.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_ON_DESC: {
    ko: "CRF 일괄 SDV를 처리할 수 있는 권한입니다.",
    en: "Privilege to process SDV by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_SDV_OFF_DESC: {
    ko: "CRF 일괄 SDV를 해제할 수 있는 권한입니다.",
    en: "Privilege to clear SDV by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_DESC: {
    ko: "CRF 일괄 Freezing 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view freezing list by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 CRF 일괄 Freezing 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view user site freezing list.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_ON_DESC: {
    ko: "CRF 일괄 Freezing을 처리할 수 있는 권한입니다.",
    en: "Privilege to process freezing by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_FREEZING_OFF_DESC: {
    ko: "CRF 일괄 Freezing을 해제할 수 있는 권한입니다.",
    en: "Privilege to clear freezing by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_DESC: {
    ko: "CRF 일괄 전자서명 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view e-Sign by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 CRF 일괄 전자서명 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view user site e-Sign list.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_ON_DESC: {
    ko: "CRF 일괄 전자서명을 처리할 수 있는 권한입니다.",
    en: "Privilege to process e-Sign by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_ESIGN_OFF_DESC: {
    ko: "CRF 일괄 전자서명을 해제할 수 있는 권한입니다.",
    en: "Privilege to clear e-Sign by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_DESC: {
    ko: "CRF 일괄 Lock 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view CRF Lock by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관의 대상자에 한하여 CRF 일괄 Lock 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view CRF lock list at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_ON_DESC: {
    ko: "CRF 일괄 Lock을 처리할 수 있는 권한입니다.",
    en: "Privilege to process CRF lock by the batch.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_EDC_LOCKING_OFF_DESC: {
    ko: "CRF 일괄 Lock을 해제할 수 있는 권한입니다.",
    en: "Privilege to clear CRF lock by the batch.",
  },
  // TODO: 아래 2개 번역 요청 (2023.08.23)
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_ON_BELONGS_DESC: {
    ko: "본인 소속 기관의 두번째 전자서명을 처리할 수 있는 권한입니다.",
    en: "Privilege to process second e-Sign for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SECOND_ESIGN_OFF_BELONGS_DESC: {
    ko: "본인 소속 기관의 두번째 전자서명을 해제할 수 있는 권한입니다.",
    en: "Privilege to clear seconds e-Sign for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_VIEW_DESC: {
    ko: "IP Info 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP information.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_INFO_WRITE_DESC: {
    ko: "IP Info 를 입력할 수 있는 권한입니다.",
    en: "Privilege to input IP info.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_DESC: {
    ko: "IP 배송 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_VIEW_BELONGS_DESC: {
    ko: "본인 소속 IP 배송 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP delivery at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DESC: {
    ko: "IP 배송을 요청할 수 있는 권한입니다.",
    en: "Privilege to request IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_BELONGS_DESC: {
    ko: "본인 소속 IP 배송을 요청할 수 있는 권한입니다.",
    en: "Privilege to request IP delivery at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_DESC: {
    ko: "IP 배송을 승인할 수 있는 권한입니다.",
    en: "Privilege to approve IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_APPROVE_BELONGS_DESC: {
    ko: "본인 소속 IP 배송을 승인할 수 있는 권한입니다.",
    en: "Privilege to approve IP delivery at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_DESC: {
    ko: "IP 배송을 출발할 수 있는 권한입니다.",
    en: "Privilege to start IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_START_BELONGS_DESC: {
    ko: "본인 소속 IP 배송을 출발할 수 있는 권한입니다.",
    en: "Privilege to start IP delivery at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DESC: {
    ko: "IP 배송을 완료할 수 있는 권한입니다.",
    en: "Privilege to complete IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_BELONGS_DESC: {
    ko: "본인 소속 IP 배송을 완료할 수 있는 권한입니다.",
    en: "Privilege to complete IP delivery at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_DESC: {
    ko: "출하요청서을 발급할 수 있는 권한입니다.",
    en: "Privilege to request delivery document.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_REQUEST_DOC_BELONGS_DESC: {
    ko: "본인이 소속된 기관의 출하요청서을 발급할 수 있는 권한입니다.",
    en: "Privilege to request delivery document at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_DESC: {
    ko: "인수확인증을 발급할 수 있는 권한입니다.",
    en: "Privilege to issue delivery confirmation document.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_DELIVERY_CONFIRM_DOC_BELONGS_DESC: {
    ko: "본인이 소속된 기관의 인수확인증을 발급할 수 있는 권한입니다.",
    en: "Privilege to issue delivery confirmation at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_DESC: {
    ko: "IP 자동 배송 조건 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view automatic IP delivery conditions.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_VIEW_BELONGS_DESC: {
    ko: "본인 소속 IP 자동 배송 조건 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view automatic delivery conditions at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_DESC: {
    ko: "IP 자동 배송 조건 목록을 입력할 수 있는 권한입니다.",
    en: "Privilege to write automatic IP delivery conditions.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_AUTO_CONDITION_WRITE_BELONGS_DESC: {
    ko: "본인 소속 IP 자동 배송 조건 목록을 입력할 수 있는 권한입니다.",
    en: "Privilege to write automatic IP delivery conditions at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_DESC: {
    ko: "IPM CRF를 조회할 수 있는 권한입니다.",
    en: "Privilege to view IPM CRF.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_VIEW_BELONGS_DESC: {
    ko: "본인 소속 대상자에 한하여 IPM CRF를 조회할 수 있는 권한입니다.",
    en: "Privilege to view IPM CRF at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CRF_WRITE_DESC: {
    ko: "IPM CRF를 입력할 수 있는 권한입니다.",
    en: "Privilege to write IPM CRF.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_DESC: {
    ko: "보유중인 IP 재고 정보를 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP stock.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STOCK_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관이 보유중인 IP 재고 정보를 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP stock at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_DESC: {
    ko: "보유중인 Pharmacy IP 재고에 대해서 반납하거나 Error 처리와 같은 수정을 할 수 있는 권한입니다.",
    en: "Privilege to edit (return/error) pharmacy IP information.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_PHARMACY_STOCK_WRITE_BELONGS_DESC: {
    ko: "본인 소속 기관이 보유중인 Pharmacy IP 재고에 대해서 반납하거나 Error 처리와 같은 수정을 할 수 있는 권한입니다.",
    en: "Privilege to edit (return/error) Pharmacy IP information at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_DESC: {
    ko: "IP 수불기록 정보를 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP accountability.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 수불기록 정보를 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP accountability at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_DESC: {
    ko: "수불기록을 발급할 수 있는 권한입니다.",
    en: "Privilege to issue accountability document.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_ACCOUNTABILITY_DOC_BELONGS_DESC: {
    ko: "본인이 소속된 기관의 수불기록을 발급할 수 있는 권한입니다.",
    en: "Privilege to issue accountability document at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_DESC: {
    ko: "Error 처리된 IP의 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP error.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_VIEW_BELONGS_DESC: {
    ko: "본인 소속기관에서 Error 처리된 IP의 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP error at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_DESC: {
    ko: "Error 처리된 IP를 수정할 수 있는 권한입니다.",
    en: "Privilege to edit IP error.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_ERROR_IP_EDIT_BELONGS_DESC: {
    ko: "본인 소속기관에서 Error 처리된 IP를 수정할 수 있는 권한입니다.",
    en: "Privilege to edit IP error at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_DESC: {
    ko: "반납 요청된 IP의 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view list of return requested IP.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_VIEW_BELONGS_DESC: {
    ko: "본인 소속 반납 요청된 IP의 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view IP return requests within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_DESC: {
    ko: "보유중이지만 사용되거나 사용불가한 IP의 반납을 요청할 수 있는 권한입니다.",
    en: "Privilege to return held IP that has been used or expired.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_REQUEST_BELONGS_DESC: {
    ko: "본인 소속기관에서 보유중이지만 사용되거나 사용불가한 IP의 반납을 요청할 수 있는 권한입니다.",
    en: "Privilege to return held IP within user's site that has been used or expired.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_DESC: {
    ko: "IP 반납 요청을 승인할 수 있는 권한입니다.",
    en: "Privilege to approve request for IP return.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_CONFIRM_BELONGS_DESC: {
    ko: "본인 소속기관에 요청된 IP 반납 요청을 승인할 수 있는 권한입니다.",
    en: "Privilege to approve request for IP return within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_DESC: {
    ko: "반납확인증을 발급할 수 있는 권한입니다.",
    en: "Privilege to issue a certificate of IP return.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_RETURN_DOC_BELONGS_DESC: {
    ko: "본인이 소속된 기관의 반납확인증을 발급할 수 있는 권한입니다.",
    en: "Privilege to issue a certificate of IP return within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_DESC: {
    ko: "대상자에게 배정된 IP 불출 / 반납 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view the status of IP issued to a certain user.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_STATUS_VIEW_BELONGS_DESC: {
    ko: "본인 소속기관에서 대상자에게 배정된 IP 불출 / 반납 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view the status of IP issued to a certain user within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_DESC: {
    ko: "입력된 CRF Data를 추출할 수 있는 권한입니다.",
    en: "Privilege to export logged CRF data.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_EDC_BELONGS_DESC: {
    ko: "본인 소속 기관의 대상자에 입력된 CRF Data를 추출할 수 있는 권한입니다.",
    en: "Privilege to export logged CRF data within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_DESC: {
    ko: "입력된 Lab Data를 추출할 수 있는 권한입니다.",
    en: "Privilege to export logged lab data.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_LAB_BELONGS_DESC: {
    ko: "본인 소속 기관의 대상자에 입력된 Lab Data를 추출할 수 있는 권한입니다.",
    en: "Privilege to export logged lab data within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_DESC: {
    ko: "입력된 eCOA Data를 추출할 수 있는 권한입니다.",
    en: "Privilege to export logged eCOA data.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DATASET_ECOA_BELONGS_DESC: {
    ko: "본인 소속 기관의 대상자에 입력된 eCOA Data를 추출할 수 있는 권한입니다.",
    en: "Privilege to export logged eCOA data within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_DB_SPEC_DESC: {
    ko: "DB Specification을 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export DB specification.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_BLANK_CRF_DESC: {
    ko: "Blank CRFs를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export DB specification within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_DESC: {
    ko: "Data CRFs를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export data CRFs.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_SUBJECT_CRF_BELONGS_DESC: {
    ko: "본인 소속 기관의 대상자에 한하여 Data CRFs를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export data CRFs within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_DESC: {
    ko: "Audit Trail(Data)를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export audit trail (data).",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_DATA_BELONGS_DESC: {
    ko: "본인 소속 기관에 한하여 Audit Trail(Data)를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export audit trail (data) within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_DESC: {
    ko: "Audit Trail(Query)를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export audit trail (query).",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_AUDIT_TRAIL_QUERY_BELONGS_DESC: {
    ko: "본인 소속 기관에 한하여 Audit Trail(Query)를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export audit trail (query) within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_DESC: {
    ko: "Comment를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export comment.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_COMMENT_BELONGS_DESC: {
    ko: "본인 소속 기관에 한하여 Comment를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export comment within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_INCLUDE_NON_CRF_DESC: {
    ko: "Non-CRF를 포함하여 DataWarehouse 데이터를 다운로드 할 수 있는 권한입니다.",
    en: "Previlege to download the DataWarehouse data including Non-CRF.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_DESC: {
    ko: "Subject 상태 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view subject status statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_LIST_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관 Subject 상태 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view subject status statistics within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_SUBJECT_STATUS_STATISTICS_DOWNLOAD_DESC: {
    ko: "Subject 상태 통계를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export subject status statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_DESC: {
    ko: "CRF Page의 입력 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view input status on a per CRF basis.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관의 CRF Page 입력 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view input status on a per CRF basis within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_CRF_DOWNLOAD_DESC: {
    ko: "CRF Page 별 입력 상태를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export input status on a per CRF basis.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_DESC: {
    ko: "Visit 별 입력 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view input status on a per visit basis.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관의 Visit 별 입력 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view input status on a per visit basis within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_VISIT_DOWNLOAD_DESC: {
    ko: "Visit 별 입력 상태를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export input status on a per visit basis.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_DESC: {
    ko: "기관별 입력 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view input status on a per site basis.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관의 입력 상태를 조회할 수 있는 권한입니다.",
    en: "Privilege to view input status within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CRFS_STATUS_BY_SITE_DOWNLOAD_DESC: {
    ko: "기관별 입력 상태를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export input status on a per site basis.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_DESC: {
    ko: "Custom Data Report 1 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view custom data report 1 statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_LIST_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관 Custom Data Report 1 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view custom data report 1 statistics within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_1_STATISTICS_DOWNLOAD_DESC: {
    ko: "Custom Data Report 1 통계를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export custom data report 1 statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_DESC: {
    ko: "Custom Data Report 2 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view custom data report 2 statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_LIST_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관 Custom Data Report 2 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view custom data report 2 statistics within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_2_STATISTICS_DOWNLOAD_DESC: {
    ko: "Custom Data Report 2 통계를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export custom data report 2 statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_DESC: {
    ko: "Custom Data Report 3 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view custom data report 3 statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_LIST_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관 Custom Data Report 3 통계를 조회할 수 있는 권한입니다.",
    en: "Privilege to view custom data report 3 statistics within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_REPORT_CUSTOM_3_STATISTICS_DOWNLOAD_DESC: {
    ko: "Custom Data Report 3 통계를 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to export custom data report 3 statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_VIEW_DESC: {
    ko: "Data Integration(EDC) 내역을 조회 할 수 있는 권한입니다.",
    en: "Privilege to view data integration (EDC) history.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_DESC: {
    ko: "Data Integration(EDC)을 수행 할 수 있는 권한입니다.",
    en: "Privilege to perform data integration (EDC).",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_EDC_UPLOAD_BELONGS_DESC: {
    ko: "본인 소속 기관 대상자에 한하여 Data Integration(EDC)을 수행 할 수 있는 권한입니다.",
    en: "Privilege to perform data integration (EDC) within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_VIEW_DESC: {
    ko: "Data Integration(LAB) 내역을 조회 할 수 있는 권한입니다.",
    en: "Privilege to view data integration (LAB) history.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_DESC: {
    ko: "Data Integration(LAB)을 수행 할 수 있는 권한입니다.",
    en: "Privilege to perform data integration (LAB).",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_LAB_UPLOAD_BELONGS_DESC: {
    ko: "본인 소속 기관 대상자에 한하여 Data Integration(LAB)을 수행 할 수 있는 권한입니다.",
    en: "Privilege to perform data integration (LAB) within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_VIEW_DESC: {
    ko: "Data Integration(Attachment) 내역을 조회 할 수 있는 권한입니다.",
    en: "Privilege to view data integration (Attachment) history.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_INTEGRATION_ATTACHMENT_MANAGEMENT_DESC: {
    ko: "Data Integration(Attachment)을 수행 할 수 있는 권한입니다.",
    en: "Privilege to request data integration (Attachment).",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_DESC: {
    ko: "Data Export Request를 수행 할 수 있는 권한입니다.",
    en: "Privilege to request data export.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DATA_EXPORT_REQUEST_BELONGS_DESC: {
    ko: "본인 소속 기관에 한하여 Data Export Request를 수행 할 수 있는 권한입니다.",
    en: "Privilege to request data export within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_DESC: {
    ko: "Audit Trail에 대한 Digest를 확인하고 데이터 위변조 여부를 모니터링 할 수 있는 권한입니다.",
    en: "Privilege to check Digest, a function to confirm or detect the history of forgery within the blockchain audit trail.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DIGEST_VIEW_BELONGS_DESC: {
    ko: "본인 소속 기관에 한하여 Audit Trail에 대한 Digest를 확인하고 데이터 위변조 여부를 모니터링 할 수 있는 권한입니다.",
    en: "Privilege to check Digest, a function to confirm or detect the history of forgery within the blockchain audit trail, within the user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RNP_MANAGEMENT_DESC: {
    ko: "스터디 R&P를 관리할 수 있는 권한입니다.",
    en: "Privilges to manage study Roles and Privileges.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_VIEW_DESC: {
    ko: "스터디 사용자를 조회 할 수 있는 권한입니다.",
    en: "Privilege to view study users.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_USER_MANAGEMENT_DESC: {
    ko: "스터디 사용자를 관리할 수 있는 권한입니다.",
    en: "Privilege to manage study users.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_VIEW_DESC: {
    ko: "스터디 Site를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_SITE_MANAGEMENT_DESC: {
    ko: "스터디 Site를 관리할 수 있는 권한입니다.",
    en: "Privilege to manage study site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_API_MANAGEMENT_DESC: {
    ko: "스터디 API를 관리할 수 있는 권한입니다.",
    en: "Privilege to manage study API.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_LOCK_DESC: {
    ko: "DB Lock을 할 수 있는 권한입니다.",
    en: "Privilege to perform DB lock.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_DB_UNLOCK_DESC: {
    ko: "DB Unlock을 할 수 있는 권한입니다.",
    en: "Privilege to perfom DB unlock.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TERMINATE_DESC: {
    ko: "스터디를 종료할 수 있는 권한입니다.",
    en: "Privilege to terminate study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_DESC: {
    ko: "SAE Report 생성 및 제출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notifications of SAE report creation and submission.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_BELONGS_DESC: {
    ko: "본인 소속 기관의 SAE Report 생성 및 제출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of SAE report creation and submission within user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_DESC: {
    ko: "AE Report 생성 및 제출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of AE report creation and submission.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_BELONGS_DESC: {
    ko: "본인 소속 기관의 AE Report 생성 및 제출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of AE report creation and submission within user site",
  },
  // TODO: SAE, AE Report 생성, 저장, 제출, 변경, 취소 번역 요청 (2023.08.23)
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CREATION_BELONGS_DESC: {
    ko: "본인 소속 기관의 SAE Report 생성 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of SAE report creation within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CREATION_BELONGS_DESC: {
    ko: "본인 소속 기관의 AE Report 생성 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of AE report creation within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SAVE_BELONGS_DESC: {
    ko: "본인 소속 기관 SAE Report 저장 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of SAE report save within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_BELONGS_DESC: {
    ko: "본인 소속 기관 SAE Report 제출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of AE report save within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: {
    ko: "본인 소속 기관의 SAE Report 원본 데이터의 변경 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of SAE report data change within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: {
    ko: "본인 소속 기관의 AE Report 원본 데이터의 변경 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of AE report data change within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: {
    ko: "본인 소속 기관의 SAE Report 제출 알림을 첨부파일을 포함하여 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of SAE report submission with attachment within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: {
    ko: "본인 소속 기관의 AE Report 제출 알림을 첨부파일을 포함하여 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of AE report submission with attachment within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SAE_REPORT_CANCEL_BELONGS_DESC: {
    ko: "본인 소속 기관의 SAE Report 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of SAE report cancel within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AE_REPORT_CANCEL_BELONGS_DESC: {
    ko: "본인 소속 기관의 AE Report 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of AE report cancel within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CREATION_BELONGS_DESC: {
    ko: "본인 소속 기관의 PREGNANCY Report 생성 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of pregnancy report creation within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SAVE_BELONGS_DESC: {
    ko: "본인 소속 기관 PREGNANCY Report 저장 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of pregnancy report save within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_BELONGS_DESC: {
    ko: "본인 소속 기관 PREGNANCY Report 제출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of pregnancy report submission within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: {
    ko: "본인 소속 기관의 PREGNANCY Report 제출 알림을 첨부파일을 포함하여 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of pregnancy report submission (with attachment) within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_CANCEL_BELONGS_DESC: {
    ko: "본인 소속 기관의 PREGNANCY Report 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of pregnancy report cancellation within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_PREGNANCY_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: {
    ko: "본인 소속 기관의 PREGNANCY Report 원본 데이터의 변경 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of pregnancy report changes to original data within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CREATION_BELONGS_DESC: {
    ko: "본인 소속 기관의 AESI Report 생성 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of AESI report creation at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SAVE_BELONGS_DESC: {
    ko: "본인 소속 기관 AESI Report 저장 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of AESI report save at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_BELONGS_DESC: {
    ko: "본인 소속 기관 AESI Report 제출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of AESI report submission at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_SUBMISSION_ATTACHMENT_BELONGS_DESC: {
    ko: "본인 소속 기관의 AESI Report 제출 알림을 첨부파일을 포함하여 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of AESI report submission with attachment at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_CANCEL_BELONGS_DESC: {
    ko: "본인 소속 기관의 AESI Report 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of AESI report cancellation at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_AESI_REPORT_MAPPING_DATA_CHANGED_BELONGS_DESC: {
    ko: "본인 소속 기관의 AESI Report 원본 데이터의 변경 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of changes to AESI report mapping data at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_DESC: {
    ko: "대상자 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of subject registration.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_REGISTRATION_BELONGS_DESC: {
    ko: "본인 소속 기관의 대상자 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of subject registration within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_DESC: {
    ko: "최초 대상자 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of the first subject registration.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BY_SITE_DESC: {
    ko: "기관별 최초 대상자 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of the first subject by site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_FIRST_REG_BELONGS_DESC: {
    ko: "본인 소속에 한하여 최초 대상자 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of the first subject at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_DESC: {
    ko: "대상자 무작위 배정 번호 부여 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of assigning randomization number to subject.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_RANDOM_NO_ASSIGN_BELONGS_DESC: {
    ko: "본인 소속 기관의 대상자 무작위 배정 번호 부여 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of assigning randomization number to subject at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_DESC: {
    ko: "대상자 등록 번호 부여 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of assigning subject number to subject.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SUBJECT_ALLOCATION_NO_ASSIGN_BELONGS_DESC: {
    ko: "본인 소속 기관의 대상자 등록 번호 부여 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of assigning subject number to subject at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_DESC: {
    ko: "SDV 자동 해제 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of SDV auto-cancellation.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_SDV_AUTO_RELEASE_BELONGS_DESC: {
    ko: "본인 소속 기관 SDV 자동 해제 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of SDV auto-cancellation at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_DESC: {
    ko: "전자서명 자동 해제 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of E-Sign auto-cancellation.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_ESIGN_AUTO_RELEASE_BELONGS_DESC: {
    ko: "본인 소속 기관 전자서명 자동 해제 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of auto-cancellation at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_DESC: {
    ko: "이중맹검이 적용된 과제에서 눈가림 해제시 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of unblinding in a double-blind study.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_RTSM_BLIND_RELEASE_BELONGS_DESC: {
    ko: "이중맹검이 적용된 과제에서 본인 소속 기관 눈가림 해제시 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of unblinding in a double-blind study at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_DESC: {
    ko: "IP 배송 요청 등록에 대한 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of IP delivery request.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_REQ_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 배송 요청 등록에 대한 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of IP delivery request at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_DESC: {
    ko: "IP 자동 배송 요청 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of IP delivery auto-request.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_AUTO_REQ_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 자동 배송 요청 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of IP delivery auto-request at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_DESC: {
    ko: "IP 배송 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive cancellation notice of IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CANCEL_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 배송 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive cancellation notice of IP delivery at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_DESC: {
    ko: "IP 배송 요청 승인 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive approval notice of IP delivery request.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_APPROVE_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 배송 요청 승인 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive approval notice of IP delivery request at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_DESC: {
    ko: "IP 배송 출발 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive dispatch notice of IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_START_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 배송 출발 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive dispatch notice of IP delivery to user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_DESC: {
    ko: "IP 배송 완료 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive completion notice of IP delivery.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DELIVERY_CONFIRM_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 배송 완료 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive completion notice of IP delivery at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_DATA_CRF_CREATE_BELONGS_DESC: {
    ko: "본인 소속 기관의 Data Combination File 데이터 추가/삭제/변경 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to add data/change/receive notice of change to data combination file for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_CUSTOM_TEMPLATE_BELONGS_DESC: {
    ko: "본인 소속 기관의 Custom Template을 메일로 받을 수 있는 권한입니다.",
    en: "Privilege to receive custom template mail at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_01_DESC: {
    ko: "본인 소속기관의 Trigger로 설정한 특정 기능을 이용할 수 있는 권한 01 입니다.",
    en: "Privilege (01) to use the specific function set as the trigger within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_02_DESC: {
    ko: "본인 소속기관의 Trigger로 설정한 특정 기능을 이용할 수 있는 권한 02 입니다.",
    en: "Privilege (02) to use the specific function set as the trigger within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_03_DESC: {
    ko: "본인 소속기관의 Trigger로 설정한 특정 기능을 이용할 수 있는 권한 03 입니다.",
    en: "Privilege (03) to use the specific function set as the trigger within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_04_DESC: {
    ko: "본인 소속기관의 Trigger로 설정한 특정 기능을 이용할 수 있는 권한 04 입니다.",
    en: "Privilege (04) to use the specific function set as the trigger within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRIGGER_RESERVED_BELONGS_05_DESC: {
    ko: "본인 소속기관의 Trigger로 설정한 특정 기능을 이용할 수 있는 권한 05 입니다.",
    en: "Privilege (05) to use the specific function set as the trigger within user's site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_DESC: {
    ko: "IP Code 배정 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of IP code assignment.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_ASSIGN_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP Code 배정 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of IP code assignment at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CODE_RETRIEVE_BELONGS_DESC: {
    ko: "본인 소속기관 IP Code 회수 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of IP code retrieval at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_ALLOCATE_BELONGS_DESC: {
    ko: "본인 소속기관에서 IP 번호를 배정할 수 있는 권한입니다.",
    en: "Privilege to allocate IP code at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_IP_CODE_RETRIEVE_BELONGS_DESC: {
    ko: "본인 소속기관에서 배정된 IP 번호를 회수할 수 있는 권한입니다.",
    en: "Privilege to retrieve IP code at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_DESC: {
    ko: "IP 불출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of IP dispensation.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_DISPENSE_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 불출 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of IP dispensation at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_DESC: {
    ko: "IP 반납 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notice of IP return.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 반납 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive IP return at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_DESC: {
    ko: "IP 반납 요청 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of IP return request.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_REQUEST_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 반납 요청 등록 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive registration notice of IP return request at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_DESC: {
    ko: "IP 반납 요청 승인 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive approval notice of IP return request.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CONFIRM_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 반납 요청 승인 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive approval notice of IP return request at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_DESC: {
    ko: "IP 반납 요청 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive cancellation notice of IP return request.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_RETURN_CANCEL_BELONGS_DESC: {
    ko: "본인 소속 기관의 IP 반납 요청 취소 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive cancellation notice of IP return request at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_DESC: {
    ko: "IP CRA 전자서명 해제 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive cancellation notice of IP CRA E-Sign.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_NOTI_IP_CRA_ESIGN_OFF_BELONGS_DESC: {
    ko: "본인 소속기관 IP CRA 전자서명 해제 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive cancellation notice of IP CRA E-Sign at user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_TRAINING_CERTIFICATE_VIEW_DESC: {
    ko: "Study E-Training 인증서를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study e-training certificate.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_VIEW_DESC: {
    ko: "Study UAT List를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study UAT list.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_WRITE_DESC: {
    ko: "Study UAT를 작성할 수 있는 권한입니다.",
    en: "Privilege to write study UAT.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_ACCEPT_DESC: {
    ko: "Study UAT를 Accepted 상태로 변경할 수 있는 권한입니다.",
    en: "Privilege to change study UAT status to accepted.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_APPLY_DESC: {
    ko: "Study UAT를 Applied 상태로 변경할 수 있는 권한입니다.",
    en: "Privilege to change study UAT status to applied.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_COMPLETE_DESC: {
    ko: "Study UAT를 Completed 상태로 변경할 수 있는 권한입니다.",
    en: "Privilege to change study UAT status to completed.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_STATUS_CANCEL_DESC: {
    ko: "Study UAT를 Cancelled 상태로 변경할 수 있는 권한입니다.",
    en: "Privilege to change study UAT status to cancelled.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_MIGRATE_DESC: {
    ko: "Study UAT 항목을 다음 UAT로 이관할 수 있는 권한입니다.",
    en: "Privilege to migrate study UAT items to next UAT sequence.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_VIEW_DESC: {
    ko: "Study UAT Comments 조회를 할 수 있는 권한입니다.",
    en: "Privilege to view study UAT comments.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_COMMENT_WRITE_DESC: {
    ko: "Study UAT Comment 작성을 할 수 있는 권한입니다.",
    en: "Privilege to write study UAT comment.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_REPORT_DOWNLOAD_DESC: {
    ko: "Study UAT Report를 다운로드 받을 수 있는 권한입니다.",
    en: "Privilege to export study UAT report.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_OPEN_DESC: {
    ko: "Study UAT를 시작할 수 있는 권한입니다.",
    en: "Privilege to open study UAT.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_UAT_CLOSE_DESC: {
    ko: "Study UAT를 종료할 수 있는 권한입니다.",
    en: "Privilege to close study UAT.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_STATISTICS_VIEW_DESC: {
    ko: "Study 통계정보를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study statistics.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_VIEW_DESC: {
    ko: "Lab Normal Range를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study lab normal range.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_EDIT_DESC: {
    ko: "Lab Normal Range를 편집할 수 있는 권한입니다.",
    en: "Privilege to edit study lab normal range.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_VIEW_DESC: {
    ko: "Lab Normal Range Comment를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study lab normal range comment.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_LAB_NORMAL_RANGE_COMMENT_WRITE_DESC: {
    ko: "Lab Normal Range Comment를 작성할 수 있는 권한입니다.",
    en: "Privilege to write study lab normal range comment.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_VIEW_BELONGS_DESC: {
    ko: "본인이 속한 기관에 대하여 Data Combination File 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view data combination file list for user site.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_CUSTOM_DATA_CRF_DOWNLOAD_DESC: {
    ko: "Data Combination File을 다운로드 받을 수 있는 권한입니다.",
    en: "Privilege to download data combination file.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_VIEW_DESC: {
    ko: "Study Payload를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study payload.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_WRITE_DESC: {
    ko: "Study Payload를 생성 및 수정할 수 있는 권한입니다.",
    en: "Privilege to create and edit study payload.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_PAYLOAD_RELEASE_DESC: {
    ko: "Study Payload를 배포할 수 있는 권한입니다.",
    en: "Privilege to release study payload.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_VIEW_DESC: {
    ko: "Study Randomization 조회할 수 있는 권한입니다.",
    en: "Privilege to view study randomization.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_RANDOMIZATION_WRITE_DESC: {
    ko: "Study Randomization 설정할 수 있는 권한입니다.",
    en: "Privilege to set up study randomization.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_VIEW_DESC: {
    ko: "Study IP Management 를 조회할 수 있는 권한입니다.",
    en: "Privilege to view study IP management.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_RTSM_IPM_WRITE_DESC: {
    ko: "Study IP Management 를 설정할 수 있는 권한입니다.",
    en: "Privilege to set up study IP management.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_VIEW_DESC: {
    ko: "독립적 평가 목록을 조회할 수 있는 권한입니다.",
    en: "Privilege to view Independent Evaluation List.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_WRITE_DESC: {
    ko: "독립적 평가를 작성할 수 있는 권한입니다.",
    en: "Privilege to write Independent Evaluation.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_ADMINISTRATION_DESC: {
    ko: "독립적 평가 목록에서 모든 독립적 평가 항목과 Subject No 및 평가 파일의 원본 명칭을 볼 수 있는 권한입니다.",
    en: "Privilege to view all independent evaluations, the actual Subject No. and original filename of the evaluation file in the independent evaluation list.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_FILE_UPLOAD_NOTIFICATION_DESC: {
    ko: "독립적 평가 파일이 업로드 되었을 때 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of Independent Evaluation upload.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_LIST_DOWNLOAD_DESC: {
    ko: "독립적 평가 목록을 다운로드 할 수 있는 권한입니다.",
    en: "Privilege to download Independent Evaluation List.",
  },
  BUILDER_STUDY_ROLE_PRIVILEGE_STUDY_INDEPENDENT_EVALUATION_COMPLETE_NOTIFICATION_DESC: {
    ko: "독립적 평가가 완료되어 완료 상태로 변경되었을 때 알림을 수신할 수 있는 권한입니다.",
    en: "Privilege to receive notification of Independent Evaluation completion.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_ROLE_PRIVILEGE_DESC_LANG);

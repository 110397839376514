import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_NOTICE_LANG: {
  CDMS_STUDY_NOTICE_PAGE_TITLE: MvnCodeFE;
} = {
  CDMS_STUDY_NOTICE_PAGE_TITLE: {
    ko: "Notice",
    en: "Notice",
  },
};

MvnCodeInitiate(CDMS_STUDY_NOTICE_LANG);

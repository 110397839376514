import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_TEMPLATE_CODE: {
  BUILDER_STUDY_TEMPLATE_DUPLICATED: MvnCodeBE;
} = {
  BUILDER_STUDY_TEMPLATE_DUPLICATED: {
    code: "BUILDER_STUDY_TEMPLATE_DUPLICATED",
    status: 409,
    ko: "Study Template이 이미 존재합니다. (name: {name}, category: {category})",
    en: "Study Template already exists. (name: {name}, category: {category})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_TEMPLATE_CODE);

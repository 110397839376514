import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PROPERTY_CODE: {
  BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_INDEX_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_MISSING: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_SUBJECT_LABEL_MISSING: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_LABEL_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_LABEL_MISSING: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_TEXT_MISSING: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_HEADER_VALUE_MISSING: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_HEADER_VALUE_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_INDEX_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PROPERTY_INVALID_REQUIRED_PROPERTY_MISSING: MvnCodeBE;
} = {
  BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_DUPLICATED: {
    code: "BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_DUPLICATED",
    status: 400,
    ko: "중복된 Column({column})이 존재합니다.",
    en: "Column({column}) is duplicated.",
  },
  BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_INDEX_DUPLICATED: {
    code: "BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_INDEX_DUPLICATED",
    status: 409,
    ko: "중복된 Subject Column Index가 존재합니다.",
    en: "Subject column index value has duplicated.",
  },
  BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_MISSING: {
    code: "BUILDER_STUDY_PROPERTY_SUBJECT_COLUMN_MISSING",
    status: 400,
    ko: "Subject Column을 입력해 주세요.",
    en: "Please enter a subject column.",
  },
  BUILDER_STUDY_PROPERTY_SUBJECT_LABEL_MISSING: {
    code: "BUILDER_STUDY_PROPERTY_SUBJECT_LABEL_MISSING",
    status: 400,
    ko: "Subject Label({lang})을 입력해 주세요.",
    en: "Please enter a subject label({lang}).",
  },
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_LABEL_DUPLICATED: {
    code: "BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_LABEL_DUPLICATED",
    status: 400,
    ko: "중복된 Label({label})이 존재합니다.",
    en: "Label({label}) is duplicated.",
  },
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_LABEL_MISSING: {
    code: "BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_LABEL_MISSING",
    status: 400,
    ko: "Label을 입력해 주세요.",
    en: "Please enter a label.",
  },
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_TEXT_MISSING: {
    code: "BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_TEXT_MISSING",
    status: 400,
    ko: "Label Description을 입력해 주세요.",
    en: "Please enter a label description.",
  },
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_HEADER_VALUE_MISSING: {
    code: "BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_HEADER_VALUE_MISSING",
    status: 400,
    ko: "Value을 입력해 주세요.",
    en: "Please enter a value.",
  },
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_HEADER_VALUE_DUPLICATED: {
    code: "BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_HEADER_VALUE_DUPLICATED",
    status: 400,
    ko: "중복된 Value({value})가 존재합니다.",
    en: "Value({value}) is duplicated.",
  },
  BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_INDEX_DUPLICATED: {
    code: "BUILDER_STUDY_PROPERTY_DATA_WAREHOUSE_INDEX_DUPLICATED",
    status: 409,
    ko: "중복된 Index가 존재합니다.",
    en: "Index value has duplicated.",
  },
  BUILDER_STUDY_PROPERTY_INVALID_REQUIRED_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PROPERTY_INVALID_REQUIRED_PROPERTY_MISSING",
    status: 400,
    ko: "Study Property의 필수값({property})이 누락되었습니다.",
    en: "Required value({property}) of study property is missing.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PROPERTY_CODE);

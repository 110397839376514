import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_DATA_EXPORT_CODE: {
  CDMS_STUDY_DATA_EXPORT_REQUEST_TOTAL_FILE_SIZE_EXCEEDED: MvnCodeBE;
  CDMS_STUDY_DATA_EXPORT_REQUEST_FILE_NAME_DUPLICATED: MvnCodeBE;
  CDMS_STUDY_DATA_EXPORT_REQUEST_TOTAL_FILE_SIZE_EXCEED_AND_FILE_NAME_DUPLICATED: MvnCodeBE;
} = {
  CDMS_STUDY_DATA_EXPORT_REQUEST_TOTAL_FILE_SIZE_EXCEEDED: {
    status: 409,
    ko: "Request 가능한 최대 용량({maxSize})을 초과했습니다.",
    en: "You have exceeded the maximum file capacity({maxSize}) you can request.",
  },
  CDMS_STUDY_DATA_EXPORT_REQUEST_FILE_NAME_DUPLICATED: {
    status: 409,
    ko: "파일명 중복으로 Request에 실패했습니다. 파일명을 확인해 주세요. 파일명: {duplicatedFileNames}",
    en: "Failed to Request because of a duplicate filename. Please check the filename. Filename: ${duplicatedFileNames}",
  },
  CDMS_STUDY_DATA_EXPORT_REQUEST_TOTAL_FILE_SIZE_EXCEED_AND_FILE_NAME_DUPLICATED: {
    status: 409,
    ko: "최대 용량({maxSize}) 초과 및 파일명 중복으로 Request에 실패했습니다. 파일명: {duplicatedFileNames}",
    en: "Failed to request because it exceeded the maximum capacity ({maxSize}) and had a duplicate file name. Filename: ${duplicatedFileNames}",
  },
};

MvnCodeInitiate(CDMS_STUDY_DATA_EXPORT_CODE);

import { MvnCodeFE } from "./../const";
import { MvnCodeInitiate } from "../../lib/const";

export const CDMS_STUDY_CODING_LANG: {
  CDMS_STUDY_CODING_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CODING_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_CODING_PAGE_TITLE: {
    ko: "Coding",
    en: "Coding",
  },
  CDMS_STUDY_CODING_PAGE_DESC: {
    ko: "병력, 증상, 약품 등에 표준코드를 할당할 수 있습니다.",
    en: "Allocate standard codes to medical history, symptoms, medications.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CODING_LANG);

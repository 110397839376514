import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_SITE_CODE: {
  STUDY_SITE_NOT_FOUND: MvnCodeBE;
  STUDY_SITE_NOT_AVAILABLE: MvnCodeBE;
  STUDY_SITE_INVALID_REGISTERED_SUBJECT_EXIST: MvnCodeBE;
  STUDY_SITE_INVALID_DELETED_ALREADY: MvnCodeBE;
  STUDY_SITE_INVALID_DELETE_NOT_SURPPORTED: MvnCodeBE;
} = {
  STUDY_SITE_NOT_FOUND: {
    code: "STUDY_SITE_NOT_FOUND",
    status: 404,
    ko: "요청하신 Study Site({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study site({id}) could not be found.",
  },
  STUDY_SITE_NOT_AVAILABLE: {
    code: "STUDY_SITE_NOT_AVAILABLE",
    status: 400,
    ko: "요청하신 Study Site({id}) 정보가 올바르지 않습니다.",
    en: "Requested study site({id}) is not valid.",
  },
  STUDY_SITE_INVALID_REGISTERED_SUBJECT_EXIST: {
    code: "STUDY_SITE_INVALID_REGISTERED_SUBJECT_EXIST",
    status: 400,
    ko: "대상자가 등록된 Site입니다.",
    en: "Subject is registered already on site.",
  },
  STUDY_SITE_INVALID_DELETED_ALREADY: {
    code: "STUDY_SITE_INVALID_DELETED_ALREADY",
    status: 409,
    ko: "이미 삭제된 Site입니다.",
    en: "Site is deleted already.",
  },
  STUDY_SITE_INVALID_DELETE_NOT_SURPPORTED: {
    code: "STUDY_SITE_INVALID_DELETE_NOT_SURPPORTED",
    status: 400,
    ko: "Site 삭제 기능은 아직 제공되지 않습니다.",
    en: "The site deletion function is not yet available.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_SITE_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_CLOSE_LANG: {
  CDMS_STUDY_CLOSE_TITLE: MvnCodeFE;
  CDMS_STUDY_CLOSE_DB_LOCK_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CLOSE_DB_LOCK_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_CLOSE_TERMINATE_BREADCRUMB: MvnCodeFE;
  CDMS_STUDY_CLOSE_TERMINATE_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CLOSE_TERMINATE_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_CLOSE_LABEL_DB_LOCKED_DATE: MvnCodeFE;
} = {
  CDMS_STUDY_CLOSE_TITLE: {
    ko: "Close",
    en: "Close",
  },
  CDMS_STUDY_CLOSE_DB_LOCK_PAGE_TITLE: {
    ko: "DB Lock",
    en: "DB Lock",
  },
  CDMS_STUDY_CLOSE_DB_LOCK_PAGE_DESC: {
    ko: "대상자 생성 및 데이터 입력을 포함한 모든 신규 데이터 입력을 할 수 없도록 DB Lock 처리를 할 수 있습니다.",
    en: "Execute DB Lock.",
  },
  CDMS_STUDY_CLOSE_TERMINATE_BREADCRUMB: {
    ko: "Terminate",
    en: "Terminate",
  },
  CDMS_STUDY_CLOSE_TERMINATE_PAGE_TITLE: {
    ko: "Study Terminate",
    en: "Study Terminate",
  },
  CDMS_STUDY_CLOSE_TERMINATE_PAGE_DESC: {
    ko: "임상시험 스터디 진행을 종료할 수 있습니다.",
    en: "Execute clinical trial study terminate.",
  },
  CDMS_STUDY_CLOSE_LABEL_DB_LOCKED_DATE: {
    ko: "DB 잠금 날짜",
    en: "DB Locked Date",
  },
};

MvnCodeInitiate(CDMS_STUDY_CLOSE_LANG);

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CLOSE_LANG: {
  CLOSE_STUDY_DB_LOCK_TITLE: MvnCodeFE;
  CLOSE_STUDY_DB_LOCK_LOG_TITLE: MvnCodeFE;
  CLOSE_STUDY_TERMINATE_TITLE: MvnCodeFE;
  CLOSE_STUDY_LOG_TITLE: MvnCodeFE;
  CLOSE_STUDY_TABLE_COLUMN_ESIGN: MvnCodeFE;
  CLOSE_STUDY_TABLE_COLUMN_ESIGN_ID: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_ENROLLED_SUBJECTS: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_ESIGNED_SUBJECTS: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_ECRF_PAGES: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_ACTIVATED_ECRF_PAGES: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_SAVE_ECRF_PAGES: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_PRELIMINARY_QUREIS: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_OPEN_QUREIS: MvnCodeFE;
  CLOSE_STUDY_LABEL_NUMBER_OF_ANSWER_QUREIS: MvnCodeFE;
  CLOSE_STUDY_LABEL_SUBJECT: MvnCodeFE;
  CLOSE_STUDY_LABEL_SITE: MvnCodeFE;
  CLOSE_STUDY_LABEL_NAME: MvnCodeFE;
  CLOSE_STUDY_LABEL_STATUS: MvnCodeFE;
  CLOSE_STUDY_LABEL_TITLE: MvnCodeFE;
  CLOSE_STUDY: MvnCodeFE;
  CLOSE_STUDY_TABLE_COLUMN_SIGNATURE_DATE: MvnCodeFE;
  CLOSE_STUDY_LOCK_DIALOG_TITLE: MvnCodeFE;
  CLOSE_STUDY_UNLOCK_DIALOG_TITLE: MvnCodeFE;
  CLOSE_STUDY_CLOSE_DIALOG_TITLE: MvnCodeFE;
  CLOSE_STUDY_REOPEN_DIALOG_TITLE: MvnCodeFE;
  CLOSE_STUDY_FAIL: MvnCodeFE;
  CLOSE_DB_FAIL: MvnCodeFE;
  CLOSE_STUDY_LABEL_A_NUMBER_OF_COUNT: MvnCodeFE;
  CLOSE_STUDY_LABEL_A_NUMBER_OF_PEOPLE: MvnCodeFE;
} = {
  CLOSE_STUDY_DB_LOCK_TITLE: {
    ko: "DB 잠금상태",
    en: "DB Lock",
  },
  CLOSE_STUDY_TERMINATE_TITLE: {
    ko: "과제 종료 상태",
    en: "End Of Study",
  },
  CLOSE_STUDY_LOG_TITLE: {
    ko: "과제 종료 로그",
    en: "Study End Log",
  },
  CLOSE_STUDY_DB_LOCK_LOG_TITLE: {
    ko: "DB 잠금 로그",
    en: "DB Lock Log",
  },
  CLOSE_STUDY_TABLE_COLUMN_ESIGN: {
    ko: "전자서명",
    en: "E-Sgin",
  },
  CLOSE_STUDY_TABLE_COLUMN_ESIGN_ID: {
    ko: "전자서명 ID",
    en: "E-Sign ID",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_ENROLLED_SUBJECTS: {
    ko: "등록된 총 시험대상자 수",
    en: "Number of Enrolled Subjects",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_ESIGNED_SUBJECTS: {
    ko: "연구자 전자서명이 완료된 시험대상자 수",
    en: "Number of E-Signed Subject",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_ECRF_PAGES: {
    ko: "총 eCRF Page 수",
    en: "Number of eCRF Pages",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_ACTIVATED_ECRF_PAGES: {
    ko: "활성화 된 eCRF Page 수",
    en: "Number of Activated eCRF Pages",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_SAVE_ECRF_PAGES: {
    ko: "저장된 eCRF Page 수",
    en: "Number of Saved eCRF Pages",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_PRELIMINARY_QUREIS: {
    ko: "총 예비 쿼리 개수",
    en: "Number of Preliminary Queries",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_OPEN_QUREIS: {
    ko: "총 오픈 쿼리 개수",
    en: "Number of open Queries",
  },
  CLOSE_STUDY_LABEL_NUMBER_OF_ANSWER_QUREIS: {
    ko: "총 응답 쿼리 개수",
    en: "Number of Closed Queries",
  },
  CLOSE_STUDY_LABEL_SUBJECT: {
    ko: "대상자",
    en: "Subject",
  },
  CLOSE_STUDY_LABEL_SITE: {
    ko: "실시기관",
    en: "Site",
  },
  CLOSE_STUDY_LABEL_NAME: {
    ko: "과제명",
    en: "Study Name",
  },
  CLOSE_STUDY_LABEL_STATUS: {
    ko: "상태",
    en: "Status",
  },
  CLOSE_STUDY_LABEL_TITLE: {
    ko: "과제 제목",
    en: "Study Title",
  },
  CLOSE_STUDY: {
    ko: "과제",
    en: "Study",
  },
  CLOSE_STUDY_TABLE_COLUMN_SIGNATURE_DATE: {
    ko: "서명 일시",
    en: "Signature Date",
  },
  CLOSE_STUDY_LOCK_DIALOG_TITLE: {
    ko: "Study Lock을(를) 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to lock the study.",
  },
  CLOSE_STUDY_UNLOCK_DIALOG_TITLE: {
    ko: "Study Unlock을(를) 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to unlock the study.",
  },
  CLOSE_STUDY_CLOSE_DIALOG_TITLE: {
    ko: "Study Close을(를) 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to close the study.",
  },
  CLOSE_STUDY_REOPEN_DIALOG_TITLE: {
    ko: "Study Reopen을(를) 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to reopen the study.",
  },
  CLOSE_STUDY_FAIL: {
    ko: "Study {title}에 실패했습니다. ({error}) ",
    en: "Study {title} has been failed ({error}) ",
  },
  CLOSE_DB_FAIL: {
    ko: "DB {title}에 실패했습니다. ({error}) ",
    en: "DB {title} has been failed. ({error}) ",
  },
  CLOSE_STUDY_LABEL_A_NUMBER_OF_COUNT: {
    ko: "{number}개",
    en: "{number}",
  },
  CLOSE_STUDY_LABEL_A_NUMBER_OF_PEOPLE: {
    ko: "{number}명",
    en: "{number}",
  },
};

MvnCodeInitiate(STUDY_CLOSE_LANG);

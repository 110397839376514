import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_CODE: {
  BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_MAPPING_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_MAPPING_INVALID: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_MAPPING_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_MAPPING_DUPLICATED",
    status: 409,
    ko: "이미 매핑된 실험실 검사 항목입니다.",
    en: "Lab test items are duplicated mapped.",
  },
  BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_MAPPING_INVALID: {
    code: "BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_MAPPING_INVALID",
    status: 400,
    ko: "Lab Test Table Item({itemIdentifier})의 매핑 정보({mappingValue})가 올바르지 않습니다.",
    en: "Mapping value({mappingValue}) of Lab Test Table Item({itemIdentifier}) is invalid.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_LAB_TEST_ITEM_CODE);

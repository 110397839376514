import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_ALWAYS_SERIOUS_LIST_CODE: {
  CDMS_STUDY_ASL_UPLOAD_FILE_SAVE_FAIL: MvnCodeBE;
  CDMS_STUDY_ASL_UPLOAD_INVALID_MISSING_DATA_ROW: MvnCodeBE;
} = {
  CDMS_STUDY_ASL_UPLOAD_FILE_SAVE_FAIL: {
    status: 400,
    ko: "파일 저장에 실패하였습니다.",
    en: "Failed to save the file.",
  },
  CDMS_STUDY_ASL_UPLOAD_INVALID_MISSING_DATA_ROW: {
    status: 404,
    ko: "{row}행 {col}열이 존재하지 않습니다.",
    en: "Row {row} and column {col} does not exist.",
  },
};

MvnCodeInitiate(CDMS_STUDY_ALWAYS_SERIOUS_LIST_CODE);

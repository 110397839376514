import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_SAFETY_INTEGRATION_CODE: {
  CDMS_STUDY_SAFETY_INTG_USER_INVITE_INVALID_ALREADY_EXIST: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITE_INVALID_ORG_NOT_EXIST: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_USER_MODIFY_ORG_INVALID_ONLY_SELF: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_USER_MODIFY_ORG_INVALID_NOT_ORG_MEMBER: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_USER_MODIFY_ORG_INVALID_NOT_SUBSCRIBED: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITE_NOT_FOUND: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_INVALID_RECENTLY_SEND: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_FAILED_INTG_USER_NOT_EXIST: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_FAILED_DATA_NOT_MODIFIED: MvnCodeBE;
  CDMS_STUDY_SAFETY_INTG_FAILED_DATA_NOT_CODED: MvnCodeBE;
} = {
  CDMS_STUDY_SAFETY_INTG_USER_INVITE_INVALID_ALREADY_EXIST: {
    status: 409,
    ko: "이미 지정된 User입니다.",
    en: "This user has been already designated.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITE_INVALID_ORG_NOT_EXIST: {
    status: 404,
    ko: "해당 계정이 소속된 Safety Organization이 없습니다.",
    en: "This account is not affiliated with any Safety Organization.",
    code: "ORGANIZATION_NOT_EXIST",
  },
  CDMS_STUDY_SAFETY_INTG_USER_MODIFY_ORG_INVALID_ONLY_SELF: {
    status: 403,
    ko: "Organization은 본인만 수정 가능합니다.",
    en: "Only the owner can make changes to the Organization.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_MODIFY_ORG_INVALID_NOT_ORG_MEMBER: {
    status: 400,
    ko: "Organization의 Member가 아닙니다.",
    en: "Not a member of the Organization.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_MODIFY_ORG_INVALID_NOT_SUBSCRIBED: {
    status: 400,
    ko: "Safety를 구독하지 않는 Organization입니다.",
    en: "This Organization is not subscribed to Safety",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITE_NOT_FOUND: {
    status: 404,
    ko: "초대 정보가 없습니다.",
    en: "Invitation not found.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_INVALID_RECENTLY_SEND: {
    status: 400,
    ko: "메일이 이미 전송되었습니다. 1분 후 다시 시도해 주세요.",
    en: "The email has already been sent. Please try again in 1 minute.",
    code: "INVITATION_MAIL_SENT_RECENTLY",
  },
  CDMS_STUDY_SAFETY_INTG_FAILED_INTG_USER_NOT_EXIST: {
    status: 404,
    ko: "대표 PV가 존재하지 않습니다.",
    en: "A rep PV does not exist.",
  },
  CDMS_STUDY_SAFETY_INTG_FAILED_DATA_NOT_MODIFIED: {
    status: 409,
    ko: "변경 사항이 없습니다.",
    en: "Nothing changed.",
  },
  CDMS_STUDY_SAFETY_INTG_FAILED_DATA_NOT_CODED: {
    status: 400,
    ko: "코딩되지 않은 item이 존재합니다.",
    en: "An uncoded item exists.",
  },
};

MvnCodeInitiate(CDMS_STUDY_SAFETY_INTEGRATION_CODE);

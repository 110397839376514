import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";
export const CDMS_STUDY_DATA_INTEGRATION_CODE: {
  VALIDATE_ERROR_MISSING_SHEET: MvnCodeBE;
  VALIDATE_ERROR_NOT_SET_RANDOMIZATION: MvnCodeBE;
  VALIDATE_ERROR_INPUT_VALUE: MvnCodeBE;
  VALIDATE_ERROR_COMMON: MvnCodeBE;
} = {
  VALIDATE_ERROR_MISSING_SHEET: {
    status: 400,
    ko: "Sheet 누락",
    en: "Empty sheets",
  },
  VALIDATE_ERROR_NOT_SET_RANDOMIZATION: {
    status: 400,
    ko: "Randomization 설정 필요",
    en: "Requires radomization set-up",
  },
  VALIDATE_ERROR_INPUT_VALUE: {
    status: 400,
    ko: "입력값 오류",
    en: "Invalid input value",
  },
  VALIDATE_ERROR_COMMON: {
    status: 400,
    ko: "알 수 없는 오류 (문의 필요)",
    en: "Unknown error (Please contact administrator)",
  },
};

MvnCodeInitiate(CDMS_STUDY_DATA_INTEGRATION_CODE);

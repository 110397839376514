import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_BLIND_LANG: {
  CDMS_STUDY_BLIND_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_BLIND_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_BLIND_PAGE_TITLE: {
    ko: "Blind",
    en: "Blind",
  },
  CDMS_STUDY_BLIND_PAGE_DESC: {
    ko: "눈가림과 관련된 요청을 조회하거나 눈가림 해제 요청을 승인할 수 있습니다.",
    en: "Inquire blind requests and approve unblind request.",
  },
};

MvnCodeInitiate(CDMS_STUDY_BLIND_LANG);

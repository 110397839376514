/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_DOCUMENT_CODE: {

  TMF_STUDY_DOCUMENT_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_DOCUMENT_ID_INVALID: MvnCodeBE;

  TMF_STUDY_DOCUMENT_LIST_NOT_FOUND: MvnCodeBE;

  TMF_STUDY_DOCUMENT_SYSTEM_VERSION_NOTFOUND: MvnCodeBE;
  TMF_STUDY_DOCUMENT_SYSTEM_VERSION_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_DOCUMENT_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_DOCUMENT_NAME_DUPLICATED: MvnCodeBE;

  TMF_STUDY_DOCUMENT_BULK_UPLOAD_TOO_MANY: MvnCodeBE;

  TMF_STUDY_DOCUMENT_FILE_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_FIlE_EXT_NOT_SUPPORT: MvnCodeBE;
  TMF_STUDY_DOCUMENT_VERSION_TOO_LONG: MvnCodeBE;
  TMF_STUDY_DOCUMENT_TMF_LEVEL_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_TMF_LEVEL_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_COUNTRY_CODE_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_SITE_CODE_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_COUNTRY_ID_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_SITE_ID_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_CONVENTION_DATE_INVALID: MvnCodeBE;

  TMF_STUDY_DOCUMENT_TYPE_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_ZONE_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_SECTION_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_ARTIFACT_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_SUB_ARTIFACT_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_BLINDED_CHNAGE_NOT_ALLOW: MvnCodeBE;

  TMF_STUDY_DOCUMENT_DELETE_REASON_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_DELETE_REASON_TOO_LONG: MvnCodeBE;

  TMF_STUDY_DOCUMENT_QC_REVIEWER_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_QC_REVIEWER_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_QC_DUE_DATE_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_QC_START_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_QC_DUE_DATE_INVALID: MvnCodeBE;

  TMF_STUDY_DOCUMENT_QC_REASON_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_QC_REVIEWER_COMMENT_TOO_LONG: MvnCodeBE;
} = {
  TMF_STUDY_DOCUMENT_ID_NOT_FOUND: {
    code: "TMF_STUDY_DOCUMENT_ID_NOT_FOUND",
    status: 400,
    ko: "studyDocumentId({id})가 존재하지 않습니다.",
    en: "studyDocumentId({id}) does not exists."
  },
  TMF_STUDY_DOCUMENT_ID_INVALID: {
    code: "TMF_STUDY_DOCUMENT_ID_INVALID",
    status: 400,
    ko: "TMF Document id({id})가 잘못되었습니다.",
    en: "TMF Document id({id}) is invalid."
  },
  TMF_STUDY_DOCUMENT_LIST_NOT_FOUND: {
    code: "TMF_STUDY_DOCUMENT_LIST_NOT_FOUND",
    status: 400,
    ko: "조건에 부합하는 TMF Document가 없습니다.",
    en: "There are no documents matching the conditions."
  },

  TMF_STUDY_DOCUMENT_SYSTEM_VERSION_NOTFOUND: {
    code: "TMF_STUDY_DOCUMENT_SYSTEM_VERSION_NOTFOUND",
    status: 400,
    ko: "TMF Document System Version({systemVersion})가 존재하지 않습니다.",
    en: "TMF Document System Version({systemVersion}) does not exists."
  },
  TMF_STUDY_DOCUMENT_SYSTEM_VERSION_INVALID: {
    code: "TMF_STUDY_DOCUMENT_SYSTEM_VERSION_INVALID",
    status: 400,
    ko: "TMF Document System Version({systemVersion})가 잘못되었습니다.",
    en: "TMF Document System Version({systemVersion}) is invalid."
  },
  TMF_STUDY_DOCUMENT_NAME_MISSING: {
    code: "TMF_STUDY_DOCUMENT_NAME_MISSING",
    status: 400,
    ko: "TMF Document 이름이 입력되지 않았습니다.",
    en: "TMF Document Name is missing."
  },
  TMF_STUDY_DOCUMENT_NAME_TOO_SHORT: {
    code: "TMF_STUDY_DOCUMENT_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Document 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Document name must be at least {length} characters long.",
  },
  TMF_STUDY_DOCUMENT_NAME_TOO_LONG: {
    code: "TMF_STUDY_DOCUMENT_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Document 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Document name can be up to {length} characters long.",
  },
  TMF_STUDY_DOCUMENT_NAME_DUPLICATED: {
    code: "TMF_STUDY_DOCUMENT_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Document 이름({name})이 중복되었습니다.",
    en: "TMF Document name({name}) is duplicated.",
  },

  TMF_STUDY_DOCUMENT_BULK_UPLOAD_TOO_MANY: {
    code: "TMF_STUDY_DOCUMENT_BULK_UPLOAD_TOO_MANY",
    status: 400,
    ko: "TMF Document는 최대 {count}개까지 한 번에 업로드할 수 있습니다.",
    en: "TMF documents can be uploaded up to {count} TMF documents at once.",
  },

  TMF_STUDY_DOCUMENT_FILE_MISSING: {
    code: "TMF_STUDY_DOCUMENT_FILE_MISSING",
    status: 400,
    ko: "TMF Document File이 누락되었습니다.",
    en: "TMF Document file is missing.",
  },
  TMF_STUDY_DOCUMENT_FIlE_EXT_NOT_SUPPORT: {
    code: "TMF_STUDY_DOCUMENT_FIlE_EXT_NOT_SUPPORT",
    status: 400,
    ko: "TMF Document File 확장자가({mime})가 지원되지 않습니다.",
    en: "TMF Document File Extension({mime}) is not supported."
  },
  TMF_STUDY_DOCUMENT_VERSION_TOO_LONG: {
    code: "TMF_STUDY_DOCUMENT_VERSION_TOO_LONG",
    status: 400,
    ko: "TMF Document Version 길이는 최대 {length}자 입니다.",
    en: "TMF Document version can be up to {length} characters long.",
  },
  TMF_STUDY_DOCUMENT_TMF_LEVEL_MISSING: {
    code: "TMF_STUDY_DOCUMENT_TMF_LEVEL_MISSING",
    status: 400,
    ko: "TMF Document TMF Level이 누락되었습니다.",
    en: "TMF Document TMF Level is missing."
  },
  TMF_STUDY_DOCUMENT_TMF_LEVEL_INVALID: {
    code: "TMF_STUDY_DOCUMENT_TMF_LEVEL_INVALID",
    status: 400,
    ko: "TMF Document TMF Level({tmfLevel})가 잘못되었습니다.",
    en: "TMF Document TMF Level({tmfLevel}) is invalid."
  },
  TMF_STUDY_DOCUMENT_COUNTRY_CODE_MISSING: {
    code: "TMF_STUDY_DOCUMENT_COUNTRY_CODE_MISSING",
    status: 400,
    ko: "TMF Document TMF Level(Country Code)이 누락되었습니다.",
    en: "TMF Document TMF Level(Country Code) is missing."
  },
  TMF_STUDY_DOCUMENT_SITE_CODE_MISSING: {
    code: "TMF_STUDY_DOCUMENT_SITE_CODE_MISSING",
    status: 400,
    ko: "TMF Document TMF Level(Site Code)이 누락되었습니다.",
    en: "TMF Document TMF Level(Site Code) is missing."
  },
  TMF_STUDY_DOCUMENT_COUNTRY_ID_INVALID: {
    code: "TMF_STUDY_DOCUMENT_COUNTRY_ID_INVALID",
    status: 400,
    ko: "TMF Document Country id({id})가 잘못되었습니다.",
    en: "TMF Document Country id({id}) is invalid."
  },
  TMF_STUDY_DOCUMENT_SITE_ID_INVALID: {
    code: "TMF_STUDY_DOCUMENT_SITE_ID_INVALID",
    status: 400,
    ko: "TMF Document Site id({id})가 잘못되었습니다.",
    en: "TMF Document Site id({id}) is invalid."
  },
  TMF_STUDY_DOCUMENT_CONVENTION_DATE_INVALID: {
    code: "TMF_STUDY_DOCUMENT_CONVENTION_DATE_INVALID",
    status: 400,
    ko: "TMF Document Convention Date({conventionDate})가 잘못되었습니다.",
    en: "TMF Document Convention Date({conventionDate}) is invalid."
  },

  TMF_STUDY_DOCUMENT_TYPE_MISSING: {
    code: "TMF_STUDY_DOCUMENT_TYPE_MISSING",
    status: 400,
    ko: "TMF Document type 입력되지 않았습니다.",
    en: "TMF Document type is missing."
  },
  TMF_STUDY_DOCUMENT_ZONE_MISSING: {
    code: "TMF_STUDY_DOCUMENT_ZONE_MISSING",
    status: 400,
    ko: "TMF Zone이 입력되지 않았습니다.",
    en: "TMF Zone is missing."
  },
  TMF_STUDY_DOCUMENT_SECTION_MISSING: {
    code: "TMF_STUDY_DOCUMENT_SECTION_MISSING",
    status: 400,
    ko: "TMF Section이 입력되지 않았습니다.",
    en: "TMF Section is missing."
  },
  TMF_STUDY_DOCUMENT_ARTIFACT_MISSING: {
    code: "TMF_STUDY_DOCUMENT_ARTIFACT_MISSING",
    status: 400,
    ko: "TMF Artifact가 입력되지 않았습니다.",
    en: "TMF Artifact is missing."
  },
  TMF_STUDY_DOCUMENT_SUB_ARTIFACT_MISSING: {
    code: "TMF_STUDY_DOCUMENT_SUB_ARTIFACT_MISSING",
    status: 400,
    ko: "TMF Sub Artifact가 입력되지 않았습니다.",
    en: "TMF Sub Artifact is missing."
  },
  TMF_STUDY_DOCUMENT_BLINDED_CHNAGE_NOT_ALLOW: {
    code: "TMF_STUDY_DOCUMENT_BLINDED_CHNAGE_NOT_ALLOW",
    status: 400,
    ko: "Document 눈가림 설정 이후에는 눈가림 해제할 수 없습니다.",
    en: "Document cannot be unblinded after it is blinded"
  },
  TMF_STUDY_DOCUMENT_DELETE_REASON_MISSING: {
    code: "TMF_STUDY_DOCUMENT_DELETE_REASON_MISSING",
    status: 400,
    ko: "TMF Document 삭제 사유가 입력되지 않았습니다.",
    en: "the reason for TMF document deletion is missing."
  },
  TMF_STUDY_DOCUMENT_DELETE_REASON_TOO_LONG: {
    code: "TMF_STUDY_DOCUMENT_DELETE_REASON_TOO_LONG",
    status: 400,
    ko: "TMF Document 삭제 사유의 길이는 최대 {length}자 입니다.",
    en: "The reason for TMF document deletion can be up to {length} characters long.",
  },

  TMF_STUDY_DOCUMENT_QC_REVIEWER_MISSING: {
    code: "TMF_STUDY_DOCUMENT_QC_REVIEWER_MISSING",
    status: 400,
    ko: "TMF Document Reviewer가 입력되지 않았습니다.",
    en: "TMF Document Reviewer is missing."
  },
  TMF_STUDY_DOCUMENT_QC_REVIEWER_INVALID: {
    code: "TMF_STUDY_DOCUMENT_QC_REVIEWER_INVALID",
    status: 400,
    ko: "TMF Document QC Reviewer Id({id})가 잘못되었습니다.",
    en: "TMF Document QC Reviewer Id({id}) is invalid."
  },
  TMF_STUDY_DOCUMENT_QC_DUE_DATE_MISSING: {
    code: "TMF_STUDY_DOCUMENT_QC_DUE_DATE_MISSING",
    status: 400,
    ko: "TMF Document QC due date이 입력되지 않았습니다.",
    en: "TMF Document QC due date is missing."
  },
  TMF_STUDY_DOCUMENT_QC_START_DATE_INVALID: {
    code: "TMF_STUDY_DOCUMENT_QC_START_DATE_INVALID",
    status: 400,
    ko: "TMF Document QC start date({date})가 잘못되었습니다.",
    en: "TMF Document QC start date({date}) is invalid."
  },
  TMF_STUDY_DOCUMENT_QC_DUE_DATE_INVALID: {
    code: "TMF_STUDY_DOCUMENT_QC_DUE_DATE_INVALID",
    status: 400,
    ko: "TMF Document QC due date({date})가 잘못되었습니다.",
    en: "TMF Document QC due date({date}) is invalid."
  },
  TMF_STUDY_DOCUMENT_QC_REASON_INVALID: {
    code: "TMF_STUDY_DOCUMENT_QC_REASON_INVALID",
    status: 400,
    ko: "TMF Document QC Reason({reason})가 잘못되었습니다.",
    en: "TMF Document QC Reason({reason}) is invalid."
  },
  TMF_STUDY_DOCUMENT_QC_REVIEWER_COMMENT_TOO_LONG: {
    code: "TMF_STUDY_DOCUMENT_QC_REVIEWER_COMMENT_TOO_LONG",
    status: 400,
    ko: "TMF Document QC Reviewer Comment의 길이는 최대 {length}자 입니다.",
    en: "TMF Document QC Reviewer's comment can be up to {length} characters long.",
  },
};

MvnCodeInitiate(TMF_STUDY_DOCUMENT_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_CONVERSION_FACTORS_LANG: {
  EMPTY_CONVERSION_FACTORS_TITLE: MvnCodeFE;
  EMPTY_CONVERSION_FACTORS_DESCRIPTION: MvnCodeFE;
  EMPTY_LIBRARY_DESCRIPTION: MvnCodeFE;
  OPEN_LIBRARY: MvnCodeFE;
  OPEN_LIBRARY_SUBTITLE: MvnCodeFE;
  OPEN_LIBRARY_SUCCESS: MvnCodeFE;
  OPEN_LIBRARY_FAIL: MvnCodeFE;
  OPEN_LIBRARY_ALERT_TITLE: MvnCodeFE;
  OPEN_LIBRARY_ALERT_MESSAGE: MvnCodeFE;
  SAVE_LIBRARY: MvnCodeFE;
  SAVE_LIBRARY_DIALOG_TITLE: MvnCodeFE;
  SAVE_LIBRARY_DIALOG_DESCRIPTION: MvnCodeFE;
  SAVE_LIBRARY_SUCCEED: MvnCodeFE;
  SAVE_LIBRARY_FAILED: MvnCodeFE;
  SAVE_LIBRARY_LIMIT_EXCEED: MvnCodeFE;
  SAVE_LIBRARY_NAME_PLACEHOLDER: MvnCodeFE;
  EXPORT_CONVERSION_FACTORS: MvnCodeFE;
  EXPORT_CONVERSION_FACTORS_SUCCEED: MvnCodeFE;
  EXPORT_CONVERSION_FACTORS_FAILED: MvnCodeFE;
  ADD_CONVERSION_FACTORS: MvnCodeFE;
  ADD_CONVERSION_FACTORS_SUCCEED: MvnCodeFE;
  ADD_CONVERSION_FACTORS_FAILED: MvnCodeFE;
  ADD_CONVERSION_FACTORS_DIALOG_TITLE: MvnCodeFE;
  ADD_CONVERSION_FACTORS_DIALOG_DESCRIPTION: MvnCodeFE;
  REMOVE_CONVERSION_FACTORS_SUCCEED: MvnCodeFE;
  REMOVE_CONVERSION_FACTORS_FAILED: MvnCodeFE;
  UPDATE_CONVERSION_FACTORS_FAILED: MvnCodeFE;
  FACTOR_TARGET_UNIT_VALUE_NUMERIC_ONLY: MvnCodeFE;
} = {
  EMPTY_CONVERSION_FACTORS_TITLE: {
    ko: "등록된 데이터가 없습니다.",
    en: "No data is currently registered.",
  },
  EMPTY_CONVERSION_FACTORS_DESCRIPTION: {
    ko: "라이브러리 열기를 이용해 사용할 라이브러리를 선택해 주세요.",
    en: "Please select the library you want to use.",
  },

  EMPTY_LIBRARY_DESCRIPTION: {
    ko: "저장된 파일이 없습니다.",
    en: "There is no saved file.",
  },

  OPEN_LIBRARY: {
    ko: "라이브러리 불러오기",
    en: "Open Library",
  },

  OPEN_LIBRARY_SUBTITLE: {
    ko: "사용할 파일을 선택해 주세요.",
    en: "Select the library to use.",
  },

  OPEN_LIBRARY_SUCCESS: {
    ko: "라이브러리 불러오기를 완료했습니다.",
    en: "The library has been opened.",
  },

  OPEN_LIBRARY_FAIL: {
    ko: "라이브러리 불러오기에 실패했습니다.",
    en: "Failed to open the library.",
  },

  OPEN_LIBRARY_ALERT_TITLE: {
    ko: "변환계수 라이브러리 대체",
    en: "Conversion Factors Library Replacement",
  },

  OPEN_LIBRARY_ALERT_MESSAGE: {
    ko: "스터디에 적용된 변환계수 정보를 선택한 파일로 대체하시겠습니까?\n현재 설정된 상태는 초기화되어 재사용이 불가능합니다.",
    en: "Do you want to replace the Conversion Factors information applied to the study with the selected library?\nThe currently set state will be reset and cannot be reused.",
  },

  SAVE_LIBRARY: {
    ko: "라이브러리 저장",
    en: "Save Library",
  },

  SAVE_LIBRARY_DIALOG_TITLE: {
    ko: "라이브러리 저장",
    en: "Save Library",
  },

  SAVE_LIBRARY_DIALOG_DESCRIPTION: {
    ko: "저장할 라이브러리 이름을 입력해 주세요.",
    en: "Fill in the library name where you want to save.",
  },

  SAVE_LIBRARY_NAME_PLACEHOLDER: {
    ko: "라이브러리명 입력",
    en: "Enter library name",
  },

  SAVE_LIBRARY_SUCCEED: {
    ko: "라이브러리를 저장했습니다.",
    en: "The library has been saved.",
  },

  SAVE_LIBRARY_FAILED: {
    ko: "라이브러리 저장에 실패했습니다.",
    en: "Failed to save the library.",
  },

  SAVE_LIBRARY_LIMIT_EXCEED: {
    ko: "라이브러리 저장 한도를 초과했습니다.",
    en: "The library storage limit has been exceeded.",
  },

  EXPORT_CONVERSION_FACTORS: {
    ko: "내보내기",
    en: "Export",
  },

  EXPORT_CONVERSION_FACTORS_SUCCEED: {
    ko: "내보내기를 완료했습니다.",
    en: "The library has been exported.",
  },

  EXPORT_CONVERSION_FACTORS_FAILED: {
    ko: "내보내기에 실패했습니다.",
    en: "Failed to export the library.",
  },

  ADD_CONVERSION_FACTORS: {
    ko: "변환계수 추가",
    en: "Add Conversion Factors",
  },

  ADD_CONVERSION_FACTORS_SUCCEED: {
    ko: "변환계수 추가를 완료했습니다.",
    en: "The Conversion Factors have been added.",
  },

  ADD_CONVERSION_FACTORS_FAILED: {
    ko: "변환계수 추가에 실패했습니다.",
    en: "Failed to add the Conversion Factors.",
  },

  ADD_CONVERSION_FACTORS_DIALOG_TITLE: {
    ko: "변환계수 추가",
    en: "Add Conversion Factor(s)",
  },

  ADD_CONVERSION_FACTORS_DIALOG_DESCRIPTION: {
    ko: "추가할 변수를 입력해 주세요.",
    en: "Enter the Conversion Factors to add.",
  },

  REMOVE_CONVERSION_FACTORS_SUCCEED: {
    ko: "변환계수 삭제를 완료했습니다.",
    en: "The Conversion Factors have been deleted.",
  },

  REMOVE_CONVERSION_FACTORS_FAILED: {
    ko: "변환계수 삭제에 실패했습니다.",
    en: "Failed to delete the Conversion Factors.",
  },

  UPDATE_CONVERSION_FACTORS_FAILED: {
    ko: "변환계수 변경에 실패했습니다.",
    en: "Failed to modify the Conversion Factors.",
  },
  FACTOR_TARGET_UNIT_VALUE_NUMERIC_ONLY: {
    ko: "Factor Target Unit 값은 숫자만 입력 가능합니다.",
    en: "The Factor Target Unit value can only be a number.",
  },
};

MvnCodeInitiate(CONVERTER_CONVERSION_FACTORS_LANG);

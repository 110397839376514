import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CONVERTER_COMMON_LANG: {
  // TITLE
  STUDY_TITLE: MvnCodeFE;
  IMPORT_TITLE: MvnCodeFE;
  SETTINGS_TITLE: MvnCodeFE;
  SETTINGS_DATASET_VARIABLE_TITLE: MvnCodeFE;
  SETTINGS_CONVERSION_FACTORS_TITLE: MvnCodeFE;
  SETTINGS_MY_DICTIONARY_TITLE: MvnCodeFE;
  DEFINE_XML_TITLE: MvnCodeFE;
  DATA_FLOW_TITLE: MvnCodeFE;
  CRF_LABELING_TITLE: MvnCodeFE;
  VALIDATION_TITLE: MvnCodeFE;
  AUDIT_TRAIL_TITLE: MvnCodeFE;

  // DESCRIPTION
  STUDY_TITLE_DESCRIPTION: MvnCodeFE;
  IMPORT_TITLE_DESCRIPTION: MvnCodeFE;
  VALIDATION_TITLE_DESCRIPTION: MvnCodeFE;
  AUDIT_TRAIL_TITLE_DESCRIPTION: MvnCodeFE;

  // VERB
  RESET_VERB: MvnCodeFE;
  ADD_VERB: MvnCodeFE;
  CREATE_VERB: MvnCodeFE;
  SAVE_VERB: MvnCodeFE;
  DELETE_VERB: MvnCodeFE;
  CONFIRM_VERB: MvnCodeFE;
  APPLY_VERB: MvnCodeFE;
  CANCEL_VERB: MvnCodeFE;
  UPDATE_VERB: MvnCodeFE;
  EDIT_VERB: MvnCodeFE;

  FORM_REQUIRED: MvnCodeFE;
} = {
  // NOUN
  STUDY_TITLE: {
    ko: "Study",
    en: "Study",
  },
  IMPORT_TITLE: {
    ko: "Import",
    en: "Import",
  },
  SETTINGS_TITLE: {
    ko: "Settings",
    en: "Settings",
  },
  SETTINGS_DATASET_VARIABLE_TITLE: {
    ko: "Dataset & Variables",
    en: "Dataset & Variables",
  },
  SETTINGS_CONVERSION_FACTORS_TITLE: {
    ko: "Conversion Factors",
    en: "Conversion Factors",
  },
  SETTINGS_MY_DICTIONARY_TITLE: {
    ko: "My Dictionary",
    en: "My Dictionary",
  },
  DEFINE_XML_TITLE: {
    ko: "Define-XML",
    en: "Define-XML",
  },
  DATA_FLOW_TITLE: {
    ko: "Data Flow",
    en: "Data Flow",
  },
  CRF_LABELING_TITLE: {
    ko: "CRF Labeling",
    en: "CRF Labeling",
  },
  VALIDATION_TITLE: {
    ko: "Validation",
    en: "Validation",
  },
  AUDIT_TRAIL_TITLE: {
    ko: "Audit Trail",
    en: "Audit Trail",
  },
  STUDY_TITLE_DESCRIPTION: {
    ko: "스터디를 생성하고 원하는 스터디로 진입할 수 있습니다.",
    en: "Create a folder for your study and upload data.",
  },
  IMPORT_TITLE_DESCRIPTION: {
    ko: "Data file을 업로드 할 수 있습니다.",
    en: "Data files can be uploaded.",
  },
  VALIDATION_TITLE_DESCRIPTION: {
    ko: "CDISC CORE 엔진을 이용하여 데이터 세트과 Define-xml의 유효성을 평가합니다.",
    en: "Validating Dataset and Define-XML using the CDISC CORE Engine.",
  },
  AUDIT_TRAIL_TITLE_DESCRIPTION: {
    ko: "스터디 내에 사용자의 활동을 확인할 수 있습니다.",
    en: "You can check your activity within the study.",
  },

  // VERB
  RESET_VERB: {
    ko: "초기화",
    en: "Reset",
  },
  ADD_VERB: {
    ko: "추가",
    en: "Add",
  },
  CREATE_VERB: {
    ko: "생성",
    en: "Create",
  },
  SAVE_VERB: {
    ko: "저장",
    en: "Save",
  },
  DELETE_VERB: {
    ko: "삭제",
    en: "Delete",
  },
  CONFIRM_VERB: {
    ko: "확인",
    en: "Confirm",
  },
  APPLY_VERB: {
    ko: "적용",
    en: "Apply",
  },
  CANCEL_VERB: {
    ko: "취소",
    en: "Cancel",
  },
  UPDATE_VERB: {
    ko: "수정",
    en: "Update",
  },
  EDIT_VERB: {
    ko: "수정",
    en: "Edit",
  },

  FORM_REQUIRED: {
    ko: "필수 입력값입니다.",
    en: "This field is required.",
  },
};

MvnCodeInitiate(CONVERTER_COMMON_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_REQUEST_LANG: {
  SAFETY_REQUEST_TYPE_SELECT_DIALOG_HEADER: MvnCodeFE;
  SAFETY_REQUEST_TYPE_SELECT_DROPDOWN_LABEL: MvnCodeFE;
  SAFETY_REQUEST_DIALOG_BTN_ADD_APPROVER: MvnCodeFE;
  SAFETY_REQUEST_DIALOG_BTN_SEARCH_APPROVER: MvnCodeFE;
  SAFETY_REQUEST_DIALOG_BTN_CHANGE_APPROVER: MvnCodeFE;
  SAFETY_REQUEST_CANCEL: MvnCodeFE;
  SAFETY_REQUEST_BUTTON: MvnCodeFE;
  SAFETY_REQUEST_CANCEL_SUCC: MvnCodeFE;
  SAFETY_REQUEST_CANCEL_FAIL: MvnCodeFE;
  SAFETY_REQUEST_CANCEL_DIALOG_CONFIRM_MESSAGE: MvnCodeFE;
  SAFETY_REQUEST_BATCH_CANCEL_DIALOG_CONFIRM_MESSAGE: MvnCodeFE;
  SAFETY_REQUEST_APPROVER_INDEX_HEADER: MvnCodeFE;
  SAFETY_REQUEST_ADD_APPROVER_EMAIL_PLACEHOLDER: MvnCodeFE;
  SAFETY_REQUEST_ADD_INVALID_APPROVER_DUPLICATED: MvnCodeFE;
  SAFETY_REQUEST_APPROVE_SUCC: MvnCodeFE;
  SAFETY_REQUEST_APPROVE_FAIL: MvnCodeFE;
  SAFETY_REQUEST_APPROVE_DIALOG_COMMENT_PLACEHOLDER: MvnCodeFE;
  SAFETY_REQUEST_RESPONSE_DIALOG_COMMENT_DESCRIPTION: MvnCodeFE;
  SAFETY_REQUEST_APPROVE_DIALOG_TITLE: MvnCodeFE;
  SAFETY_REQUEST_REJECT_SUCC: MvnCodeFE;
  SAFETY_REQUEST_REJECT_FAIL: MvnCodeFE;
  SAFETY_REQUEST_REJECT_DIALOG_COMMENT_PLACEHOLDER: MvnCodeFE;
  SAFETY_REQUEST_REJECT_DIALOG_TITLE: MvnCodeFE;
  SAFETY_REQUEST_TYPE_APPROVAL_DESCRIPTION: MvnCodeFE;
  SAFETY_REQUEST_TYPE_APPROVAL_INVALID_DESCRIPTION_CASE_LOCKED: MvnCodeFE;
  SAFETY_REQUEST_APPROVAL_REQUEST_DIALOG_HEADER: MvnCodeFE;
  SAFETY_REQUEST_APPROVAL_REQUEST_SUCC: MvnCodeFE;
  SAFETY_REQUEST_APPROVAL_REQUEST_FAIL: MvnCodeFE;
  SAFETY_REQUEST_APPROVAL_MEMBER_SEARCH_OVERLAY_LABEL: MvnCodeFE;
  SAFETY_REQUEST_CANCEL_APPROVAL_REQUEST_DIALOG_HEADER: MvnCodeFE;
  SAFETY_REQUEST_CANCEL_APPROVAL_REQUEST_SUCC: MvnCodeFE;
  SAFETY_REQUEST_CANCEL_APPROVAL_REQUEST_FAIL: MvnCodeFE;
  SAFETY_REQUEST_CANCEL_APPROVAL_MEMBER_SEARCH_OVERLAY_LABEL: MvnCodeFE;
  SAFETY_REQUEST_TYPE_CANCEL_APPROVAL_DESCRIPTION: MvnCodeFE;
  SAFETY_REQUEST_TYPE_UNBLIND_DESCRIPTION: MvnCodeFE;
  SAFETY_REQUEST_UNBLIND_REQUEST_DIALOG_HEADER: MvnCodeFE;
  SAFETY_REQUEST_UNBLIND_REQUEST_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_REQUEST_UNBLIND_REQUEST_SUCC: MvnCodeFE;
  SAFETY_REQUEST_UNBLIND_REQUEST_FAIL: MvnCodeFE;
  SAFETY_REQUEST_UNBLIND_MEMBER_SEARCH_OVERLAY_LABEL: MvnCodeFE;
  SAFETY_REQUEST_BTN_TRAIL: MvnCodeFE;
  SAFETY_REQUEST_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: MvnCodeFE;
} = {
  SAFETY_REQUEST_TYPE_SELECT_DIALOG_HEADER: {
    ko: "Select Request Type",
    en: "Select Request Type",
  },
  SAFETY_REQUEST_TYPE_SELECT_DROPDOWN_LABEL: {
    ko: "Request Type",
    en: "Request Type",
  },
  SAFETY_REQUEST_DIALOG_BTN_ADD_APPROVER: {
    ko: "승인자 추가하기",
    en: "Add Approver",
  },
  SAFETY_REQUEST_DIALOG_BTN_SEARCH_APPROVER: {
    ko: "승인자 검색하기",
    en: "Search Approver",
  },
  SAFETY_REQUEST_DIALOG_BTN_CHANGE_APPROVER: {
    ko: "승인자 변경하기",
    en: "Change Approver",
  },
  SAFETY_REQUEST_CANCEL: {
    ko: "Cancel Request",
    en: "Cancel Request",
  },
  SAFETY_REQUEST_BUTTON: {
    ko: "Request",
    en: "Request",
  },
  SAFETY_REQUEST_CANCEL_SUCC: {
    ko: "요청을 성공적으로 취소하였습니다.",
    en: "Cancel request has been completed successfully.",
  },
  SAFETY_REQUEST_CANCEL_FAIL: {
    ko: "요청 취소에 실패했습니다. ({error})",
    en: "Failed to cancel request. ({error})",
  },
  SAFETY_REQUEST_CANCEL_DIALOG_CONFIRM_MESSAGE: {
    ko: "요청을 취소하시겠습니까?",
    en: "Are you sure to cancel the request?",
  },
  SAFETY_REQUEST_BATCH_CANCEL_DIALOG_CONFIRM_MESSAGE: {
    ko: "{number}개의 리포트가 선택되었습니다.\n요청을 정말 취소하시겠습니까?",
    en: "{number} reports are selected.\nAre you sure you want to cancel Request?",
  },
  SAFETY_REQUEST_APPROVER_INDEX_HEADER: {
    ko: "{index}차 승인자",
    en: "{index}{suffix} Approver",
  },
  SAFETY_REQUEST_ADD_APPROVER_EMAIL_PLACEHOLDER: {
    ko: "승인 요청할 대상의 이메일을 입력해 주세요.",
    en: "Please enter the email address for approver request.",
  },
  SAFETY_REQUEST_ADD_INVALID_APPROVER_DUPLICATED: {
    ko: "승인자가 중복되었습니다.",
    en: "Approver is duplicated.",
  },
  SAFETY_REQUEST_APPROVE_SUCC: {
    ko: "승인을 완료하였습니다.",
    en: "You have approved the request successfully.",
  },
  SAFETY_REQUEST_APPROVE_FAIL: {
    ko: "승인을 실패했습니다. ({error})",
    en: "Failed to approve the request. ({error})",
  },
  SAFETY_REQUEST_APPROVE_DIALOG_COMMENT_PLACEHOLDER: {
    ko: "승인 사유를 입력해주세요.",
    en: "Please enter a comment.",
  },
  SAFETY_REQUEST_RESPONSE_DIALOG_COMMENT_DESCRIPTION: {
    ko: "Comment는 {number}자까지 입력 가능합니다.",
    en: "Comments are limited to {number} characters.",
  },
  SAFETY_REQUEST_APPROVE_DIALOG_TITLE: {
    ko: "요청을 승인하시겠습니까?",
    en: "Are you sure you want to approve request?",
  },
  SAFETY_REQUEST_REJECT_SUCC: {
    ko: "요청을 성공적으로 거절하였습니다.",
    en: "You have rejected the request successfully.",
  },
  SAFETY_REQUEST_REJECT_FAIL: {
    ko: "거절에 실패하였습니다. ({error})",
    en: "Failed to reject the request. ({error})",
  },
  SAFETY_REQUEST_REJECT_DIALOG_COMMENT_PLACEHOLDER: {
    ko: "거절 사유를 입력해주세요. (최소 {length}자 이상)",
    en: "Please enter a comment. (At least {length} characters)",
  },
  SAFETY_REQUEST_REJECT_DIALOG_TITLE: {
    ko: "요청을 거절하시겠습니까?",
    en: "Are you sure you want to reject request?",
  },
  SAFETY_REQUEST_TYPE_APPROVAL_DESCRIPTION: {
    ko: "승인 요청이 진행 중이거나 완료된 리포트는 수정이 불가능합니다. \n승인이 완료된 이후에는 Follow-up 리포트를 생성할 수 있습니다.",
    en: "Reports can not be revised during the approval process. When reports are approved, it can not be revised and able to generate follow-up reports.",
  },
  SAFETY_REQUEST_TYPE_APPROVAL_INVALID_DESCRIPTION_CASE_LOCKED: {
    ko: "잠긴 케이스는 승인 요청을 보낼 수 없습니다.",
    en: "Approval request for locked case is not available.",
  },
  SAFETY_REQUEST_APPROVAL_REQUEST_DIALOG_HEADER: {
    ko: "Approval Request",
    en: "Approval Request",
  },
  SAFETY_REQUEST_APPROVAL_REQUEST_SUCC: {
    ko: "승인 요청에 성공했습니다.",
    en: "You have sent Approval request successfully.",
  },
  SAFETY_REQUEST_APPROVAL_REQUEST_FAIL: {
    ko: "승인 요청에 실패했습니다. ({error})",
    en: "Failed to send Approval request. ({error})",
  },
  SAFETY_REQUEST_APPROVAL_MEMBER_SEARCH_OVERLAY_LABEL: {
    ko: "Members",
    en: "Members",
  },
  SAFETY_REQUEST_CANCEL_APPROVAL_REQUEST_DIALOG_HEADER: {
    ko: "Cancel Approval Request",
    en: "Cancel Approval Request",
  },
  SAFETY_REQUEST_CANCEL_APPROVAL_REQUEST_SUCC: {
    ko: "승인 취소 요청에 성공했습니다.",
    en: "You have sent Cancel Approval request successfully.",
  },
  SAFETY_REQUEST_CANCEL_APPROVAL_REQUEST_FAIL: {
    ko: "승인 취소 요청에 실패했습니다. ({error})",
    en: "Failed to send Cancel Approval request. ({error})",
  },
  SAFETY_REQUEST_CANCEL_APPROVAL_MEMBER_SEARCH_OVERLAY_LABEL: {
    ko: "Members",
    en: "Members",
  },
  SAFETY_REQUEST_TYPE_CANCEL_APPROVAL_DESCRIPTION: {
    ko: "Follow-up 리포트가 없는 리포트는 승인을 취소할 수 있습니다. \n승인이 취소되면 다시 리포트를 수정할 수 있습니다.",
    en: "Approved reports with no follow-up reports can be selected for cancellation of approval. \nReports can be revised after the process.",
  },
  SAFETY_REQUEST_TYPE_UNBLIND_DESCRIPTION: {
    ko: "Unblind 요청은 Blind 해제 권한을 갖는 유저에게만 요청할 수 있습니다.",
    en: "You may send unblind request to the member with 'Blinder' privilege to unblind blinded reports.",
  },
  SAFETY_REQUEST_UNBLIND_REQUEST_DIALOG_HEADER: {
    ko: "Unblind Request",
    en: "Unblind Request",
  },
  SAFETY_REQUEST_UNBLIND_REQUEST_DIALOG_DESCRIPTION: {
    ko: "Blind 해제 권한이 있는 유저를 선택하세요.",
    en: "Select members with ‘Blinder’ privilege.",
  },
  SAFETY_REQUEST_UNBLIND_REQUEST_SUCC: {
    ko: "눈가림 해제 요청에 성공했습니다.",
    en: "You have sent Unblind request successfully.",
  },
  SAFETY_REQUEST_UNBLIND_REQUEST_FAIL: {
    ko: "눈가림 해제 요청에 실패했습니다. ({error})",
    en: "Failed to send Unblind request. ({error})",
  },
  SAFETY_REQUEST_UNBLIND_MEMBER_SEARCH_OVERLAY_LABEL: {
    ko: "Members with blind privilege",
    en: "Members with blind privilege",
  },
  SAFETY_REQUEST_BTN_TRAIL: {
    ko: "Request Trail",
    en: "Request Trail",
  },
  SAFETY_REQUEST_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. Request를 진행하시겠습니까?",
    en: "You have unsaved data. Do you want to process Request?",
  },
};

MvnCodeInitiate(SAFETY_REQUEST_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_CODE: {
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_LENGTH_EXCEEDED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_LABEL_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_REQUIRED_VALUE_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VALUE: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_NO_INNER_ITEM: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_INNER_ITEM_SETTING_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_PROPERTY_INVALID_REQUIRED_VALUE_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_VISIT_CODE: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_SITE_CODE: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_ROLE_NAME: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_LIMIT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_DICTIONARY_TYPE: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_SEQUENCE_ITEM_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_SEQUENCE_ITEM_DUPLICATED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_MISSING",
    status: 400,
    ko: "Section Item OID를 입력해 주세요.",
    en: "Please enter a section item OID.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_FORMAT: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_FORMAT",
    status: 400,
    ko: "Section Item OID는 영대문자, 숫자만 입력할 수 있습니다. (OID: {identifier})",
    en: "Section item OID should be combination of uppercase letters and number. (OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_LENGTH_EXCEEDED",
    status: 400,
    ko: "Section Item OID는 {length}자리 이하의 문자이어야합니다.",
    en: "Section item OID length should be {length} or less.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "중복된 Section Item OID({id})가 존재합니다.",
    en: "Section Item OID({id}) is duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_LABEL_FORMAT: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_LABEL_FORMAT",
    status: 400,
    ko: "Section Item Label은 한글, 영문자, 숫자, 특수문자만 입력할 수 있습니다. (label: {label})",
    en: "Section item label should be combination of english letters, special characters and number. (label: {label})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_REQUIRED_VALUE_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_REQUIRED_VALUE_MISSING",
    status: 400,
    ko: "{type} Item의 필수값이 누락되었습니다. (Item OID: {identifier}, property: {property})",
    en: "Required value of {type} item is missing. (Item OID: {identifier}, property: {property})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VALUE: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VALUE",
    status: 400,
    ko: "유효하지 않은 값 입니다. (Item OID: {identifier}, Property: {property}, Value: {value})",
    en: "Invalid item value. (Item OID: {identifier}, Property: {property}, Value: {value})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_NO_INNER_ITEM: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_NO_INNER_ITEM",
    status: 400,
    ko: "{type} Item의 내부 Item이 존재하지 않습니다. (Item OID: {identifier})",
    en: "Inner item of {type} item is missing. (Item OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_INNER_ITEM_SETTING_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_INNER_ITEM_SETTING_MISSING",
    status: 400,
    ko: "{type} Item의 내부 Item의 설정이 누락되었습니다. (Item OID: {identifier})",
    en: "Inner item setting of {type} item is missing. (Item OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_VISIT_CODE: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_VISIT_CODE",
    status: 400,
    ko: "{type} Item의 Visibility의 Visit Code({code}) 정보가 올바르지 않습니다. (Item OID: {identifier})",
    en: "{type} Item setting of visibility visit code({code}) is invalid.(Item OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_SITE_CODE: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_SITE_CODE",
    status: 400,
    ko: "{type} Item의 Visibility의 Site Code({code}) 정보가 올바르지 않습니다. (Item OID: {identifier})",
    en: "{type} Item setting of visibility site code({code}) is invalid.(Item OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_ROLE_NAME: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_ROLE_NAME",
    status: 400,
    ko: "{type} Item의 Visibility의 Role name({name}) 정보가 올바르지 않습니다. (Item OID: {identifier})",
    en: "{type} Item setting of visibility role name({name}) is invalid.(Item OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_VISIBILITY_PROPERTY_MISSING",
    status: 400,
    ko: "{type} Item의 Visibility의 {property}가 누락되었습니다. (Item OID: {identifier})",
    en: "{type} Item setting of visibility {property} is missing.(Item OID: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_PROPERTY_INVALID_REQUIRED_VALUE_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_PROPERTY_INVALID_REQUIRED_VALUE_MISSING",
    status: 400,
    ko: "Study Page({pageIdentifier}) Section({sectionIdentifier}) Item({itemIdentifier}) {property}의 필수값({value})이 누락되었습니다.",
    en: "Required value({value}) of study page({pageIdentifier}) section({sectionIdentifier}) item({itemIdentifier}) {property} is missing.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_LIMIT: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_LIMIT",
    status: 400,
    ko: "Appendable Table, Table, LabTestTable 아이템은 section에 하나씩만 등록할 수 있습니다.(Page:{pageIdentifier} Section:{sectionIdentifier})",
    en: "Appendable Table, Table, LabTestTable items can only be registered once per section. (Page: {pageIdentifier} Section: {sectionIdentifier})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_DICTIONARY_TYPE: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_DICTIONARY_TYPE",
    status: 400,
    ko: "Dictionary 타입은 Direct Input만 입력할 수 없습니다. (Item OID: {OID}, property: Dictionary)",
    en: "Dictionary type cannot be only Direct Input. (Item OID: {OID}, property: Dictionary)",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_SEQUENCE_ITEM_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_SEQUENCE_ITEM_MISSING",
    status: 409,
    ko: "Table ({identifier}) 내에 sequence item이 존재하지 않습니다.",
    en: "Table ({identifier}) is missing a sequence item.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_SEQUENCE_ITEM_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_INVALID_SEQUENCE_ITEM_DUPLICATED",
    status: 409,
    ko: "Table ({identifier}) 내에 sequence item이 한 개 이상 존재합니다.",
    en: "Table ({identifier}) has multiple sequence items.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_PAGE_SECTION_ITEM_CODE);

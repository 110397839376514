import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX: {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_INVALID_PROPERTY_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_INVALID_ENROLL_DUPLICATED: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_INVALID_PROPERTY_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_INVALID_PROPERTY_MISSING",
    status: 400,
    ko: "'{property}' 필드는 필수 입력값입니다.",
    en: "'{property}' field is required.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_INVALID_ENROLL_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_INVALID_ENROLL_DUPLICATED",
    status: 400,
    ko: "중복된 Enroll Visit({visitId}):Page({pageId})가 존재합니다.",
    en: "Enroll Visit({visitId}):Page({pageId}) is duplicated.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_NOTIFICATION_LANG: {
  CDMS_STUDY_NOTIFICATION_BUTTON: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_INVALID_TIME: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_ADD_BUTTON: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_SET_FREQUENCY_BUTTON: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_EMPTY: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_NOT_SET: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_LABEL: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_WEEKLY_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_WEEK: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_MONTHLY_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_MONTH: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_MONTHLY_DESCRIPTION: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_DAY_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_DATE_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_HOUR_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_FREQUENCY_HOUR: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_EXCEED: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_ADD_DIALOG_TITLE: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_ADD_DIALOG_SUCCESS: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_ADD_DIALOG_FAIL: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_UPDATE_DIALOG_TITLE: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_UPDATE_DIALOG_SUCCESS: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_UPDATE_DIALOG_FAIL: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_DELETE_DIALOG_TITLE: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_DELETE_DIALOG_CONTENT: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_DELETE_SUCCESS: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_DELETE_FAIL: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_QUERY_STATUS_LABEL: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_QUERY_FILE_FORMAT_LABEL: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_QUERY_DATA_RANGE_LABEL: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_QUERY_DATA_RANGE_DESCRIPTION: MvnCodeFE;
  CDMS_STUDY_NOTIFICATION_REPORT_LABEL: MvnCodeFE;
} = {
  CDMS_STUDY_NOTIFICATION_BUTTON: {
    ko: "Notification",
    en: "Notification",
  },
  CDMS_STUDY_NOTIFICATION_INVALID_TIME: {
    ko: "메일 발송 시간은 현재 시간보다 이전 시간으로 설정할 수 없습니다.",
    en: "The send time cannot be earlier than the current time.",
  },
  CDMS_STUDY_NOTIFICATION_ADD_BUTTON: {
    ko: "Add Notification",
    en: "Add Notification",
  },
  CDMS_STUDY_NOTIFICATION_SET_FREQUENCY_BUTTON: {
    ko: "Set Frequency",
    en: "Set Frequency",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_EMPTY: {
    ko: "설정된 알림 주기가 없습니다.",
    en: "Notification frequency has not been set.",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_NOT_SET: {
    ko: "설정 안 함",
    en: "Not set",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_LABEL: {
    ko: "Frequency",
    en: "Frequency",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_WEEKLY_PLACEHOLDER: {
    ko: "1-10주",
    en: "1-10 weeks",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_WEEK: {
    ko: "주",
    en: " week",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_MONTHLY_PLACEHOLDER: {
    ko: "1-12개월",
    en: "1-12 months",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_MONTH: {
    ko: "개월",
    en: " month",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_DAY_PLACEHOLDER: {
    ko: "요일",
    en: "Day of the Week",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_DATE_PLACEHOLDER: {
    ko: "1~31일",
    en: "1st~31st day",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_HOUR_PLACEHOLDER: {
    ko: "00~23시",
    en: "00~23h",
  },
  CDMS_STUDY_NOTIFICATION_FREQUENCY_HOUR: {
    ko: "시",
    en: "h",
  },
  CDMS_STUDY_NOTIFICATION_MONTHLY_DESCRIPTION: {
    ko: "29~31일이 없는 달에는 당월 마지막날에 알림 메일이 발송됩니다.",
    en: "The mail is sent on the last day of the month for months that don't have days 29~31.",
  },
  CDMS_STUDY_NOTIFICATION_EXCEED: {
    ko: "최대 3개까지 추가 가능합니다.",
    en: "You can only add up to 3 notifications.",
  },
  CDMS_STUDY_NOTIFICATION_ADD_DIALOG_TITLE: {
    ko: "Add Notification",
    en: "Add Notification",
  },
  CDMS_STUDY_NOTIFICATION_ADD_DIALOG_SUCCESS: {
    ko: "Notification 추가가 완료되었습니다.",
    en: "Notification has been added.",
  },
  CDMS_STUDY_NOTIFICATION_ADD_DIALOG_FAIL: {
    ko: "Notification 추가에 실패했습니다. ({message})",
    en: "Failed to add Notification. ({message})",
  },
  CDMS_STUDY_NOTIFICATION_UPDATE_DIALOG_TITLE: {
    ko: "Edit Notification",
    en: "Edit Notification",
  },
  CDMS_STUDY_NOTIFICATION_UPDATE_DIALOG_SUCCESS: {
    ko: "Notification 변경이 완료되었습니다.",
    en: "Notification has been changed.",
  },
  CDMS_STUDY_NOTIFICATION_UPDATE_DIALOG_FAIL: {
    ko: "Notification 변경에 실패했습니다. ({message})",
    en: "Failed to change Notification. ({message})",
  },
  CDMS_STUDY_NOTIFICATION_DELETE_DIALOG_TITLE: {
    ko: "Delete Notification",
    en: "Delete Notification",
  },
  CDMS_STUDY_NOTIFICATION_DELETE_DIALOG_CONTENT: {
    ko: "해당 Notification이 영구 삭제됩니다.\n정말 삭제하시겠습니까?",
    en: "The notification will be deleted permanently.\nDo you want to proceed?",
  },
  CDMS_STUDY_NOTIFICATION_DELETE_SUCCESS: {
    ko: "Notification이 삭제되었습니다.",
    en: "The notification has been deleted.",
  },
  CDMS_STUDY_NOTIFICATION_DELETE_FAIL: {
    ko: "Notification이 삭제에 실패했습니다. ({message})",
    en: "Failed to delete Notification. ({message})",
  },
  CDMS_STUDY_NOTIFICATION_QUERY_STATUS_LABEL: {
    ko: "Query Status",
    en: "Query Status",
  },
  CDMS_STUDY_NOTIFICATION_QUERY_FILE_FORMAT_LABEL: {
    ko: "File Format",
    en: "File Format",
  },
  CDMS_STUDY_NOTIFICATION_QUERY_DATA_RANGE_LABEL: {
    ko: "Data Range",
    en: "Data Range",
  },
  CDMS_STUDY_NOTIFICATION_QUERY_DATA_RANGE_DESCRIPTION: {
    ko: "설정한 범위 내에 Query Data중 선택한 Query Status, File Format에 맞춰 메일을 발송합니다.",
    en: "Query data issued during the selected dates will be sent to your email.",
  },
  CDMS_STUDY_NOTIFICATION_REPORT_LABEL: {
    ko: "알림 주기",
    en: "Notification frequency",
  },
};

MvnCodeInitiate(CDMS_STUDY_NOTIFICATION_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_REPORT_LANG: {
  CDMS_STUDY_REPORT_GO_TO_CRF_BUTTON: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_AESI_TITLE: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_AESI_DESC: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_PREGNANCY_TITLE: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_PREGNANCY_DESC: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_SAE_TITLE: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_SAE_DESC: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_AE_TITLE: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_AE_DESC: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_QC_TITLE: MvnCodeFE;
  CDMS_STUDY_REPORT_PAGE_QC_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_REPORT_GO_TO_CRF_BUTTON: {
    ko: "CRF로 이동",
    en: "Go to CRF",
  },
  CDMS_STUDY_REPORT_PAGE_TITLE: {
    ko: "Report",
    en: "Report",
  },
  CDMS_STUDY_REPORT_PAGE_DESC: {
    ko: "등록된 이상반응 Report를 관리할 수 있습니다.",
    en: "Manage and inquire adverse event Report.",
  },
  CDMS_STUDY_REPORT_PAGE_AESI_TITLE: {
    ko: "AESI Report",
    en: "AESI Report",
  },
  CDMS_STUDY_REPORT_PAGE_AESI_DESC: {
    ko: "등록된 AESI Report를 관리할 수 있습니다.",
    en: "Manage and inquire AESI Report.",
  },
  CDMS_STUDY_REPORT_PAGE_PREGNANCY_TITLE: {
    ko: "Pregnancy Report",
    en: "Pregnancy Report",
  },
  CDMS_STUDY_REPORT_PAGE_PREGNANCY_DESC: {
    ko: "등록된 Pregnancy Report를 관리할 수 있습니다.",
    en: "Manage and inquire Pregnancy Report.",
  },
  CDMS_STUDY_REPORT_PAGE_SAE_TITLE: {
    ko: "SAE Report",
    en: "SAE Report",
  },
  CDMS_STUDY_REPORT_PAGE_SAE_DESC: {
    ko: "등록된 SAE Report를 관리할 수 있습니다.",
    en: "Manage and inquire SAE Report.",
  },
  CDMS_STUDY_REPORT_PAGE_AE_TITLE: {
    ko: "AE Report",
    en: "AE Report",
  },
  CDMS_STUDY_REPORT_PAGE_AE_DESC: {
    ko: "등록된 AE Report를 관리할 수 있습니다.",
    en: "Manage and inquire AE Report.",
  },
  CDMS_STUDY_REPORT_PAGE_QC_TITLE: {
    ko: "QC Report",
    en: "QC Report",
  },
  CDMS_STUDY_REPORT_PAGE_QC_DESC: {
    ko: "등록된 QC Report를 관리할 수 있습니다.",
    en: "Manage and inquire QC Report.",
  },
};

MvnCodeInitiate(CDMS_STUDY_REPORT_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CRF_CAPTURE_CODE: {
  CRF_CAPTURE_REQUIRED_VALUE_MISSING: MvnCodeBE;
  CRF_CAPTURE_ITEM_NOT_CAPTURABLE: MvnCodeBE;
  CRF_CAPTURE_ITEM_NOT_AVAILABLE: MvnCodeBE;
  CRF_CAPTURE_ITEM_INVALID_NOT_ALLOWED_FORMAT: MvnCodeBE;
  CRF_CAPTURE_ITEM_INVALID_NOT_ALLOWED_CHARACTER: MvnCodeBE;
  CRF_CAPTURE_ITEM_INVALID_NOT_MODIFIED: MvnCodeBE;
  CRF_CAPTURE_AUTO_ITEM_INVALID_NOT_MATCHED_VALUE: MvnCodeBE;
  CRF_CAPTURE_AUTO_ITEM_INVALID_MATCHED_NOT_EXIST: MvnCodeBE;
  CRF_CAPTURE_RELATED_TEXT_ITEM_INVALID_NOT_MATCHED_VALUE: MvnCodeBE;
  CRF_CAPTURE_RELATED_TEXT_ITEM_INVALID_STRIKED_VALUE: MvnCodeBE;
  CRF_CAPTURE_HIDDEN_ITEM_INVALID_NOT_MATCHED_CONDITION: MvnCodeBE;
  CRF_CAPTURE_DATE_ITEM_INVALID_DATE_RANGE: MvnCodeBE;
} = {
  CRF_CAPTURE_REQUIRED_VALUE_MISSING: {
    status: 400,
    ko: "필수값이 누락되었습니다. (label: {label})",
    en: "Required item value is missing. (label: {label})",
  },
  CRF_CAPTURE_ITEM_NOT_CAPTURABLE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 값이 capture 될 수 없는 조건입니다.",
    en: "[{itemSeq}] {itemId} value does not match capture conditions.",
  },
  CRF_CAPTURE_ITEM_NOT_AVAILABLE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}는 입력할 수 없는 항목입니다.",
    en: "[{itemSeq}] {itemId} cannot be entered.",
  },
  CRF_CAPTURE_ITEM_INVALID_NOT_ALLOWED_FORMAT: {
    status: 400,
    ko: "허용하지 않는 데이터 형식입니다. ([{itemSeq}] {itemId})",
    en: "Not allowed data format. ([{itemSeq}] {itemId})",
  },
  CRF_CAPTURE_ITEM_INVALID_NOT_ALLOWED_CHARACTER: {
    status: 400,
    ko: "허용하지 않는 문자 형식입니다.",
    en: "Not allowed character format.",
  },
  CRF_CAPTURE_ITEM_INVALID_NOT_MODIFIED: {
    status: 400,
    ko: "[{itemSeq}] {itemId}는 수정할 수 없는 항목입니다.",
    en: "[{itemSeq}] {itemId} cannot be modified.",
  },
  CRF_CAPTURE_AUTO_ITEM_INVALID_NOT_MATCHED_VALUE: {
    status: 400,
    ko: "{itemId}의 value가 매칭되는 bind item의 값과 일치하지 않습니다.",
    en: "{itemId} does not have a same value with a bind item.",
  },
  CRF_CAPTURE_AUTO_ITEM_INVALID_MATCHED_NOT_EXIST: {
    status: 400,
    ko: "{itemId}에 match 되는 bind item이 존재하지 않습니다.",
    en: "{itemId} does not match with any bind item.",
  },
  CRF_CAPTURE_RELATED_TEXT_ITEM_INVALID_NOT_MATCHED_VALUE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 value가 참조하는 item의 값과 일치하지 않습니다.",
    en: "[{itemSeq}] {itemId} does not have a same value with a related item.",
  },
  CRF_CAPTURE_RELATED_TEXT_ITEM_INVALID_STRIKED_VALUE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 value가 참조하는 item의 값이 유효하지 않습니다.",
    en: "[{itemSeq}] related item of {itemId} is invalid.",
  },
  CRF_CAPTURE_HIDDEN_ITEM_INVALID_NOT_MATCHED_CONDITION: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 값이 조건과 맞지 않습니다.",
    en: "[{itemSeq}] {itemId} value does not match condition.",
  },
  CRF_CAPTURE_DATE_ITEM_INVALID_DATE_RANGE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 value가 범위를 초과하였습니다. (range: {range})",
    en: "[{itemSeq}] {itemId} is out of range. (range: {range})",
  },
};

MvnCodeInitiate(SAFETY_CRF_CAPTURE_CODE);

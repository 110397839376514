import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_IP_MANAGEMENT_LANG: {
  CDMS_STUDY_IP_MANAGEMENT_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_IP_MANAGEMENT_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_IP_MANAGEMENT_STOCK_INFO: MvnCodeFE;
  CDMS_STUDY_IP_MANAGEMENT_PHARMACY_INFO: MvnCodeFE;
  CDMS_STUDY_IP_MANAGEMENT_DISABLED_INFO: MvnCodeFE;
  CDMS_STUDY_IP_MANAGEMENT_USED_RETURN_INFO: MvnCodeFE;
  CDMS_STUDY_IP_MANAGEMENT_UNUSED_RETURN_INFO: MvnCodeFE;
} = {
  CDMS_STUDY_IP_MANAGEMENT_PAGE_TITLE: {
    ko: "IP Management",
    en: "IP Management",
  },
  CDMS_STUDY_IP_MANAGEMENT_PAGE_DESC: {
    ko: "IP 배정, 불출, 반납 등 IP 상태를 관리하고, 관련 문서를 출력할 수 있습니다.",
    en: "Manage IP status and export relevant documents.",
  },
  CDMS_STUDY_IP_MANAGEMENT_STOCK_INFO: {
    ko: "Site 별 IP 현황 요약표를 조회할 수 있습니다.",
    en: "A summary table of the IP status by site.",
  },
  CDMS_STUDY_IP_MANAGEMENT_PHARMACY_INFO: {
    ko: "해당 Site에서 보관하고 있는 IP 목록입니다. IP Status는 가용재고, 불출대기, 배송파손, 파손으로 표현됩니다.",
    en: "IPs stored at each site. The IP status can be Available, Dispense Ready, Damaged on delivery, or Damaged.",
  },
  CDMS_STUDY_IP_MANAGEMENT_DISABLED_INFO: {
    ko: "사용불가 상태의 IP 목록입니다. IP Status는 배송파손, 파손, 배송분실, 분실, 배송누락, 미사용 반납 개시, 반납 요청, 반납 완료, 처방 취소, 배정 후 분실, 배정 후 파손, 오불출로 표현됩니다. 특정 IP를 선택 후 상태 변경을 통해 다시 사용 가능 재고로 돌려놓을 수 있습니다.",
    en: "IPs that are disabled. The IP status can be Damaged on delivery, Damaged, Lost on delivery, Lost, Omitted on delivery, Unused ready for return, Return requested, Returned, Prescription cancelled, Lost after allocation, Damaged after allocation, or Dispense Error.",
  },
  CDMS_STUDY_IP_MANAGEMENT_USED_RETURN_INFO: {
    ko: "사용 후 환자가 반납한 IP를 제약사로 반납 요청합니다. IP Accountability에서 Return Status가 Returned 인 IP가 조회됩니다.",
    en: "IPs returned by patients after use. These are IPs with a Return Status of Returned in the IP Accountability menu.",
  },
  CDMS_STUDY_IP_MANAGEMENT_UNUSED_RETURN_INFO: {
    ko: "사용하지 않은 IP를 제약사로 반납 요청 합니다. IP Status는 가용재고, 배송파손, 파손, 미사용 반납 대기로 표현됩니다.",
    en: "IPs that were not used but are being returned to the manufacturer. The IP status can be Available, Damaged on delivery, Damaged, or Unused ready for return.",
  },
};

MvnCodeInitiate(CDMS_STUDY_IP_MANAGEMENT_LANG);

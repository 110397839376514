import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_UAT_LANG: {
  CDMS_UAT_PAGE_TITLE: MvnCodeFE;
  CDMS_UAT_PAGE_DESC: MvnCodeFE;
  CDMS_UAT_CAPTURE_SCREEN_REGION: MvnCodeFE;
  CDMS_UAT_CAPTURE_FULL_SCREEN: MvnCodeFE;
  CDMS_UAT_CAPTURE_FULL_PAGE: MvnCodeFE;
  CDMS_UAT_CATEGORY_REQUIRED: MvnCodeFE;
  CDMS_UAT_TITLE_REQUIRED: MvnCodeFE;
  CDMS_UAT_CONTENT_REQUIRED: MvnCodeFE;
} = {
  CDMS_UAT_PAGE_TITLE: {
    ko: "UAT",
    en: "UAT",
  },
  CDMS_UAT_PAGE_DESC: {
    ko: "Study에 등록된 UAT 목록을 확인하고 관리할 수 있습니다.",
    en: "Inquire and download UAT report.",
  },
  CDMS_UAT_CAPTURE_SCREEN_REGION: {
    ko: "직접 지정",
    en: "Capture a portion",
  },
  CDMS_UAT_CAPTURE_FULL_SCREEN: {
    ko: "전체 스크린",
    en: "Full Screen",
  },
  CDMS_UAT_CAPTURE_FULL_PAGE: {
    ko: "전체 페이지",
    en: "Full Page",
  },
  CDMS_UAT_CATEGORY_REQUIRED: {
    ko: "Category를 선택해 주세요.",
    en: "Please select a category.",
  },
  CDMS_UAT_TITLE_REQUIRED: {
    ko: "Title은 필수 입력 값입니다.",
    en: "The title is required.",
  },
  CDMS_UAT_CONTENT_REQUIRED: {
    ko: "Content는 필수 입력 값입니다.",
    en: "The content is required.",
  },
};

MvnCodeInitiate(CDMS_STUDY_UAT_LANG);

import { MvnCodeFE } from "./../const";
import { MvnCodeInitiate } from "../../lib/const";

export const CDMS_STUDY_INDEPENDENT_EVALUATION_LANG: {
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_INDEPENDENT_EVALUATION_FILE_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_TITLE: {
    ko: "Independent Evaluation",
    en: "Independent Evaluation",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_PAGE_DESC: {
    ko: "독립적 평가 대상자를 확인하고 평가를 진행할 수 있습니다.",
    en: "View independent evaluation subjects and proceed with the evaluation.",
  },
  CDMS_STUDY_INDEPENDENT_EVALUATION_FILE_DOWNLOAD_FAIL: {
    ko: "파일 다운로드에 실패하였습니다.",
    en: "Failed to download the file.",
  },
};

MvnCodeInitiate(CDMS_STUDY_INDEPENDENT_EVALUATION_LANG);

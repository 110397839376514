import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_CAPTURE_STATUS_LANG: {
  CDMS_STUDY_CAPTURE_STATUS_SDV_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CAPTURE_STATUS_SDV_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_CAPTURE_STATUS_FREEZING_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CAPTURE_STATUS_FREEZING_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_CAPTURE_STATUS_LOCKING_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CAPTURE_STATUS_LOCKING_PAGE_DESC: MvnCodeFE;
  CDMS_STUDY_CAPTURE_STATUS_ESIGN_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CAPTURE_STATUS_ESIGN_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_CAPTURE_STATUS_SDV_PAGE_TITLE: {
    ko: "CRF SDV",
    en: "CRF SDV",
  },
  CDMS_STUDY_CAPTURE_STATUS_SDV_PAGE_DESC: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 SDV 처리를 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch SDV for each registerd Subject/Site/Visit/CRF.",
  },
  CDMS_STUDY_CAPTURE_STATUS_FREEZING_PAGE_TITLE: {
    ko: "CRF Freezing",
    en: "CRF Freezing",
  },
  CDMS_STUDY_CAPTURE_STATUS_FREEZING_PAGE_DESC: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 Freezing 처리를 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch freezing for each registerd Subject/Site/Visit/CRF.",
  },
  CDMS_STUDY_CAPTURE_STATUS_LOCKING_PAGE_TITLE: {
    ko: "CRF Locking",
    en: "CRF Locking",
  },
  CDMS_STUDY_CAPTURE_STATUS_LOCKING_PAGE_DESC: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 Locking 처리를 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch locking for each registerd Subject/Site/Visit/CRF.",
  },
  CDMS_STUDY_CAPTURE_STATUS_ESIGN_PAGE_TITLE: {
    ko: "CRF E-Sign",
    en: "CRF E-Sign",
  },
  CDMS_STUDY_CAPTURE_STATUS_ESIGN_PAGE_DESC: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 E-Sign을 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch E-Sign for each registerd Subject/Site/Visit/CRF.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CAPTURE_STATUS_LANG);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_REPORT_IMPORT_LANG: {
  SAFETY_CASE_REPORT_IMPORT_BREADCRUMB: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_DIALOG_LABEL_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_DIALOG_TITLE: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_DIALOG_SUBMISSION_DUE_AT_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_SUCC: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_FAIL: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_PATH_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_REQUEST_SUCC: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_REQUEST_FAIL: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_REQUEST_SUCC: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_REQUEST_FAIL: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATION_FAILURE_DIALOG_CONTENT_FAIL_DURING_IMPORT: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_DUPLICATE_CASE_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_XML_SIMPLIFY_VALIDATION_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_XML_SIMPLIFY_VALIDATION_TOOLTIP: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_XML_SIMPLIFY_VALIDATION_RESULT_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_IMPORT_XML_AUTO_IDENTIFIER_DESCRIPTION: MvnCodeFE;
} = {
  SAFETY_CASE_REPORT_IMPORT_BREADCRUMB: {
    ko: "데이터를 업로드하여 Safety 데이터베이스를 구축할 수 있습니다.",
    en: "Import reports and build safety database.",
  },
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_DIALOG_LABEL_DESCRIPTION: {
    ko: "Description",
    en: "Description",
  },
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_DIALOG_TITLE: {
    ko: "Upload File",
    en: "Upload File",
  },
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_DIALOG_SUBMISSION_DUE_AT_DESCRIPTION: {
    ko: "보고서 제출기한의 Timezone은 Workspace의 Timezone과 동일하게 설정됩니다.",
    en: "Timezone of Due Date will follow timezone of workspace.",
  },
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_SUCC: {
    ko: "Import 파일 업로드에 성공했습니다.",
    en: "File upload has been completed successfully.",
  },
  SAFETY_CASE_REPORT_IMPORT_UPLOAD_FAIL: {
    ko: "Import 파일 업로드에 실패했습니다. ({message})",
    en: "Failed to upload an import file. ({message})",
  },
  SAFETY_CASE_REPORT_IMPORT_PATH_DIALOG_HEADER: {
    ko: "Import report(s) to",
    en: "Import report(s) to",
  },
  SAFETY_CASE_REPORT_IMPORT_REQUEST_SUCC: {
    ko: "Import 요청에 성공했습니다.",
    en: "Import request has been completed successfully.",
  },
  SAFETY_CASE_REPORT_IMPORT_REQUEST_FAIL: {
    ko: "Import 요청에 실패했습니다.",
    en: "Failed to request an import. ({message})",
  },
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_REQUEST_SUCC: {
    ko: "Import file 다운로드 요청을 성공했습니다.",
    en: "File download request has been completed successfully.",
  },
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_REQUEST_FAIL: {
    ko: "Import file 다운로드 요청이 실패했습니다. ({message})",
    en: "Failed to import file download. ({message})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATION_FAILURE_DIALOG_CONTENT_FAIL_DURING_IMPORT: {
    ko: "import 과정에서 오류가 발생했습니다.\n 파일을 확인하고 다시 시도하십시오.\n 동일한 오류가 발생하면 당사에 문의해 주세요.",
    en: "Failure occurred during the import process.\n Please check your file and try again.\n If you encounter same failure, please contact us.",
  },
  SAFETY_CASE_REPORT_IMPORT_DUPLICATE_CASE_DIALOG_DESCRIPTION: {
    ko: "중복으로 입력된 Case가 있습니다. 아래 Case 리스트를 확인하시기 바랍니다.",
    en: "The case already exists. Please check the case list below.",
  },
  SAFETY_CASE_REPORT_IMPORT_XML_SIMPLIFY_VALIDATION_DESCRIPTION: {
    ko: "일부 필수 값 검증을 건너뜁니다.",
    en: "You can skip validation for some required fields.",
  },
  SAFETY_CASE_REPORT_IMPORT_XML_SIMPLIFY_VALIDATION_TOOLTIP: {
    ko: "다음 값들만 필수로 검증됩니다.",
    en: "Only the following values are mandatory for validation.",
  },
  SAFETY_CASE_REPORT_IMPORT_XML_SIMPLIFY_VALIDATION_RESULT_DESCRIPTION: {
    ko: "간소화된 검증 결과입니다.",
    en: "This is the simplified validation result.",
  },
  SAFETY_CASE_REPORT_IMPORT_XML_AUTO_IDENTIFIER_DESCRIPTION: {
    ko: "고유식별 보고자관리번호를 자동으로 생성합니다.",
    en: "Generate case identification automatically.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_IMPORT_LANG);

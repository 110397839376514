import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_PAYLOAD_DOMAIN_CODE: {
  BUILDER_STUDY_PAYLOAD_DOMAIN_NOT_FOUND: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_TYPE_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_LABEL_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_LABEL_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_LENGTH_EXCEEDED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_FORMAT: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_IDENTIFIER_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INDEX_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_EXPORT_TYPE_MISSING: MvnCodeBE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_REMOVE_INVALID_MAPPED_PAGE: MvnCodeBE;
} = {
  BUILDER_STUDY_PAYLOAD_DOMAIN_NOT_FOUND: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_NOT_FOUND",
    status: 404,
    ko: "Domain이 존재하지 않습니다.",
    en: "Domain is not exist.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_MISSING",
    status: 400,
    ko: "ID를 입력해 주세요.",
    en: "Please enter a ID.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_TYPE_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_TYPE_MISSING",
    status: 400,
    ko: "Type을 입력해 주세요.",
    en: "Please enter a type.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_LABEL_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_LABEL_MISSING",
    status: 400,
    ko: "Label을 입력해 주세요.",
    en: "Please enter a label.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_LABEL_FORMAT: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_LABEL_FORMAT",
    status: 400,
    ko: "Label은 영문, 한글, 숫자 및 공백만 입력할 수 있습니다. (label: {label})",
    en: "Label should be combination of uppercase and lowercase letters, number and space. (label: {label})",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_LENGTH_EXCEEDED: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_LENGTH_EXCEEDED",
    status: 400,
    ko: "Identifier는 {length}자리 이하의 문자이어야합니다.",
    en: "Identifier length should be {length} or less.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_FORMAT: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_IDENTIFIER_FORMAT",
    status: 400,
    ko: "Identifier는 영대문자, 숫자만 입력할 수 있습니다. (identifier: {identifier})",
    en: "Identifier should be combination of uppercase letters and number. (identifier: {identifier})",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_IDENTIFIER_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "중복된 Identifier({id})가 존재합니다.",
    en: "Identifier({id}) is duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INDEX_DUPLICATED: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INDEX_DUPLICATED",
    status: 409,
    ko: "중복된 Domain Index가 존재합니다.",
    en: "Domain index value has duplicated.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_EXPORT_TYPE_MISSING: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_INVALID_PAYLOAD_EXPORT_TYPE_MISSING",
    status: 400,
    ko: "Export Type을 입력해 주세요.",
    en: "Please enter a export type.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_REMOVE_INVALID_MAPPED_PAGE: {
    code: "BUILDER_STUDY_PAYLOAD_DOMAIN_REMOVE_INVALID_MAPPED_PAGE",
    status: 400,
    ko: "Mapping된 Page({identifier})가 존재하는 Domain은 삭제할 수 없습니다.",
    en: "You cannot delete domain that page({identifier}) mapped already.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_DOMAIN_CODE);

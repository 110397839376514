import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_IP_CODE: {
  CDMS_STUDY_IP_CODE_VALID_NOT_FOUND: MvnCodeBE;
} = {
  CDMS_STUDY_IP_CODE_VALID_NOT_FOUND: {
    status: 404,
    ko: "유효한 IP를 찾을 수 없습니다.",
    en: "Valid IP allocation not found.",
  },
};

MvnCodeInitiate(CDMS_STUDY_IP_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_ROLE_LANG: {
  CDMS_STUDY_ROLE_BREADCRUMB: MvnCodeFE;
  CDMS_STUDY_ROLE_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_ROLE_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_ROLE_BREADCRUMB: {
    ko: "Roles",
    en: "Roles",
  },
  CDMS_STUDY_ROLE_PAGE_TITLE: {
    ko: "Manage Role",
    en: "Manage Role",
  },
  CDMS_STUDY_ROLE_PAGE_DESC: {
    ko: "등록된 권한을 확인하고 관리할 수 있습니다.",
    en: "Manage role and privileges.",
  },
};

MvnCodeInitiate(CDMS_STUDY_ROLE_LANG);

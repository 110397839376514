import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_BUILDER_ROLE_CODE: {
  BUILDER_STUDY_BUILDER_ROLE_INVALID_NAME_DUPLICATE: MvnCodeBE;
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_INVALID_OWNER_INACTIVE_DENIED: MvnCodeBE;
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_INVALID_PRIVILEGE_NOT_FOUND: MvnCodeBE;
} = {
  BUILDER_STUDY_BUILDER_ROLE_INVALID_NAME_DUPLICATE: {
    code: "BUILDER_STUDY_BUILDER_ROLE_INVALID_NAME_DUPLICATE",
    status: 400,
    ko: "중복된 Role name({name})입니다.",
    en: "Role name({name}) duplicated.",
  },
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_INVALID_OWNER_INACTIVE_DENIED: {
    code: "BUILDER_STUDY_BUILDER_ROLE_MODIFY_INVALID_OWNER_INACTIVE_DENIED",
    status: 400,
    ko: "Owner는 비활성화 할 수 없습니다.",
    en: "Owner can not be deactivate.",
  },
  BUILDER_STUDY_BUILDER_ROLE_MODIFY_INVALID_PRIVILEGE_NOT_FOUND: {
    code: "BUILDER_STUDY_BUILDER_ROLE_MODIFY_INVALID_PRIVILEGE_NOT_FOUND",
    status: 404,
    ko: "존재하지 않는 권한입니다. (privilege: {privilege})",
    en: "Not found privilege. (privilege: {privilege})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_BUILDER_ROLE_CODE);

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_CUSTOM_DATA_CRF_LANG: {
  CDMS_STUDY_CUSTOM_DATA_CRF_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_CUSTOM_DATA_CRF_PAGE_DESC: MvnCodeFE;
} = {
  CDMS_STUDY_CUSTOM_DATA_CRF_PAGE_TITLE: {
    ko: "Data Combination File",
    en: "Data Combination File",
  },
  CDMS_STUDY_CUSTOM_DATA_CRF_PAGE_DESC: {
    ko: "필요한 데이터를 조합하여 PDF 파일로 다운로드 할 수 있습니다.",
    en: "You may combine the data you want into one single file and download it as PDF.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CUSTOM_DATA_CRF_LANG);

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_DATA_INTEGRATION_LANG: {
  SPEC_NOT_EXIST: MvnCodeFE;
} = {
  SPEC_NOT_EXIST: {
    ko: "정의된 Data Integration 정보가 존재하지 않습니다.",
    en: "The information of defined Data Integration does not exist.",
  },
};

export const STUDY_DATA_INTEGRATION_UPLOAD_LANG: {
  LIST_BTN_DOWNLOAD_TEMPLATE: MvnCodeFE;
  LIST_TEMPLATE_DOWNLOAD_FAIL: MvnCodeFE;
  LIST_BTN_UPLOAD: MvnCodeFE;
  LIST_UPLOAD_SUCC: MvnCodeFE;
  LIST_UPLOAD_FAIL: MvnCodeFE;
  LIST_EMPTY_CHECK_DIALOG_HEADER: MvnCodeFE;
  LIST_EMPTY_CHECK_DIALOG_TEXT: MvnCodeFE;
  LIST_UPLOAD_DIALOG_HEADER: MvnCodeFE;
  LIST_UPLOAD_DIALOG_BTN_CONFIRM: MvnCodeFE;
  LIST_UPLOAD_DIALOG_BTN_CANCEL: MvnCodeFE;
  LIST_UPLOAD_DIALOG_LABEL_DESCRIPTION: MvnCodeFE;
  LIST_UPLOAD_DIALOG_LABEL_FILE: MvnCodeFE;
  LIST_BTN_VALIDATE: MvnCodeFE;
  LIST_VALIDATE_SUCC: MvnCodeFE;
  LIST_VALIDATE_FAIL: MvnCodeFE;
  LIST_BTN_INTEGRATE: MvnCodeFE;
  LIST_INTEGRATE_SUCC: MvnCodeFE;
  LIST_INTEGRATE_FAIL: MvnCodeFE;
  LIST_BTN_DOWNLOAD: MvnCodeFE;
  LIST_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  LIST_BTN_DOWNLOAD_TEMPLATE: {
    ko: "Download Template",
    en: "Download Template",
  },
  LIST_TEMPLATE_DOWNLOAD_FAIL: {
    ko: "샘플 포맷 다운로드에 실패했습니다. ({message})",
    en: "You have failed to download template. ({message})",
  },
  LIST_BTN_UPLOAD: {
    ko: "Upload",
    en: "Upload",
  },
  LIST_UPLOAD_SUCC: {
    ko: "Data Integration Upload를 성공했습니다.",
    en: "Data Integration has been uploaded successfully.",
  },
  LIST_UPLOAD_FAIL: {
    ko: "Data Integration Upload를 실패했습니다. ({message})",
    en: "Data Integration Upload has been failed. ({message})",
  },
  LIST_EMPTY_CHECK_DIALOG_HEADER: {
    ko: "Confirm Integrate",
    en: "Confirm Integrate",
  },
  LIST_EMPTY_CHECK_DIALOG_TEXT: {
    ko: "다음 항목이 누락되었습니다. 그래도 파일을 통합하시겠습니까?",
    en: "The following items are missing. Are you sure you still want to integrate the file?",
  },
  LIST_UPLOAD_DIALOG_HEADER: {
    ko: "Upload Data Integration",
    en: "Upload Data Integration",
  },
  LIST_UPLOAD_DIALOG_BTN_CONFIRM: {
    ko: "Upload",
    en: "Upload",
  },
  LIST_UPLOAD_DIALOG_BTN_CANCEL: {
    ko: "Cancel",
    en: "Cancel",
  },
  LIST_UPLOAD_DIALOG_LABEL_DESCRIPTION: {
    ko: "Description",
    en: "Description",
  },
  LIST_UPLOAD_DIALOG_LABEL_FILE: {
    ko: "File",
    en: "File",
  },
  LIST_BTN_VALIDATE: {
    ko: "Validate",
    en: "Validate",
  },
  LIST_VALIDATE_SUCC: {
    ko: "Data Integration Validate가 정상적으로 요청되었습니다.",
    en: "Validate has been requested successfully.",
  },
  LIST_VALIDATE_FAIL: {
    ko: "Data Integration Validate 요청에 실패했습니다. ({message})",
    en: "Validate request has been failed. ({message})",
  },
  LIST_BTN_INTEGRATE: {
    ko: "Integrate",
    en: "Integrate",
  },
  LIST_INTEGRATE_SUCC: {
    ko: "Data Integration Integrate가 정상적으로 요청되었습니다.",
    en: "Integrate has been requested successfully.",
  },
  LIST_INTEGRATE_FAIL: {
    ko: "Data Integration Integrate 요청에 실패했습니다. ({message})",
    en: "Integrate request has been failed. ({message})",
  },
  LIST_BTN_DOWNLOAD: {
    ko: "Download",
    en: "Download",
  },
  LIST_DOWNLOAD_FAIL: {
    ko: "Download 요청에 실패했습니다. ({message})",
    en: "Download request has been failed. ({message})",
  },
};

MvnCodeInitiate(STUDY_DATA_INTEGRATION_LANG);
MvnCodeInitiate(STUDY_DATA_INTEGRATION_UPLOAD_LANG);

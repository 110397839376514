import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_LAB_TEST_ITEM_CODE: {
  BUILDER_STUDY_LAB_TEST_ITEM_NOT_FOUND: MvnCodeBE;
  BUILDER_STUDY_LAB_TEST_ITEM_DUPLICATED: MvnCodeBE;
  BUILDER_STUDY_LAB_TEST_ITEM_FACTOR_INVALID_TARGET_ITEM: MvnCodeBE;
  BUILDER_STUDY_LAB_TEST_ITEM_FACTOR_INVALID_ITEM_TYPE_NOT_MATCHED_BY_CATEGORY: MvnCodeBE;
} = {
  BUILDER_STUDY_LAB_TEST_ITEM_NOT_FOUND: {
    code: "BUILDER_STUDY_LAB_TEST_ITEM_NOT_FOUND",
    status: 404,
    ko: "요청하신 Study Lab Test Item({id}) 정보를 찾을 수 없습니다.",
    en: "Requested Study Lab Test Item({id}) could not be found.",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_DUPLICATED: {
    code: "BUILDER_STUDY_LAB_TEST_ITEM_DUPLICATED",
    status: 409,
    ko: "해당 실험실 항목({name}({category}))이 이미 존재합니다.",
    en: "Lab Test Item({name}({category})) already exists.",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_FACTOR_INVALID_TARGET_ITEM: {
    code: "BUILDER_STUDY_LAB_TEST_ITEM_FACTOR_INVALID_TARGET_ITEM",
    status: 400,
    ko: "스터디에서 아이템 정보({itemInfo})와 일치하는 아이템을 찾을 수 없습니다. (labTestItemId: {labTestItemId})",
    en: "Not found matched item with item info({itemInfo}) in all payloads of study. (labTestItemId: {labTestItemId})",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_FACTOR_INVALID_ITEM_TYPE_NOT_MATCHED_BY_CATEGORY: {
    code: "BUILDER_STUDY_LAB_TEST_ITEM_FACTOR_INVALID_ITEM_TYPE_NOT_MATCHED_BY_CATEGORY",
    status: 400,
    ko: "실험실 검사 요소({category})는 '{itemType}' 아이템만 연결될 수 있습니다. (labTestItemId: {labTestItemId})",
    en: "Lab Test Factor {category} type can only be mapped to '{itemType}' item type. (labTestItemId: {labTestItemId})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_LAB_TEST_ITEM_CODE);
